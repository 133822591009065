<template>
  <div class="">
    <q-table
      :rows="rows"
      :columns="columnsI18n"
      row-key="id"
      v-model:pagination="pagination"
      :loading="loading"
      :filter="filter"
      @request="onRequest"
      binary-state-sort
      class="sticky-header-table size-md"
    >
      <template v-slot:body-cell-type="props">
        <q-td :props="props">
          <q-badge :color="colorType(props.value)" class="text-uppercase">
            {{ $t("type.code." + props.value) }}
          </q-badge>
          {{
            props.row.lockerName != null ? "(" + props.row.lockerName + ")" : ""
          }}
        </q-td>
      </template>
      <template v-slot:body-cell-validFrom="props">
        <q-td :props="props">
          {{ $moment(props.value).format("DD/MM/YYYY HH:mm:ss") }}
        </q-td>
      </template>
      <template v-slot:body-cell-validUntil="props">
        <q-td :props="props">
          {{ $moment(props.value).format("DD/MM/YYYY HH:mm:ss") }}
        </q-td>
      </template>
      <template v-slot:body-cell-lastUsedAt="props">
        <q-td :props="props">
          <div v-if="props.value">
            {{ $moment(props.value).format("DD/MM/YYYY HH:mm:ss") }}
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-origin="props">
        <q-td :props="props">
          <router-link
            v-if="props.row.order"
            :to="'/order/' + props.row.order"
            class="vito-link text-bold"
          >
            <span>{{ props.row.order }}</span>
          </router-link>
          <router-link
            v-if="props.row.visit"
            :to="'/visit/' + props.row.visit"
            class="vito-link text-bold"
          >
            <span>{{ props.row.visit }}</span>
          </router-link>
        </q-td>
      </template>

      <template v-slot:body-cell-isActive="props">
        <q-td :props="props">
          <q-toggle
            ref="isActive"
            v-model="
              rows[
                props.rowIndex - (pagination.page - 1) * pagination.rowsPerPage
              ].isActive
            "
            :label="props.value == true ? $t('active') : $t('inactive')"
            color="green-vito"
            @update:model-value="handleChange(props)"
          >
          </q-toggle>
        </q-td>
      </template>
    </q-table>
  </div>
</template>
<script>
import CodeApi from "../../apis/Code";
import CodeType from "./code.type.enum";
export default {
  props: ["store", "filter"],

  data: () => {
    return {
      loading: false,
      pagination: {
        sortBy: "id",
        descending: true,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 0,
      },
      rows: [],
      refreshCounter: 0,
    };
  },
  methods: {
    colorType(type) {
      return CodeType[type].color;
    },

    async handleChange(props) {
      const payload = {};
      payload[props.col.field] = props.value;
      await this.saveChange(props.key, payload);
    },

    async saveChange(id, payload) {
      try {
        const codeResponse = await CodeApi.save(id, payload);
        this.$q.notify({
          type: "positive",
          message: this.$t(codeResponse.data.message),
        });
        this.$emit("refresh");
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    updateProxy() {
      this.refreshCounter += 1;
    },
    async onRequest(props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const filter = props.filter;
      this.loading = true;
      try {
        const startRow = (page - 1) * rowsPerPage;
        const pageReponse = await CodeApi.storePaginate({
          storeId: this.store.id,
          startRow,
          fetchCount: rowsPerPage,
          filter,
          sortBy,
          descending,
        });

        this.rows = pageReponse.data.items;
        this.pagination.rowsNumber = pageReponse.data.count;
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async refresh() {
      this.onRequest({
        pagination: this.pagination,
        filter: this.filter,
      });
      this.showNew = false;
    },
  },

  async mounted() {
    this.onRequest({
      pagination: this.pagination,
      filter: this.filter,
    });
  },

  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "left",
          field: "id",
          sortable: true,
        },
        {
          name: "code",
          label: this.$t("label.code"),
          field: "code",
          align: "center",
          sortable: true,
        },
        {
          name: "type",
          align: "center",
          label: this.$t("label.type"),
          field: "type",
          sortable: true,
        },
        {
          name: "validFrom",
          label: this.$t("label.validFrom"),
          field: "validFrom",
          align: "center",
          sortable: true,
        },
        {
          name: "validUntil",
          label: this.$t("label.validUntil"),
          field: "validUntil",
          align: "center",
          sortable: true,
        },
        {
          name: "lastUsedAt",
          label: this.$t("label.lastUsedAt"),
          //format: (val) => this.$t(val ? "true" : "false"),
          field: "lastUsedAt",
          align: "center",
          sortable: true,
        },
        {
          name: "usageCount",
          label: this.$t("label.usageCount"),
          //format: (val) => this.$t(val ? "true" : "false"),
          field: "usageCount",
          align: "center",
          sortable: true,
        },
        {
          name: "origin",
          label: this.$t("label.origin"),
          field: "origin",
          align: "center",
          sortable: true,
        },
        {
          name: "isActive",
          label: this.$t("label.active"),
          field: "isActive",
          align: "center",
          sortable: true,
        },
      ];
      return columns;
    },
  },
};
</script>
