<template>
  <q-card
    bordered
    class="q-mt-md q-pa-md shadow-1"
    style="width: 750px; max-width: 90vw"
  >
    <q-card-section class="row text-green-vito no-wrap justify-between">
      <div class="text-h6">
        {{ $t("title.resendMessage") }}
      </div>
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>

    <q-card-section>
      <q-input
        ref="comment"
        input-style="max-height: 180px"
        square
        filled
        clearable
        type="textarea"
        autogrow
        v-model="comment"
        :label="$t('label.addComment')"
        hint=""
        ><template v-slot:prepend> <q-icon name="comment" /> </template>
      </q-input>
    </q-card-section>

    <q-card-actions class="q-pa-md justify-end">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        v-close-popup
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="$t('button.send')"
        @click="handleSend"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
import VisitApi from "../../apis/Visit";
export default {
  props: ["visit"],
  data: () => {
    return {
      comment: null,
    };
  },
  methods: {
    async handleSend() {
      console.log("sending");
      try {
        const visitResponse = await VisitApi.resendMessage(this.visit.id, {
          comment: this.comment,
        });
        this.$q.notify({
          type: "positive",
          message: this.$t(visitResponse.data.message),
        });
        this.$emit("refresh");
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
  },
};
</script>
