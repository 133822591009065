<template>
  <q-card
    bordered
    class="q-mt-md q-pa-md shadow-1"
    style="width: 750px; max-width: 90vw"
  >
    <q-card-section class="row text-green-vito no-wrap justify-between">
      <div class="text-h6">
        {{ $t("title.orderMessage") }} #{{ message.id }}
      </div>
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>

    <q-card-section class="col-12 col-sm-auto">
      <message :message="message"></message>
    </q-card-section>
  </q-card>
</template>

<script>
import Message from "../message/Message.vue";

export default {
  props: ["message"],
  components: { Message },
};
</script>
