<template>
  <q-card flat class="full-width" style="max-width: 90vw">
    <q-card-section horizontal>
      <q-input
        ref="currency"
        class="col-12 col-md-3"
        square
        filled
        clearable
        v-model="form.currency"
        :label="$t('label.currency')"
        :hint="$t('hint.minchar', { chars: 1 })"
        lazy-rules
        :rules="[required]"
      >
        <template v-slot:prepend>
          <q-icon name="attach_money" />
        </template>
      </q-input>
    </q-card-section>
    <q-card-section horizontal>
      <q-field
        :stack-label="
          form.techniciansEmail && form.techniciansEmail.length ? true : false
        "
        square
        filled
        :label="$t('label.techniciansEmail')"
        class="col-12 col-md-10 q-mt-md"
        style="min-width: 250px"
      >
        <template v-slot:prepend>
          <q-icon name="mail" />
        </template>
        <template v-slot:append>
          <q-btn
            icon="add"
            color="primary"
            round
            dense
            @click="handleAddEmail('techniciansEmail')"
          />
        </template>
        <template v-slot:control>
          <div class="q-gutter-xs q-pt-sm">
            <q-chip
              v-for="(email, index) in form.techniciansEmail"
              :key="index"
              removable
              @remove="removeEmail(index, 'techniciansEmail')"
              color="green-vito"
              text-color="white"
            >
              {{ email }}
            </q-chip>
          </div></template
        >
      </q-field>
    </q-card-section>

    <q-card-section horizontal>
      <q-field
        :stack-label="
          form.contractManagersEmail && form.contractManagersEmail.length
            ? true
            : false
        "
        square
        filled
        :label="$t('label.contractManagersEmail')"
        class="col-12 col-md-10 q-mt-md"
        style="min-width: 250px"
      >
        <template v-slot:prepend>
          <q-icon name="mail" />
        </template>
        <template v-slot:append>
          <q-btn
            icon="add"
            color="primary"
            round
            dense
            @click="handleAddEmail('contractManagersEmail')"
          />
        </template>
        <template v-slot:control>
          <div class="q-gutter-xs q-pt-sm">
            <q-chip
              v-for="(email, index) in form.contractManagersEmail"
              :key="index"
              removable
              @remove="removeEmail(index, 'contractManagersEmail')"
              color="green-vito"
              text-color="white"
            >
              {{ email }}
            </q-chip>
          </div></template
        >
      </q-field>
    </q-card-section>

    <q-card-section horizontal>
      <q-field
        :stack-label="
          form.salesReportEmail && form.salesReportEmail.length ? true : false
        "
        square
        filled
        :label="$t('label.salesReportEmail')"
        class="col-12 col-md-10 q-mt-md"
        style="min-width: 250px"
      >
        <template v-slot:prepend>
          <q-icon name="mail" />
        </template>
        <template v-slot:append>
          <q-btn
            icon="add"
            color="primary"
            round
            dense
            @click="handleAddEmail('salesReportEmail')"
          />
        </template>
        <template v-slot:control>
          <div class="q-gutter-xs q-pt-sm">
            <q-chip
              v-for="(email, index) in form.salesReportEmail"
              :key="index"
              removable
              @remove="removeEmail(index, 'salesReportEmail')"
              color="green-vito"
              text-color="white"
            >
              {{ email }}
            </q-chip>
          </div></template
        >
      </q-field>
    </q-card-section>

    <q-card-section horizontal>
      <q-input
        ref="storeTimeout"
        class="col-12 col-md-3 q-mt-md"
        square
        filled
        clearable
        type="number"
        v-model.number="form.storeTimeout"
        :label="$t('label.storeTimeout')"
        :hint="$t('hint.number')"
        lazy-rules
        :rules="[isNumber, required]"
      >
        <template v-slot:prepend>
          <q-icon name="hourglass_bottom" />
        </template>
      </q-input>
    </q-card-section>

    <q-card-section horizontal>
      <q-input
        ref="supplierCodeDuration"
        class="col-12 col-md-3 q-mt-md"
        square
        filled
        clearable
        type="number"
        v-model.number="form.supplierCodeDuration"
        :label="$t('label.supplierCodeDuration')"
        :hint="$t('hint.number')"
        lazy-rules
        :rules="[isNumber, required]"
      >
        <template v-slot:prepend>
          <q-icon name="dayglass_bottom" />
        </template>
      </q-input>
    </q-card-section>

    <q-card-section v-if="errorMessage">
      <div class="text-red-vito text-center">
        <strong>{{ errorMessage }}</strong>
      </div>
    </q-card-section>
    <q-card-actions class="q-px-md justify-end">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        @click="getParams"
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="$t('button.save')"
        @click="handleSave"
      />
    </q-card-actions>
  </q-card>

  <q-dialog v-model="showAddEmail" persistent ref="newEmailDialog">
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-h6 text-green-vito">{{ $t("label.email") }}</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <q-input
          ref="newEmail"
          dense
          v-model="newEmail"
          autofocus
          :hint="$t('hint.emailFormat')"
          type="email"
          lazy-rules
          :rules="[isEmail]"
        />
      </q-card-section>

      <q-card-actions align="right" class="text-primary">
        <q-btn flat :label="$t('button.cancel')" v-close-popup />
        <q-btn flat :label="$t('button.add')" @click="addEmail" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import ParamApi from "../../apis/Param";
export default {
  data: () => {
    return {
      form: {
        id: null,
        currency: "",
        techniciansEmail: [],
        contractManagersEmail: [],
        salesReportEmail: [],
        storeTimeout: 0,
        supplierCodeDuration: 0,
      },
      newEmail: null,
      showAddEmail: false,
      errorMessage: null,
    };
  },
  async mounted() {
    this.$store.state.title = "button.settings";
    await this.getParams();
  },
  methods: {
    async getParams() {
      try {
        const paramResponse = await ParamApi.findAll();
        this.form = paramResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async handleSave() {
      this.errorMessage = null;
      ["currency"].forEach((f) => {
        //console.log(this.$refs[f].validate());

        if (!this.$refs[f].validate()) {
          this.errorMessage = this.$t("validation.verify");
          return;
        }
      });
      if (!this.errorMessage) {
        try {
          const payload = (({
            currency,
            techniciansEmail,
            contractManagersEmail,
            salesReportEmail,
            storeTimeout,
            supplierCodeDuration,
          }) => ({
            currency,
            techniciansEmail,
            contractManagersEmail,
            salesReportEmail,
            storeTimeout,
            supplierCodeDuration,
          }))(this.form);
          //console.log(payload);

          const saveResponse = await ParamApi.save(payload);
          this.$q.notify({
            type: "positive",
            message: this.$t(saveResponse.data.message),
          });
          //console.log(saveResponse.data);
          await this.getParams();
        } catch (error) {
          const message = error.response
            ? error.response.data.message
            : error.message;
          this.errorMessage = message;
          this.$q.notify({
            type: "negative",
            message: message,
          });
          //console.error(message);
        }
      }
    },

    addEmail() {
      //console.log(this.form);
      this.$refs.newEmail.validate();
      if (this.$refs.newEmail.hasError) {
        this.$refs.newEmailDialog.shake();
        // form has error
      } else {
        let list = this.emailList;
        if (list) list.push(this.newEmail);
        else list = [this.newEmail];
        this.showAddEmail = false;
      }
    },
    removeEmail(index, list) {
      //console.log(this.form);
      this.form[list].splice(index, 1);
    },
    handleAddEmail(list) {
      this.emailList = this.form[list];
      this.showAddEmail = true;
    },
    isEmail(val) {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val) || this.$t("validation.isEmail");
    },

    required(val) {
      return (
        (val && val.toString().length > 0) || this.$t("validation.required")
      );
    },
    isNumber(val) {
      return !isNaN(val) || this.$t("validation.number");
    },
  },
  computed: {},
};
</script>
