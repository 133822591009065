import Api from "./Api";
export default {
  getCommands() {
    return Api().get("/terminal/commands");
  },
  findAll() {
    return Api().get("/terminal");
  },

  findOne(id) {
    return Api().get(`/terminal/${id}`);
  },

  save(id, form) {
    return Api().patch("/terminal/" + id, form);
  },

  create(form) {
    return Api().post("/terminal", form);
  },

  delete(id) {
    return Api().delete(`/terminal/${id}`);
  },

  paginate(paginate) {
    return Api().post("/terminal/page", paginate);
  },

  findLogcats(id) {
    return Api().get(`/terminal/${id}/logcat`);
  },

  getLogcat(terminalId, id) {
    return Api().get(`/terminal/${terminalId}/logcat/${id}`);
  },
  paginateLog(paginate) {
    return Api().post("/terminal/logs", paginate);
  },
};
