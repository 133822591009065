import Api from "./Api";
export default {
  findAll() {
    return Api().get("/store");
  },
  find(id) {
    return Api().get("/store/" + id);
  },
  save(form) {
    return Api().patch("/store/" + form.id, form);
  },
  create(form) {
    return Api().post("/store", form);
  },
  remove(storeId) {
    return Api().delete("/store/" + storeId);
  },
  addProduct(storeId, productId) {
    return Api().post(`/store/${storeId}/product/${productId}`);
  },
  removeProduct(storeId, productId) {
    return Api().delete(`/store/${storeId}/product/${productId}`);
  },

  saveProductLevelAlert(storeId, payload) {
    return Api().post(`/store/${storeId}/alert`, payload);
  },
  findNetworks() {
    return Api().get("/store/network/list");
  },
  createNetwork(form) {
    return Api().post("/store/network", form);
  },
  updateNetwork(id, form) {
    return Api().patch(`/store/${id}/network`, form);
  },
  deleteNetwork(id) {
    return Api().delete(`/store/${id}/network`);
  },
};
