<template>
  <q-fab
    v-if="action == 'list'"
    ref="addButton"
    color="primary"
    @click="action = 'create'"
    icon="add"
    class="absolute add-store"
    style="top: 0; right: 20px; transform: translateY(25%)"
  >
    <q-tooltip class="bg-green-vito text-no-wrap" target=".add-store">
      {{ $t("tooltip.addStore") }}
    </q-tooltip>
  </q-fab>
  <div v-if="action == 'create'">
    <div class="text-h5 text-green-vito full-width q-pa-lg">
      {{ $t("title.addStore") }}
    </div>
    <div class="row full-width q-gutter-md">
      <store-card
        :storeId="0"
        action="create"
        @cancel="cancel"
        @refresh="refresh"
        @displayOne="action = 'display'"
      />
    </div>
  </div>

  <div v-if="action == 'list'">
    <div
      class="text-h5 text-green-vito full-width q-pa-lg"
      v-if="action == 'list'"
    >
      {{ $t("title.listStores") }} ({{ stores.length }})
    </div>
    <div class="row full-width q-gutter-md q-mb-md">
      <div v-for="store in stores" :key="store.id">
        <q-card
          bordered
          class="q-pa-sm shadow-1"
          style="max-width: 100vw; width: 340px"
          @click="$router.push('/store/' + store.id)"
          :class="store.isActive == false ? 'bg-grey-3' : ''"
        >
          <q-card-section
            class="row items-center q-pb-none text-green-vito justify-between"
          >
            <div class="text-h6 text">{{ store.name }}</div>
            <div class="q-gutter-x-sm row">
              <q-btn
                icon="visibility"
                square
                round
                dense
                :to="'/store/' + store.id"
              >
                <q-tooltip class="bg-green-vito text-no-wrap">
                  {{ $t("tooltip.showStore") }}
                </q-tooltip>
              </q-btn>
            </div>
          </q-card-section>
          <q-card-section class="col cursor-pointer" style="min-width: 320px">
            <p>
              {{ store.location }}
            </p>
            <div class="row justify-between">
              <div>
                <q-icon name="leaderboard" class="q-mr-md" />

                {{
                  parseFloat(
                    Math.ceil(store.transactions / 100) / 10
                  ).toLocaleString(locale, {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 2,
                  })
                }}
                T/{{ $t("month") }}
              </div>
              <span class="text-grey-8 text-italic" v-if="store.network">
                {{ store.network.name }}
              </span>
            </div>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>
<script>
import StoreCard from "../components/store/StoreCard.vue";
import StoreApi from "../apis/Store";
import { useI18n } from "vue-i18n";

export default {
  name: "OrderPage",
  components: { StoreCard },
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      refreshCounter: 0,
      action: "list",

      store: {},
      stores: [],
    };
  },
  mounted() {
    this.$store.state.title = "button.stores";
    this.getAllStores();
  },
  methods: {
    async cancel() {
      //console.log("cancel");
      await this.getAllStores();
      this.action = "list";
      if (this.$refs.addButton) this.$refs.addButton.hide();
    },
    async refresh() {
      await this.getAllStores();
      this.action = "list";
      this.refreshCounter += 1;
      if (this.$refs.addButton) this.$refs.addButton.hide();
    },
    async getAllStores() {
      try {
        const storesResponse = await StoreApi.findAll();
        this.stores = storesResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    handleEdit(id) {
      this.store = this.stores.find((s) => s.id == id);
      this.action = "edit";
    },
    async handleDisplay(id) {
      await this.getAllStores();
      this.store = this.stores.find((s) => s.id == id);
      this.action = "display";
    },
    handleAddStore() {},
  },
};
</script>
