<template>
  <div class="q-pa-md q-gutter-sm">
    <q-banner rounded class="bg-grey-3">
      <template v-slot:avatar>
        <img src="/img/display.png" />
      </template>
      <div class="text-h4 text-green-vito">{{ message }}</div>
    </q-banner>
  </div>
</template>

<script>
export default {
  props: ["message"],
};
</script>
