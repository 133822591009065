<template>
  <q-card
    bordered
    class="q-mt-md q-pa-md shadow-1"
    style="width: 750px; max-width: 90vw"
  >
    <q-card-section class="row text-green-vito no-wrap justify-between">
      <div class="text-h6">{{ $t("title.completeVisit") }} #{{ visit.id }}</div>
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>

    <q-card-actions class="q-pa-md justify-end">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        v-close-popup
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="$t('button.complete')"
        @click="handleSave"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
//import StoreApi from "../../apis/Store";
import VisitApi from "../../apis/Visit";

export default {
  props: ["visit"],
  emits: ["refresh"],

  data: () => {
    return {
      errorMessage: null,
    };
  },

  async mounted() {
    //await this.getAllStores();
    //await this.getStore();
    //console.log(this.visit);
  },
  methods: {
    async handleSave() {
      //console.log("saving complete");
      this.errorMessage = null;

      try {
        const visitResponse = await VisitApi.complete({
          visitId: this.visit.id,
        });
        this.$q.notify({
          type: "positive",
          message: this.$t(visitResponse.data.message),
        });
        this.$emit("refresh");
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
  },

  computed: {},
};
</script>
