<template>
  <q-card
    bordered
    class="q-pa-sm q-mb-sm no-padding-md"
    style="width: 480px; max-width: 100vw"
  >
    <q-card-section class="row q-pa-sm text-green-vito justify-between">
      <div class="col-grow">
        <div class="text-h5 text-green-vito" v-if="displayId">
          {{ $t("title.editDisplay") }} #{{ displayId }}
        </div>
        <div class="text-h5 text-green-vito" v-else>
          {{ $t("title.addDisplay") }}
        </div>
      </div>

      <div class="row q-gutter-x-sm no-wrap">
        <q-btn
          v-if="form.isDeletable"
          icon="delete_forever"
          class="text-red-vito"
          round
          dense
          @click="handleDelete"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.deleteDisplay") }}
          </q-tooltip>
        </q-btn>
        <q-btn icon="close" flat round dense v-close-popup />
      </div>
    </q-card-section>

    <q-card-section class="column">
      <q-form class="q-gutter-y-md q-mt-md">
        <q-input
          ref="name"
          square
          filled
          clearable
          v-model="form.name"
          :label="$t('label.name')"
          :hint="$t('hint.minchar', { chars: 2 })"
          lazy-rules
          :rules="[required, short]"
        >
        </q-input>

        <q-select
          class="q-pb-md"
          v-model="form.template"
          :options="templates"
          :label="$t('label.template')"
          filled
          borderless
          square
          :rules="[required]"
          ref="template"
          :disable="!!displayId"
        >
          <template v-slot:selected>
            <q-item>
              <q-item-section>
                <q-item-label>
                  {{ form.template ? form.template.name : "" }}
                </q-item-label>
                <q-item-label caption>{{
                  form.template ? form.template.description : ""
                }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section>
                <q-item-label>{{ scope.opt.name }}</q-item-label>
                <q-item-label caption>{{ scope.opt.description }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:prepend>
            <q-icon name="extension" />
          </template>
        </q-select>

        <q-input
          class="col-md-6"
          ref="version"
          square
          filled
          clearable
          type="number"
          v-model.number="form.version"
          :label="$t('label.version')"
          :hint="$t('hint.number')"
          lazy-rules
          :rules="[isNumber, required]"
        >
        </q-input>

        <q-input
          class="col-md-6 col-12"
          ref="targetVersion"
          square
          filled
          clearable
          type="number"
          v-model.number="form.targetVersion"
          :label="$t('label.targetVersion')"
          :hint="$t('hint.number')"
          lazy-rules
          :rules="[isNumber, required]"
        >
        </q-input>

        <q-input
          ref="traceability"
          input-style="max-height: 180px"
          square
          filled
          clearable
          type="textarea"
          autogrow
          v-model="form.traceability"
          :label="$t('label.traceability')"
          hint=""
        >
        </q-input>
        <q-toggle
          ref="isActive"
          v-model="form.isActive"
          :label="form.isActive == true ? $t('active') : 'Inactif'"
          color="green-vito"
        >
        </q-toggle>
      </q-form>
    </q-card-section>

    <q-card-section v-if="errorMessage">
      <div class="text-red-vito text-center">
        <strong>{{ errorMessage }}</strong>
      </div>
    </q-card-section>

    <q-card-actions class="q-px-md justify-end">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        @click="handleCancel"
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="$t('button.save')"
        @click="handleSave"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
import DisplayApi from "../../apis/Display";
import TemplateApi from "../../apis/Template";

export default {
  emits: ["refresh"],
  props: ["displayId"],
  data: () => {
    return {
      templates: [],
      refreshCounter: 0,
      form: {
        id: null,
        store: null,
        name: null,
        version: null,
        targetVersion: null,
        traceability: null,
        isDeletable: false,
        template: null,
        isActive: null,
      },
      errorMessage: null,
    };
  },
  async mounted() {
    if (this.displayId) {
      await this.getDisplay();
    }
    await this.getTemplates();
  },
  methods: {
    async getDisplay() {
      try {
        const displayResponse = await DisplayApi.findOne(this.displayId);
        this.form = displayResponse.data;
        //console.log(this.form);
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async getTemplates() {
      try {
        const templatesResponse = await TemplateApi.findAll();
        this.templates = templatesResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    handleCancel() {
      this.$emit("refresh");
    },

    async handleSave() {
      //console.log("saving");
      this.errorMessage = null;
      ["name", "template", "version", "targetVersion"].forEach((f) => {
        if (!this.$refs[f].validate()) {
          this.errorMessage = this.$t("validation.verify");
          return;
        }
      });
      if (!this.errorMessage) {
        const payload = {
          name: this.form.name,
          version: this.form.version,
          targetVersion: this.form.targetVersion,
          traceability: this.form.traceability,
          isActive: this.form.isActive,
        };
        try {
          const updateDisplayResponse = this.displayId
            ? await DisplayApi.save(this.form.id, {
                id: this.form.id,
                ...payload,
              })
            : await DisplayApi.create({
                template: { id: this.form.template.id },
                ...payload,
              });
          this.$emit("refresh");

          this.$q.notify({
            type: "positive",
            message: this.$t(updateDisplayResponse.data.message),
          });
        } catch (error) {
          const message = error.response
            ? error.response.data.message
            : error.message;
          this.errorMessage = message;
          this.$q.notify({
            type: "negative",
            message: message,
          });
        }
      }
    },

    async handleDelete() {
      this.$q
        .dialog({
          title: this.$t("title.confirmDelete"),
          message: this.$t("message.confirmDeleteDisplay"),
          ok: {
            color: "red-vito",
            label: this.$t("button.deleteDefinitively"),
          },
          cancel: {
            label: this.$t("button.cancel"),
            color: "green-vito",
          },
          persistent: true,
        })
        .onOk(() => {
          this.deleteDisplay();
        });
    },
    async deleteDisplay() {
      try {
        if (this.form.isDeletable) {
          const deleteResponse = await DisplayApi.delete(this.form.id);
          this.$q.notify({
            type: "positive",
            message: this.$t(deleteResponse.data.message),
          });
        }
        //console.log(saveResponse.data);
        this.$emit("refresh");
      } catch (error) {
        let message = error.response
          ? error.response.data.message
          : error.message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
        //console.error(message);
      }
    },
    required(val) {
      return (
        (val && val.toString().length > 0) || this.$t("validation.required")
      );
    },
    short(val) {
      return (
        (val && val.length > 1) || this.$t("validation.minchar", { chars: 2 })
      );
    },
    isNumber(val) {
      return !isNaN(val) || this.$t("validation.number");
    },

    productImage(product) {
      return process.env.VUE_APP_BASE_URL + "/product/images/" + product.image;
    },
  },
  computed: {},
};
</script>
