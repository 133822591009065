export default {
  hello: "Bonjour",
  dashboard: "tableau de bord",
  order: "vente",
  store: "point de vente",
  product: "product",
  display: "présentoir",
  month: "mois",
  active: "Actif",
  inactive: "Inactif",
  emptyList: "Cette liste est vide",
  true: "Oui",
  false: "Non",
  notFound: "Non trouvé",
  status: {
    ok: "OK",
    new: "Nouveau",
    cancel: "Annulé",
    success: "Succès",
    failure: "Echec",
    complete: "Terminé",
    0: "Succès",
    1: "Echec",
    2: "Refaire",
    error: "Erreur",
    message_sent: "Message envoyé",
    locker_changed: "Casier changé",
    locker_fault_reported: "Erreur de casier signalée",
    locker_fault_cleared: "Erreur de casier effacée",
    canceled: "Annulé",
    contract_sent: "Envoyé",
    contract_new: "En attente",
    contract_cancel: "Annulé",
    aborted: "Abandonné",
    locker_opened: "Casier ouvert",
  },
  title: {
    editProfile: "Modifier mon profil",
    lockerActionNone: "Aucune action disponible pour le casier {doorName}",
    lockerActionMoveIn: "Sélectionnez un article pour le casier {doorName}",
    lockerActionMoveOut:
      "Confirmez le retrait de l'article pour le casier {doorName}",
    confirmDelete: "Confirmez la suppression",
    editProduct: "Éditer le produit",
    addProduct: "Ajouter un nouveau produit",
    listProducts: "Tous les produits",
    editStore: "Éditer le point de vente",
    addStore: "Ajouter un nouveau point de vente",
    listStores: "Tous les points de vente",
    editDisplay: "Editer le présentoir",
    addOrder: "Nouvelle commande",
    completeOrder: "Finaliser la commande",
    contractDetails: "Détails pour carte de dépot",
    orders: "Ventes",
    devInProgress: "Cette page est en cours de développement",
    users: "Users",
    editUser: "Edit user",
    addUser: "Add new user",
    logDetails: "Détails du journal",
    addCode: "Type de code",
    orderMessage: "Message ",
    logs: "Journal",
    visits: "Visites",
    visit: "Visite",
    completeVisit: "Finaliser la visite",
    addVisit: "Nouvelle demande de visite",
    comment: "Commentaire",
    lockerActionResetFaulty:
      "Veuillez confirmer la réinitialisation de l'état défectueux du casier",
    lockerActionResetReserved:
      "Veuillez confirmer la réinitialisation de l'état rééservé de l'article",
    displayLocker: "Casier",
    confirmMoveOut: "Veuillez confirmer le retrait de l'article de l'armoire",
    confirmMoveIn:
      "Veuillez confirmer la création de l'article et son insertion dans le casier",
    contracts: "Cartes de dépôt",
    confirmContractSent:
      "Veuillez confirmer que la carte de dépôt a été envoyée",
    contractMessage: "Message",
    allKPIs: "KPIs généraux",
    detailledKPIs: "KPIs détaillés",
    alerts: "Alertes",
    lockerActionSetFaulty: "Veuillez confirmer l'état défectueux du casier",
    filterOrders: "Filtrer les commandes",
    exportOrders: "Filter et exporter les commandes",
    displays: "Présentoirs",
    addDisplay: "Nouveau présentoir",
    confirmRemove: "Veuillez confirmer",
    resendMessage: "Renvoyer le message",
    terminals: "Bornes",
    addTerminal: "Nouvelle borne",
    editTerminal: "Editer la borne",
    showLogsTerminal: "Voir les logs de la borne",
    statsPerNetwork: "Répartition par réseau",
    statsPerProduct: "Répartition par bouteille",
    orderLogs: "Journal des commandes",
    lockerLogs: "Journal des casiers",
    messageLogs: "Journal des messages",
    message: "Message",
    networks: "Réseaux",
    batteryHistory: "Historique du niveau de batterie",
    displayReport: "Rapport du présentoir",
    lockerActionResetFaultCount:
      "Veuillez confirmer la réinitialisation du nombre de défauts du casier",
    terminalLogs: "Journal des bornes",
    addTags: "Création de cartes RFID"
  },
  button: {
    returnable: "Consignée",
    add: "Ajouter",
    dashboard: "Tableau de bord",
    orders: "Ventes",
    stores: "Points de vente",
    products: "Produits",
    displays: "Présentoirs",
    cancel: "annuler",
    save: "enregistrer",
    signIn: "connexion",
    logOut: "Déconnexion",
    myProfile: "Mon profil",
    deleteDefinitively: "Supprimer définitivement",
    manageProducts: "Gérer les produits",
    manageTemplates: "Gérer les modèles",
    prices: "Tarifs",
    managePricing: "Gérer la tarification",
    removeStoreProduct: "Supprimer du point de vente",
    order: "Commande",
    config: "Configuration",
    logs: "Journal",
    bankReturn: "Retour de la banque",
    management: "Gestion",
    system: "Système",
    users: "Utilisateurs",
    interventions: "Interventions",
    log: "Log",
    documentation: "Documentation",
    complete: "Finaliser",
    settings: "Paramètres",
    loadingPlan: "Plan de chargement",
    codes: "Codes",
    visits: "Visites",
    request: "demande",
    comments: "Commentaires",
    reset: "Réinitialiser",
    general: "Général",
    templates: "Modèles",
    contracts: "Cartes de dépôt",
    contract: "Carte de dépôt",
    supplierVisits: "Visites chauffeur",
    technicianVisits: "Visites technicien",
    ok: "OK",
    lockers: "Casiers",
    confirm: "Confirmation",
    messages: "Messages",
    filter: "Filtrer",
    export: "Exporter",
    manageDisplays: "Gérer les présentoirs",
    remove: "Retirer",
    send: "Envoyer",
    terminals: "Bornes",
    tags: "Cartes RFID"
  },
  message: {
    confirmDeleteProduct:
      "Voulez-vous vraiment supprimer définitivement ce produit ?",
    confirmDeleteStoreProduct:
      "Voulez-vous vraiment retirer définitivement ce produit du point de vente ?",
    confirmDeleteStore:
      "Voulez-vous vraiment supprimer définitivement ce point de vente ?",
    confirmDeleteDisplay:
      "Voulez-vous vraiment supprimer définitivement ce présentoir ?",
    notFound404: "Cette page n'existe pas, ou est en cours de développement!",
    confirmDeleteUser:
      "Voulez-vous vraiment supprimer définitivement cet utilisateur ?",
    supplierCode: "Un code chauffeur",
    technicianCode: "Un code technicien",
    urgencyCode: "Un code d'urgence",
    open_locker: "Un code pour l'ouverture d'un casier",
    copied: "Le contenu a été copié dans le presse-papiers",
    confirmRemoveDisplay:
      "Voulez-vous vraiment retirer le présentoir du point de vente ?",
    confirmDeleteTerminal:
      "Voulez-vous vraiment supprimer définitivement cette borne ?",
    confirmDeleteDoc:
      "Voulez-vous vraiment supprimer définitivement cette documentation ?",
  },
  hint: {
    number: "Nombre",
    required: "Requis",
    minchar: "Min {chars} caractères",
    emailFormat: "Au format e-mail",
    password: "Mot de passe",
    repeatPassword: "Identique à mot de passe",
    requireLogin:
      "Il vous sera demandé de vous reconnecter avec les nouvelles informations afin de valider les changements.",
    numberLength: "Nombre à {chars} chiffres",
    newCodeCommentRequired: "Le commentaire est requis",
  },
  label: {
    location: "Adresse",
    displayName: "Nom du présentoir",
    language: "Langage",
    name: "Nom",
    password: "Mot de passe",
    email: "E-mail",
    repeatPassword: "Confirmez le mot de passe",
    weight: "Poids",
    position: "Position",
    image: "Image",
    dragImage: "Glissez une image ici...",
    selectProducts: "Sélectionnez les produits",
    selectAction: "Sélectionnez une action",
    selectTemplate: "Sélectionnez un modèle",
    charge: "Charge",
    promo: "Promo",
    returnable: "Consigne",
    selectPrices: "Sélectionnez la tarification",
    store: "Point de vente",
    orderProduct: "Produit commandé",
    returnProduct: "Produit retourné",
    sameReturnable: "Same returnable",
    phone: "Téléphone",
    firstname: "Prénom",
    lastname: "Nom",
    address: "Adrèsse",
    zip: "CP",
    city: "Ville",
    orderAmount: "Montant de la commande",
    search: "Recherche",
    date: "Date",
    status: "Statut",
    source: "Source",
    amount: "Montant",
    actions: "Actions",
    transactionReference: "Réference bancaire",
    authorisationNumber: "Numéro d'autorisation",
    transactionSource: "Type de paiement",
    transactionLog: "Journal",
    delivered: "Livré",
    returned: "Rendu",
    product: "Produit",
    type: "Type",
    available: "Disponible",
    deliveryDone: "Livraison effectuée",
    returnDone: "Retour efffectué",
    lockers: "Casiers",
    securityCode: "Code de sécurité",
    contact: "Contact",
    return: "Retour",
    active: "Actif",
    roles: "Rôles",
    description: "Description",
    template: "Modèle",
    side: "Face",
    faces: "Faces",
    sides: "Faces",
    rows: "Rangées",
    columns: "Colonnes",
    role: {
      admin: "Administrateur",
      super: "Super Admin",
      user: "Utilisateur",
    },
    selectProductAndAssign:
      "Sélectionnez un produit ci-dessous, puis cliquez sur un casier pour lui attribuer",
    supplier: "Chauffeur",
    technician: "Technicien",
    urgency: "Urgence",
    open_locker: "Ouverture casier",
    code: "Code",
    validFrom: "Valable depuis",
    validUntil: "Valable jusqu'à",
    lastUsedAt: "Utilisé",
    usageCount: "Utilisations",
    terminal: "Borne",
    action: "Action",
    content: "Contenu",
    to: "A",
    log: "Journal",
    order: "Commande",
    levelAlert: "Niveau d'alerte",
    suppliersEmail: "Adresses e-mail chauffeurs",
    ticket: "Ticket",
    comments: "Commentaires",
    comment: "Commentaire",
    from: "De",
    currency: "Devise",
    techniciansEmail: "E-mails des techniciens",
    origin: "Origine",
    lastUpdate: "Mise à jour",
    faulty: "Défaut",
    presence: "Présence",
    locked: "Verrouillé",
    reserved: "Réservé",
    selectProductAndMoveIn:
      "Vous pouvez sélectionner un produit ci-dessous puis cliquer sur le bouton a droite pour créer un article et le déplacer dans le casier",
    dateSent: "Date d'envoi",
    created: "Créé",
    contractManagersEmail: "E-mails des gestionnaires de cartes de dépôt",
    deployedStores: "Points de vente en service",
    revenuesSinceJan: "CA depuis le 1er janvier",
    averageRevenue: "CA moyen",
    volumeSinceJan: "Volume réalisé depuis le 1er janvier",
    averageVolumeSinceJan: "Volume moyen réalisé depuis le 1er janvier",
    salesCB: "Achats par carte de crédit",
    salesSC: "Achats sans contact",
    salesCoupon: "Achats en caisse",
    openVisits: "Visites en cours",
    lowBatteryDisplay: "Batterie faible",
    doorFault: "Défaut de porte",
    lockFault: "Défaut de serrure",
    presenceFault: "Défaut du capteur de présence",
    powerFault: "Défaut d'alimentation",
    timeoutFault: "Défaut de temporisation",
    otherFault: "Autre",
    all: "Tous",
    selection: "Sélection",
    storeFilter: "Sélection de point de vente",
    selectStores: "Sélectionnez les points de vente",
    selectDates: "Sélectionnez une plage de dates",
    previousMonth: "Mois précédent",
    currentMonth: "Ce mois-ci",
    selectStatus: "Sélectionnez le status",
    battery: "Batterie",
    lastPolling: "Dernier status",
    lastSupply: "Dernièr approvisionement",
    version: "Version",
    targetVersion: "Version cible",
    traceability: "Traçabilité",
    selectDisplayToAdd:
      "Veuillez cliquer sur un présentoir à ajouter au point de vente",
    addComment: "Ajouter un commentaire",
    serialNumber: "Numéro de série",
    closed: "Porte fermée",
    mismatchFault: "Erreur de détection",
    salesReportEmail: "Destinataires du rapport des ventes",
    display: "Présentoire",
    locker: "Casier",
    network: "Réseau",
    pendingContracts: "Cartes de dépôt à envoyer",
    pending: "Actions en attente",
    title: "Titre",
    colors: "Couleurs",
    batteryUpdated: "Batterie mise à jour",
    outOfDateDisplays: "Pas de mise à jour depuis {minutes} min",
    batteryLevel: "Niveau de battery (mV)",
    faultCount: "Fautes",
    storeTimeout: "Délai d'alerte du point de vente (minutes)",
    disabled: "Désactivé",
    disableLocker: "Desactivation de serrure",
    command: "Commande",
    selectExport: "Type de rapport",
    reportFilter: "Rapport",
    state: "Etat",
    tags: "Cartes RFID"
  },
  type: {
    order: {
      label: "Type",
      same: "Même consigne",
      other: "Autre consigne",
      none: "Sans consigne",
      same_bottle: "Échange",
      other_bottle: "Autre consigne",
      new_bottle: "Sans consigne",
    },
    code: {
      order: "Commande",
      supplier: "Chauffeur",
      technician: "Technicien",
      urgency: "Urgence",
      open_lock: "Ouverture casier",
    },
    visit: {
      label: "Type de visite",
      supplier: "Chauffeur",
      technical: "Technique",
      technician: "Technicien",
    },
    locker: {
      technician_visit: "Visite technicien",
      supplier_visit: "Visite chauffeur",
      urgency_code: "Urgency code",
      order_delivery: "Livraison de commande",
      order_return: "Retour de consigne",
      order_cancel: "Annulation de la commande",
      bo_user: "Back office",
      system: "Système",
    },
    transaction: {
      complete: "Complète",
      cancel: "Annulée",
      failure: "Échec",
    },
    message: {
      ORDER_EMAIL: "E-mail de commande",
      ORDER_SMS: "SMS de commande",
      SUPPLIER_EMAIL: "Fournisseur",
      TECHNICIAN_EMAIL: "Technicien",
      CONTRACT_EMAIL: "Carte de dépôt",
      DAILY_REPORT_EMAIL: "Rapport quotidien",
    },
    terminal: {
      actionSource: { 0: "Back Office", 1: "Borne" },
    },
  },

  validation: {
    verify: "Veuillez corriger le formulaire",
    required: "Ce champ ne doit pas être vide",
    minchar: "Un minimum de {chars} caractères est requis",
    repeatPassword: "Les mots de passe doivent être identiques",
    isEmail: "Adresse e-mail non valable",
    number: "Ce champ doit être un nombre",
    positive: "Ce champs doit contenir un nombre positif",
    numberlength: "Ce champ doit être un nombre à {chars} chiffres",
  },
  tooltip: {
    deleteProduct: "Supprimer définitivement ce produit",
    editProduct: "Éditer ce produit",
    listProduct: "Liste des produits",
    addProduct: "Ajouter un nouveau produit",
    deleteStore: "Supprimer définitivement ce point de vente",
    editStore: "Éditer le point de vente",
    listStore: "Liste des points de vente",
    addStore: "Ajouter un nouveau point de vente",
    showStore: "Voir le point de vente",
    showPrices: "Voir les tarifs",
    editPrices: "Éditer les tarifs",
    showDisplay: "Voir le présentoir",
    editDisplay: "Éditer le présentoir",
    deleteDisplay: "Supprimer définitivement ce présentoir",
    addOrder: "Simule une nouvelle commande",
    completeOrder: "Simule la finalisation de la commande",
    showOrder: "Voir la commande",
    listOrder: "Liste des commandes",
    editUser: "Modifier l'utilisateur",
    addUser: "Ajouter un utilisateur",
    deleteUser: "Supprimer définitivement l'utilisateur",
    addCode: "Créer un nouveau codee",
    displayCodes: "Voir les codes",
    hideCodes: "Cacher les codes",
    showCode: "Voir les détails du code",
    changeDateRange: "Changer les dates de validité",
    addTemplate: "Créer un modèle",
    showLog: "Voir les détails",
    addVisit: "Créer une demande de visite",
    showVisit: "Afficher la visite",
    listVisit: "Afficher toutes les visites",
    completeVisit: "Finaliser la visite",
    selectVisitType:
      "Sélectionner le type de visite pour cette demande (obligatoire)",
    batteryLevel: "Niveau de batterie",
    lastSupply: "Dernier approvisionnement",
    displayTechnicianVisits: "Afficher les visites de technicien",
    hideTechnicianVisits: "Masquer les visites de technicien",
    displaySupplierVisits: "Afficher les visites de chauffeur",
    hideSupplierVisits: "Masquer les visites de chauffeur",
    resetFaulty: "Réinitialiser",
    moveIn: "Créer l'article et le placer dans le casier",
    moveOut: "Retirer l'article du caiser",
    confirmContractSent: "Confirmer que la carte de dépôt a été envoyée",
    copyToClipboard: "Copier dans le presse-papiers",
    setFaulty: "Mettre le casier en défautt",
    exportOrder: "Filtrer et exporter vers un fichier CSV",
    filterOrder: "Filtrer les commandes",
    clearFilterOrder: "Effacer le filtre",
    addDisplay: "Créer un présentoir",
    showStatusReport: "Afficher le rapport",
    removeDisplay: "Retirer le présentoir du point de vente",
    resendMessage: "Renvoyer le message avec des commentaires",
    showMessages: "Afficher les messages",
    hideMessages: "Masquer les messages",
    addTerminal: "Créer une borne",
    deleteTerminal: "Supprimer la borne",
    refresh: "Rafraîchir",
    printDisplayReport: "Imprimer le rapport",
    resetFaultCount: "Nombre d'erreurs. Cliquez pour réinitialiser...",
    showDeliveryReport: "Afficher le bon de livraison",
    showLogsTerminal: "Voir les logs du terminal",
    downloadLogcat: "Download logcat",
    editTerminal: "Editer",
  },
  action: {
    new: "Création",
    send_message: "Envoi de message",
    complete: "Finalisation",
    retry_locker: "Changement de casier",
    create: "Création",
    report_locker_fault: "Signalement erreur de casier",
    cancel: "Annulation",
    set_faulty: "Signalement de défaut",
    reset_faulty: "Réinitialisation du défaut",
    lock_open: "Serrure déverrouillée",
    lock_close: "Serrure verrouillée",
    door_open: "Ouverture",
    door_close: "Fermeture",
    presence_detect: "Détection de présence",
    presence_undetect: "Perte de présence",
    article_move_in: "Placement de l'article",
    article_move_out: "Retrait de l'article",
    clear_locker_fault: "Réinitialisation du défaut",
    validate_transaction: "Valider la transaction",
    reset_fault_count: "Réinitialisation du nombre d'erreurs",
    disable: "Désactivation",
    update_terminal: "Mise à jour",
    execute_command: "Exécution de commande",
    create_terminal: "Création",
    delete_terminal: "Suppression",
  },

  api: {
    user: {
      profileUpdated: "Profil modifié",
      updated: "Utilisateur modifié",
      created: "User created",
      removed: "User removed",
    },
    display: {
      updated: "Présentoir modifié",
      created: "Présentoir ajouté",
      removed: "Présentoir retiré",
      itemAdded: "L'article a été ajouté",
      itemRemoved: "L'article a été supprimé",
      lockerResetFaulty: "Suppression de l'état d'erreur du casier",
      articleResetReserved: "Statut d'article réservé supprimé",
      displayOrLockerNotFound: "Présentoir ou casier non trouvé",
      lockerSetFaulty: "La serrure a été mise en défaut",
      added: "Le présentoir a été ajouté au point de vente",
      lockerNotFound: "Le casier #{lockerId} n'a pas été trouvé",
      deleted: "Présentoir supprimé",
      lockerResetFaultCount:
        "Le nombre d'erreurs de verrouillage a été remis à zéro",
    },
    price: {
      updated: "Tarifs mis à jour",
      created: "Tarif créé",
    },
    product: {
      updated: "Produit modifié",
      created: "Produit créé",
      removed: "Produit suppprimé",
      alertSaved: "Niveau d'alerte modifié",
    },
    store: {
      created: "Point de vente ajouté",
      updated: "Point de vente mis à jour",
      removed: "Point de vente supprimé",
      productAdded: "Produit ajouté au point de vente ",
      productRemoved: "Produit supprimé du point de vente",
      notFound: "Présentoir non trouvé",
      networkCreated: "Le réseau a été créé",
      networkUpdated: "Le réseau a été mis à jour",
      networkRemoved: "Le réseau a été supprimé",
    },
    order: {
      updated: "Commande modifiée",
      created: "Commande créée",
      removed: "Commande supprimée",
      complete: "Commande finaliséée",
      alreadyClosed: "La commande est déjà terminée",
      notFound: "La commande #{orderId} n'a pas été trouvée",
      retryEmptyLockerNotFound: "Pas de casier vide de secours",
      retryLockerNotFound: "Pas de produit de secours",
    },

    visit: {
      updated: "Visite modifiée",
      created: "Visite créée",
      removed: "Visite supprimée",
      complete: "Visite finaliséée",
      messageSent: "Le message a été envoyé",
    },
    template: {
      updated: "Modèle modifié",
      created: "Modèle ajouté",
      removed: "Modèle supprimé",
    },
    code: {
      updated: "Code modifié",
      created: "Code créé",
      removed: "Code supprimé",
    },
    param: {
      updated: "Paramètres modifiés",
      created: "Paramètres créés",
    },
    login: { failure: "E-mail ou mot de passe invalide" },
    contract: { sent: "Confirmation enregistrée" },
    terminal: {
      updated: "La borne a été mise à jour",
      created: "La borne a été créée",
      removed: "La borne a été supprimée",
    },
    doc: {
      updated: "La documentation a été mise à jour",
      created: "La documentation a été créée",
      removed: "La documentation a été supprimée",
      notFound: "Documentation non trouvée",
    },
  },
};
