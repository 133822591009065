<template>
  <q-table
    :rows="rows"
    :columns="columnsI18n"
    row-key="id"
    v-model:pagination="pagination"
    :loading="loading"
    :filter="filter"
    @request="onRequest"
    binary-state-sort
    class="sticky-header-table size-lg"
  >
    <template v-slot:body-cell-batteryUpdated="props">
      <q-td :props="props">
        <div>
          {{
            props.value ? $moment(props.value).format("DD/MM/YYYY HH:mm") : ""
          }}
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-store="props">
      <q-td :props="props">
        <div v-if="props.value">
          <router-link
            :to="'/store/' + props.value.id"
            class="vito-link text-bold"
          >
            {{ props.value.name }}
          </router-link>
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-template="props">
      <q-td :props="props">
        <div>
          {{ props.value.name }}
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-actions="props">
      <q-td :props="props">
        <div class="q-gutter-x-sm">
          <q-btn
            icon="local_shipping"
            text-color="green-vito"
            square
            round
            dense
            @click="deliveryReport(props.row)"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.showDeliveryReport") }}
            </q-tooltip>
          </q-btn>

          <q-btn
            icon="o_verified"
            text-color="green-vito"
            square
            round
            dense
            @click="statusReport(props.row)"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.showStatusReport") }}
            </q-tooltip>
          </q-btn>
          <q-btn
            icon="edit"
            text-color="green-vito"
            square
            round
            dense
            @click="displayDisplay(props.row)"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.showDisplay") }}
            </q-tooltip>
          </q-btn>
        </div>
      </q-td>
    </template>
  </q-table>

  <q-dialog v-model="showCard" ref="editDisplayDialog">
    <display-card @refresh="refresh" :displayId="selectedDisplayId" />
  </q-dialog>

  <q-dialog v-model="showReport" ref="reportDisplayDialog">
    <display-report @refresh="refresh" :displayId="selectedDisplayId" />
  </q-dialog>

  <q-dialog v-model="showDeliveryReport" ref="reportDisplayDeliveryDialog">
    <display-delivery-report
      @refresh="refresh"
      :displayId="selectedDisplayId"
    />
  </q-dialog>
</template>
<script>
import DisplayApi from "../../apis/Display";
import DisplayCard from "./DisplayCard.vue";
import DisplayReport from "./DisplayReport.vue";
import DisplayDeliveryReport from "./DisplayDeliveryReport.vue";
//import { DisplayStatus } from "./display.enum";
export default {
  props: ["filter"],
  emits: ["refresh"],
  components: { DisplayCard, DisplayReport, DisplayDeliveryReport },

  data: () => {
    return {
      showCard: false,
      showReport: false,
      showDeliveryReport: false,
      selectedDisplayId: null,
      loading: false,
      pagination: {
        sortBy: "id",
        descending: false,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 0,
      },
      rows: [],
    };
  },
  methods: {
    async onRequest(props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const filter = props.filter;
      this.loading = true;

      try {
        const startRow = (page - 1) * rowsPerPage;

        const pageReponse = await DisplayApi.paginate({
          startRow,
          fetchCount: rowsPerPage,
          filter,
          sortBy,
          descending,
        });

        this.rows = pageReponse.data.items;
        this.pagination.rowsNumber = pageReponse.data.count;
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async refresh() {
      this.showCard = false;
      this.onRequest({
        pagination: this.pagination,
        filter: this.filter,
      });
    },
    displayDisplay(display) {
      //console.log(display);
      this.selectedDisplayId = display.id;
      this.showCard = true;
    },
    async statusReport(display) {
      this.selectedDisplayId = display.id;
      this.showReport = true;
    },

    async deliveryReport(display) {
      this.selectedDisplayId = display.id;
      this.showDeliveryReport = true;
    },
  },

  async mounted() {
    this.$store.state.title = "button.displays";
    this.onRequest({
      pagination: this.pagination,
      filter: this.filter,
    });
  },

  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "left",
          field: "id",
          sortable: true,
        },
        {
          name: "name",
          label: this.$t("label.name"),
          field: "name",
          align: "left",
          sortable: true,
        },
        {
          name: "store",
          label: this.$t("label.store"),
          field: "store",
          align: "left",
          sortable: false,
        },
        {
          name: "battery",
          label: this.$t("label.battery"),
          field: "battery",
          align: "left",
          sortable: false,
        },
        {
          name: "batteryUpdated",
          align: "left",
          label: this.$t("label.batteryUpdated"),
          field: "batteryUpdated",
          sortable: true,
        },
        {
          name: "version",
          label: this.$t("label.version"),
          field: "version",
          align: "left",
          sortable: false,
        },
        {
          name: "targetVersion",
          label: this.$t("label.targetVersion"),
          field: "targetVersion",
          align: "left",
          sortable: true,
        },
        {
          name: "template",
          label: this.$t("label.template"),
          field: "template",
          align: "left",
          sortable: false,
        },
        {
          name: "isActive",
          label: this.$t("label.active"),
          field: "isActive",
          align: "center",
          sortable: true,
        },
        { name: "actions", style: "width:70px" },
      ];
      return columns;
    },
  },
};
</script>
