import Api from "./Api";
export default {
  getLogs(id) {
    return Api().get(`/locker/${id}/logs`);
  },

  paginateLog(paginate) {
    return Api().post("/locker/page", paginate);
  },

  findOne(id) {
    return Api().get(`/locker/${id}`);
  },
};
