<template>
  <q-card
    bordered
    class="q-pa-sm shadow-1"
    style="width: 320px; max-width: 100vw"
  >
    <q-card-section horizontal class="full-width justify-between">
      <div style="width: 100px" class="text-center">
        <q-img :src="productImage" />
      </div>
      <q-card-section v-if="action != 'list'">
        <div class="text-h6 text-green-vito">
          {{ form.name }} {{ form.weight }} kg
        </div>
        <div class="text-boldd text-green-vito">
          {{ $t("label.available") }}: {{ form.availableChargeCount }}
        </div>
        <div class="text-dd text-green-vito">
          {{ $t("label.charge") }}: {{ form.chargeCount }}
        </div>
        <div class="text-h6d text-green-vito">
          {{ $t("label.returnable") }}: {{ form.returnableCount }}
        </div>
        <div class="text-h6d text-green-vito">
          {{ $t("label.levelAlert") }}: {{ form.levelAlert }}
        </div>
      </q-card-section>
      <div v-if="action == 'display'" class="row q-gutter-x-sm no-wrap">
        <div>
          <q-btn
            v-if="editingPrice"
            icon="visibility"
            square
            round
            dense
            @click="handleCancel"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.showPrices") }}
            </q-tooltip>
          </q-btn>
          <q-btn v-else icon="edit" square round dense @click="handleEdit">
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.editPrices") }}
            </q-tooltip>
          </q-btn>
        </div>
      </div>
    </q-card-section>
    <q-markup-table flat bordered v-if="!editingPrice">
      <tbody>
        <tr v-for="price in form.prices" v-bind:key="price.id">
          <td class="text-left">{{ $t("label." + price.name) }}</td>
          <td class="text-right">{{ price.price }} {{ currency }}</td>
        </tr>
      </tbody>
    </q-markup-table>

    <q-card-section class="col" v-if="editingPrice" style="min-width: 320px">
      <q-form>
        <q-input
          ref="levelAlert"
          square
          filled
          clearable
          type="number"
          v-model.number="form.levelAlert"
          :label="$t('label.levelAlert')"
          :hint="$t('hint.number')"
          lazy-rules
          :rules="[isNumber]"
          @update:model-value="saveLevelAlert"
        ></q-input>
      </q-form>
      <q-toolbar
        class="text-green-vito bg-grey-1 q-mt-md q-mb-sm q-pl-none shadow-1"
      >
        <q-btn-dropdown
          stretch
          flat
          :label="$t('button.prices')"
          dropdown-icon="more_vert"
          menu-anchor="bottom left"
          menu-self="top left"
        >
          <q-list>
            <q-item-label header>{{ $t("label.selectPrices") }}</q-item-label>
            <q-item
              v-for="price in pricing"
              :key="price.id"
              clickable
              tabindex="0"
            >
              <q-item-section avatar>
                <q-checkbox
                  color="secondary"
                  v-model="selectedPrices"
                  :val="price.id"
                  @update:model-value="handlePricingSelection"
                  :label="$t('label.' + price.name)"
                />
              </q-item-section>
            </q-item>
            <q-item-section class="q-mt-md">
              <q-btn
                :to="'/pricing/store/' + store.id + '/product/' + product.id"
                >{{ $t("button.managePricing") }}</q-btn
              >
            </q-item-section>
          </q-list>
        </q-btn-dropdown>
      </q-toolbar>
      <q-form class="q-gutter-y-md q-mt-sm">
        <q-input
          v-for="(price, idx) in form.prices"
          :key="price.id"
          :ref="'price_' + price.id"
          square
          filled
          clearable
          type="number"
          v-model.number="form.prices[idx].price"
          :label="$t('label.' + price.name)"
          :hint="$t('hint.number') + ', ' + $t('hint.required')"
          lazy-rules
          :rules="[this.required, this.isNumber]"
        >
        </q-input>
      </q-form>
    </q-card-section>

    <q-card-section v-if="errorMessage">
      <div class="text-red-vito text-center">
        <strong>{{ errorMessage }}</strong>
      </div>
    </q-card-section>

    <q-card-actions class="q-px-md justify-end" v-if="editingPrice">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        @click="handleCancel"
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="$t('button.save')"
        @click="handleSavePrices"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
export default {
  props: ["product", "store", "action", "pricing"],
  emits: ["levelAlertUpdate"],
  data: () => {
    return {
      files: [],
      form: {
        id: null,
        name: "",
        weight: 0,
        isActive: null,
        prices: [],
        returnableCount: 0,
        chargeCount: 0,
        availableChargeCount: 0,
        levelAlert: -2,
      },
      errorMessage: null,
      selectedPrices: [],
      editingPrice: false,
    };
  },

  async mounted() {
    try {
      this.form = JSON.parse(JSON.stringify(this.product));
      //console.log("form", this.form);
      //console.log("prices", this.form.prices);
      //console.log("pricing", this.pricing);
      this.form.prices.forEach((price) => {
        //console.log("price", price);
        let pricing = this.pricing.find(
          (pricingItem) => pricingItem.id == price.pricingId
        );
        if (pricing) price.position = pricing.position;
      });
      this.selectedPrices = this.form.prices.map((price) => price.pricingId);
      //console.log(this.form, this.selectedPrices);
    } catch (error) {
      this.errorMessage = error.message;
      console.error(error.message);
    }
  },
  methods: {
    async saveLevelAlert() {
      this.errorMessage = null;
      if (!this.$refs["levelAlert"].validate()) {
        this.errorMessage = this.$t("validation.verify");
        return;
      }
      if (!this.errorMessage) {
        try {
          this.$emit("levelAlertUpdate", {
            store: this.store,
            product: this.form,
          });
        } catch (error) {
          const message = error.response
            ? error.response.data.message
            : error.message;
          this.errorMessage = message;
          this.$q.notify({
            type: "negative",
            message: message,
          });
        }
      }
    },

    handlePricingSelection(val) {
      if (val.length == 0) {
        this.form.prices = [];
      } else {
        val.forEach((selectedPricingId) => {
          let found = this.form.prices.find(
            (p) => p.pricingId == selectedPricingId
          );
          if (!found) {
            //console.log("this.pricing", this.pricing);
            let pricing = this.pricing.find((p) => p.id == selectedPricingId);
            let origPrice = this.product.prices.find(
              (p) => p.pricingId == selectedPricingId
            );
            this.form.prices.push({
              id: origPrice ? origPrice.id : 0,
              pricingId: selectedPricingId,
              name: pricing.name,
              price: origPrice ? origPrice.price : 0,
              position: pricing.position,
            });
          }
        });
        //console.log(this.form.prices);

        this.form.prices.forEach((p) => {
          if (!this.selectedPrices.includes(p.pricingId)) {
            this.form.prices = this.form.prices.filter(
              (pi) => pi.pricingId !== p.pricingId
            );
          }
        });
        //console.log(this.form.prices);
        this.form.prices.sort((a, b) =>
          a.position > b.position ? 1 : b.position > a.position ? -1 : 0
        );
      }
    },

    handleEdit() {
      //console.log(this.form.prices[0], this.product.prices[0]);

      //this.form = { ...this.product };
      this.editingPrice = true;
    },

    handleCancel() {
      this.form = JSON.parse(JSON.stringify(this.product));
      this.editingPrice = false;
      this.errorMessage = null;
    },

    async handleSavePrices() {
      this.errorMessage = null;
      this.form.prices
        .map((p) => "price_" + p.id)
        .forEach((f) => {
          if (!this.$refs[f][0].validate()) {
            this.errorMessage = this.$t("validation.verify");
            return;
          }
        });
      if (!this.errorMessage) {
        try {
          this.$emit("priceUpdate", {
            store: this.store,
            product: this.form,
          });
          this.editingPrice = false;
        } catch (error) {
          const message = error.response
            ? error.response.data.message
            : error.message;
          this.errorMessage = message;
          this.$q.notify({
            type: "negative",
            message: message,
          });
        }
      }
    },

    required(val) {
      return (
        (val && val.toString().length > 0) || this.$t("validation.required")
      );
    },
    short(val) {
      return (
        (val && val.length > 1) || this.$t("validation.minchar", { chars: 2 })
      );
    },
    isNumber(val) {
      return !isNaN(val) || this.$t("validation.number");
    },
  },
  computed: {
    currency() {
      return this.$store.getters.currency;
    },
    title() {
      return `${this.form.name} ${this.form.weight}  kg`;
    },
    editing() {
      return this.action == "create" || this.action == "edit";
    },
    isActiveBg() {
      return this.form.isActive == false ? "bg-grey-3" : "";
    },
    productImage() {
      return (
        process.env.VUE_APP_BASE_URL + "/product/images/" + this.product.image
      );
    },
  },
};
</script>
