<template>
  <div class="q-mb-md">
    <q-toolbar class="text-green-vito bg-grey-1 q-mb-sm q-pl-none shadow-1">
      <q-btn-dropdown
        stretch
        flat
        :label="$t('button.displays')"
        dropdown-icon="more_vert"
        menu-anchor="bottom left"
        menu-self="top left"
        icon-left
      >
        <q-list v-if="displays.length == 0">
          <q-item-label header>{{
            $t("label.selectDisplayToAdd")
          }}</q-item-label>

          <q-item
            v-for="display in availableDisplays"
            :key="display.id"
            clickable
            @click="addToStore(display)"
          >
            <q-item-section avatar>
              <q-icon name="apps" color="green-vito" />
            </q-item-section>
            <q-item-section>
              {{ display.name }}
            </q-item-section>
          </q-item>
        </q-list>
        <q-item-section class="q-mt-md" v-if="displays.length == 0">
          <q-btn to="/admin/displays">{{ $t("button.manageDisplays") }}</q-btn>
        </q-item-section>
      </q-btn-dropdown>
    </q-toolbar>
    <div>
      <div v-for="display in displays" :key="display.id" class="row">
        <display-card
          :store="store"
          :display="display"
          :parentAction="action"
          :key="refreshCounter"
          @refresh="refreshAll"
          @displayUpdate="handleSaveDisplay"
          @refreshDisplay="refresh"
        />
      </div>
      <div v-if="!displays.length" class="text-grey-6 text-italic q-pl-sm">
        {{ $t("emptyList") }}
      </div>
    </div>
  </div>

  <!-- <q-dialog v-model="promptDisplayName" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-h6">{{ $t("label.displayName") }}</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <q-input
          dense
          v-model="displayName"
          autofocus
          @keyup.enter="prompt = false"
        />
      </q-card-section>

      <q-card-actions align="right" class="text-primary">
        <q-btn flat :label="$t('button.cancel')" v-close-popup />
        <q-btn
          flat
          :label="$t('button.add') + ' ' + $t('display')"
          v-close-popup
          @click="addDisplay"
        />
      </q-card-actions>
    </q-card>
  </q-dialog> -->
</template>
<script>
import DisplayCard from "./DisplayCard.vue";
import DisplayApi from "../../apis/Display";
import TemplateApi from "../../apis/Template";
export default {
  components: { DisplayCard },
  props: ["store", "storeId", "parentAction", "key"],
  emits: ["refresh"],

  data: () => {
    return {
      refreshCounter: 0,
      action: "display",
      displays: [],
      templates: [],
      displayName: "",
      promptDisplayName: false,
      selectedTemplate: null,
    };
  },
  mounted() {
    this.$store.state.title = "button.stores";
    this.action = this.parentAction;
    this.getAllDisplays();
  },
  methods: {
    async cancel() {
      //console.log("cancel");
      await this.getAllDisplays();
      this.action = "list";
    },
    async refresh() {
      await this.getAllDisplays();
      this.refreshCounter += 1;
    },
    async getAllDisplays() {
      try {
        const templatesResponse = await TemplateApi.findAll();
        this.templates = templatesResponse.data;
        //console.log(this.storeId);
        const displaysResponse = await DisplayApi.findByStoreId(this.storeId);
        this.displays = displaysResponse.data;
        if (this.displays.length == 0) {
          const availableDisplays = await DisplayApi.findAvailable();
          this.availableDisplays = availableDisplays.data;
        }
        //console.log(displaysResponse);
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async refreshAll() {
      //console.log("refreshing displays");
      //await this.getAllDisplays();
      this.action = "display";
      this.refreshCounter += 1;
      this.$emit("refresh");
    },
    /* handleAddDisplay(template) {
      this.selectedTemplate = template;
      this.promptDisplayName = true;
      //console.log(template);
    }, */
    async addToStore(display) {
      try {
        const displayResponse = await DisplayApi.addToStore(
          display.id,
          this.storeId
        );
        this.$q.notify({
          type: "positive",
          message: this.$t(displayResponse.data.message),
        });
        await this.refreshAll();
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async handleSaveDisplay(display) {
      //console.log(display);
      try {
        const updateDisplayResponse = await DisplayApi.save(
          display.id,
          display
        );
        await this.refreshAll();
        this.$q.notify({
          type: "positive",
          message: this.$t(updateDisplayResponse.data.message),
        });
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
  },
};
</script>
