<template>
  <q-card
    flat
    class="q-pa-sm full-width no-padding-md"
    style="max-width: 100vw"
  >
    <q-card-section class="row q-pb-xs text-green-vito justify-between">
      <div class="text-h5">
        {{ $t("title.contracts") }}
      </div>
      <div class="q-gutter-x-md row" style="height: 40px">
        <div class="flex column justify-center">
          <q-btn
            v-if="showMessages"
            color="green-vito"
            round
            dense
            icon="mail_outline"
            @click="showMessages = !showMessages"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.showMessages") }}
            </q-tooltip>
          </q-btn>
          <q-btn
            v-else
            round
            dense
            icon="mail_outline"
            @click="showMessages = !showMessages"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.showMessages") }}
            </q-tooltip>
          </q-btn>
        </div>
      </div>
    </q-card-section>

    <q-card-section v-if="showMessages">
      <contract-messages :key="refreshCounter" />
    </q-card-section>

    <q-card-section>
      <contract-pages :filter="filter" :key="refreshCounter" />
    </q-card-section>
  </q-card>
</template>
<script>
import { useI18n } from "vue-i18n";
import ContractPages from "../components/contract/ContractPages.vue";
import ContractMessages from "../components/contract/ContractMessages.vue";

export default {
  name: "ContractsPage",

  components: { ContractPages, ContractMessages },
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      showMessages: false,
      showNew: false,
      filter: "",
      refreshCounter: 0,
    };
  },
  methods: {
    refresh() {
      this.showNew = false;
      this.refreshCounter += 1;
    },
  },

  async mounted() {
    this.$store.state.title = "button.contracts";
  },

  computed: {},
};
</script>
