<template>
  <div class="row full-width" v-if="!loading && locker">
    <locker-card
      :locker="locker"
      :display="locker.display"
      :isPage="true"
      @refresh="refresh"
      :key="refreshCounter"
    />
  </div>
  <div v-if="!loading && !locker">
    <not-found
      :message="
        $t('api.display.lockerNotFound', { lockerId: $route.params.id })
      "
    />
  </div>
</template>
<script>
import LockerCard from "../components/locker/LockerCard.vue";
import LockerApi from "../apis/Locker";
import NotFound from "../components/LockerNotFound.vue";
export default {
  name: "LockerPage",
  components: { LockerCard, NotFound },

  data: () => {
    return {
      locker: null,
      refreshCounter: 0,
      loading: true,
    };
  },

  async mounted() {
    this.$store.state.title = "button.lockers";
    //console.log("lockerId", this.$route.params.id);
    await this.getLocker(this.$route.params.id);
    this.loading = false;
    //console.log(this.locker);
  },
  methods: {
    async getLocker(lockerId) {
      try {
        const lockerResponse = await LockerApi.findOne(lockerId);
        this.locker = lockerResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async refresh() {
      await this.getLocker(this.$route.params.id);
      this.refreshCounter += 1;
    },
  },
};
</script>
