export const ReversedMessageStatus = {
  0: "SUCCESS",
  1: "ERROR",
  2: "RETRY",
};
export const ReversedMessageType = {
  0: "ORDER_EMAIL",
  1: "ORDER_SMS",
  2: "SUPPLIER_EMAIL",
  3: "TECHNICIAN_EMAIL",
  4: "CONTRACT_EMAIL",
  5: "DAILY_REPORT_EMAIL",
};

export const MessageStatus = {
  SUCCESS: 0,
  ERROR: 1,
  RETRY: 2,
};
export const MessageType = {
  ORDER_EMAIL: { value: 0, icon: "shopping_cart" },
  ORDER_SMS: { value: 1, icon: "sms" },
  SUPPLIER_EMAIL: { value: 2, icon: "wifi_protected_setup" },
  TECHNICIAN_EMAIL: { value: 3, icon: "engineering" },
  CONTRACT_EMAIL: { value: 4, icon: "assignment" },
  DAILY_REPORT_EMAIL: { value: 5, icon: "attachment" },
};
