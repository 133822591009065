<template>
  <q-card
    bordered
    class="q-mt-md q-pa-md shadow-1"
    xstyle="xwidth: 550px; max-width: 90vw"
  >
    <q-card-section class="row text-green-vito no-wrap justify-between">
      <q-input filled v-model="doc.title" :label="$t('label.title')" />
      <q-input
        filled
        v-model.number="doc.position"
        type="number"
        :label="$t('label.position')"
      />

      <div><q-btn icon="close" flat round dense v-close-popup /></div>
    </q-card-section>

    <q-card-section class="col-12 col-sm-auto">
      <q-editor
        ref="editor"
        v-model="doc.content"
        min-height="300px"
        :dense="$q.screen.lt.md"
        :toolbar="[
          ['left', 'center', 'right', 'justify'],

          ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
          ['colors', 'hr', 'link'],
          ['print', 'fullscreen'],
          [
            {
              label: $q.lang.editor.formatting,
              icon: $q.iconSet.editor.formatting,
              list: 'no-icons',
              options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code'],
            },
            {
              label: $q.lang.editor.fontSize,
              icon: $q.iconSet.editor.fontSize,
              fixedLabel: true,
              fixedIcon: true,
              list: 'no-icons',
              options: [
                'size-1',
                'size-2',
                'size-3',
                'size-4',
                'size-5',
                'size-6',
                'size-7',
              ],
            },
            {
              label: $q.lang.editor.defaultFont,
              icon: $q.iconSet.editor.font,
              fixedIcon: true,
              list: 'no-icons',
              options: ['default_font', 'arial', 'courier_new'],
            },
            'removeFormat',
          ],
          ['quote', 'unordered', 'ordered', 'outdent', 'indent'],
          ['undo', 'redo'],
          ['viewsource'],
        ]"
        :fonts="{
          arial: 'Arial',
          courier_new: 'Courier New',
        }"
      >
        <template v-slot:colors>
          <q-btn-dropdown
            dense
            no-caps
            ref="colors"
            no-wrap
            unelevated
            :label="$t('label.colors')"
            size="sm"
          >
            <q-list dense>
              <q-item
                tag="label"
                clickable
                @click="color('backColor', highlight)"
              >
                <q-item-section side>
                  <q-icon name="highlight" />
                </q-item-section>
                <q-item-section>
                  <q-color
                    v-model="highlight"
                    default-view="palette"
                    no-header
                    no-footer
                    :palette="[
                      '#cce0df',
                      '#ffccccaa',
                      '#ffe6ccaa',
                      '#ffffccaa',
                      '#ccffccaa',
                      '#ccffffaa',
                      '#cce6ffaa',
                      '#ccccffaa',
                      '#e6ccffaa',
                      '#ffccffaa',
                      '#006560',
                      '#dc1e14',
                      '#ff8000aa',
                      '#ffff00aa',
                      '#00ff80aa',
                      '#00ffffaa',
                      '#0080ffaa',
                      '#0000ffaa',
                      '#8000ffaa',
                      '#ff00ffaa',
                    ]"
                    class="my-picker"
                  />
                </q-item-section>
              </q-item>
              <q-item
                tag="label"
                clickable
                @click="color('foreColor', foreColor)"
              >
                <q-item-section side>
                  <q-icon name="format_paint" />
                </q-item-section>
                <q-item-section>
                  <q-color
                    v-model="foreColor"
                    no-header
                    no-footer
                    default-view="palette"
                    :palette="[
                      '#006560',
                      '#dc1e14',
                      '#ff8000',
                      '#ffff00',
                      '#00ff00',
                      '#00ffff',
                      '#0080ff',
                      '#0000ff',
                      '#8000ff',
                      '#ff00ff',
                    ]"
                    class="my-picker"
                  />
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </template>
      </q-editor>
    </q-card-section>
    <q-card-actions class="q-px-md justify-end">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        v-close-popup
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="$t('button.save')"
        @click="handleSave"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
export default {
  emits: ["saveDoc"],
  props: ["selectedDoc"],
  data() {
    return {
      doc: { content: "" },
      foreColor: "#000000",
      highlight: "#ffff00aa",
    };
  },

  async mounted() {
    this.doc = { ...this.selectedDoc };
  },

  methods: {
    handleSave() {
      //console.log("sav");
      this.$emit("saveDoc", this.doc);
    },
    color(cmd, name) {
      const edit = this.$refs.editor;
      this.$refs.colors.hide();
      edit.caret.restore();
      edit.runCmd(cmd, name);
      edit.focus();
    },
  },
  computed: {},
};
</script>
