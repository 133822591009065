<template>
  <q-card
    flat
    class="q-pa-sm full-width no-padding-md"
    style="max-width: 100vw"
  >
    <q-card-section class="row text-green-vito justify-between">
      <div class="text-h5">{{ $t("title.visit") }} #{{ visit.id }}</div>
      <div class="q-gutter-x-sm row">
        <q-btn
          v-if="isSuperAdmin"
          ref="completeButton"
          color="primary"
          round
          dense
          icon="close"
          @click="showComplete = true"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.completeVisit") }}
          </q-tooltip>
        </q-btn>
        <q-btn
          v-if="visit.status !== 'complete'"
          icon="forward_to_inbox"
          square
          round
          dense
          @click="newMessage = true"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.resendMessage") }}
          </q-tooltip>
        </q-btn>
        <q-btn
          icon="engineering"
          square
          round
          dense
          to="/visits"
          @click="$emit('cancel')"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.listVisit") }}
          </q-tooltip>
        </q-btn>
      </div>
    </q-card-section>

    <q-card-section class="row justify-start no-padding-md">
      <q-card-section class="col-12 col-sm-auto no-padding-md">
        <q-markup-table flat class="highlight-values">
          <tr>
            <td>{{ $t("label.date") }}</td>
            <td>
              <span>{{ $moment(visit.date).format("DD/MM/Y HH:mm") }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.store") }}</td>
            <td>
              <span>{{ visit.store.name }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("type.visit.label") }}</td>
            <td>
              <span>{{ $t("type.visit." + visit.type) }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.status") }}</td>
            <td>
              <span>{{ $t("status." + visit.status) }}</span>
            </td>
          </tr>
        </q-markup-table>
      </q-card-section>
      <q-separator vertical />
      <q-card-section class="col-12 col-sm-auto no-padding-md">
        <q-markup-table flat class="highlight-values">
          <tr>
            <td>{{ $t("label.ticket") }}</td>
            <td>
              <span>{{ visit.ticket }}</span>
            </td>
          </tr>

          <tr>
            <td>{{ $t("label.securityCode") }}</td>
            <td>
              <span>{{ visit.code ? visit.code.code : "" }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.source") }}</td>
            <td>
              <span>{{ visit.source }}</span>
            </td>
          </tr>
        </q-markup-table>
      </q-card-section>
    </q-card-section>

    <q-card-section>
      <visit-comments :visit="visit" :key="refreshCounter" />
    </q-card-section>

    <q-card-section>
      <visit-logs :visit="visit" :key="refreshCounter" />
    </q-card-section>
  </q-card>

  <q-dialog v-model="showComplete" ref="completeVisitDialog">
    <visit-complete :visit="visit" @refresh="refresh" />
  </q-dialog>

  <q-dialog v-model="newMessage" ref="newMessagetDialog">
    <visit-message-new :visit="visit" @refresh="refresh" />
  </q-dialog>
</template>

<script>
import VisitLogs from "./VisitLogs.vue";
import VisitComments from "./VisitComments.vue";
import VisitComplete from "./VisitComplete.vue";
import VisitMessageNew from "./VisitMessageNew.vue";

export default {
  props: ["visit"],
  components: { VisitLogs, VisitComplete, VisitComments, VisitMessageNew },
  emits: ["refresh", "cancel"],

  data: () => {
    return {
      newMessage: false,
      showComplete: false,
      errorMessage: null,
      refreshCounter: 0,
    };
  },
  async mounted() {
    //console.log("visit", this.visit.logs);
  },
  methods: {
    refresh() {
      //console.log("refresh");
      this.showComplete = false;
      this.$emit("refresh");
    },
    required(val) {
      return (
        (val && val.toString().length > 0) || this.$t("validation.required")
      );
    },
    short(val) {
      return (
        (val && val.length > 1) || this.$t("validation.minchar", { chars: 2 })
      );
    },
    isNumber(val) {
      return !isNaN(val) || this.$t("validation.number");
    },
  },
  computed: {
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
  },
};
</script>
