import Api from "./Api";
export default {
  findOne(id) {
    return Api().get("/price/" + id);
  },
  save(id, form) {
    return Api().patch("/price/" + id, form);
  },
  saveProduct(id, form) {
    return Api().patch("/price/product/" + id, form);
  },

  create(form) {
    return Api().post("/price", form);
  },
  findPricing() {
    return Api().get("/pricing");
  },
};
