<template>
  <q-fab
    ref="addButton"
    color="primary"
    v-model="showNew"
    icon="add"
    class="absolute add-template"
    style="top: 0; right: 20px; transform: translateY(25%)"
    @click="handleAddButton"
  >
    <q-tooltip class="bg-green-vito text-no-wrap" target=".add-template">
      {{ $t("tooltip.addTemplate") }}
    </q-tooltip>
  </q-fab>
  <q-card flat class="full-width" style="max-width: 90vw">
    <q-card-section class="row q-pa-none q-pb-md">
      <q-select
        class="col-10 col-sm-4"
        v-model="selectedTemplate"
        :options="templates"
        :label="$t('label.template')"
        filled
        borderless
        square
        ref="selectedTemplate"
        @update:model-value="buildDisplay"
      >
        <template v-slot:selected>
          <q-item>
            <q-item-section>
              <q-item-label>
                {{ selectedTemplate ? selectedTemplate.name : "" }}
              </q-item-label>
              <q-item-label caption>{{
                selectedTemplate ? selectedTemplate.description : ""
              }}</q-item-label>
            </q-item-section>
          </q-item>
        </template>
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-item-section>
              <q-item-label>{{ scope.opt.name }}</q-item-label>
              <q-item-label caption>{{ scope.opt.description }}</q-item-label>
            </q-item-section>
          </q-item>
        </template>
        <template v-slot:prepend>
          <q-icon name="extension" />
        </template>
      </q-select>
    </q-card-section>
    <div v-if="selectedTemplate">
      <q-separator />
      <q-card-section class="row q-pl-none q-pb-md q-pr-md q-pt-md">
        <q-form class="row q-gutter-md">
          <q-input
            class="col-12 col-sm-4"
            ref="name"
            square
            filled
            clearable
            v-model="selectedTemplate.name"
            :label="$t('label.name')"
            :hint="$t('hint.minchar', { chars: 3 })"
            lazy-rules
            :rules="[required, short]"
          >
            <template v-slot:prepend>
              <q-icon name="extension" />
            </template>
          </q-input>
          <q-input
            class="col-12 col-sm-5"
            ref="description"
            square
            filled
            clearable
            v-model="selectedTemplate.description"
            :label="$t('label.description')"
          >
            <template v-slot:prepend>
              <q-icon name="extension" />
            </template>
          </q-input>

          <q-input
            class="col-12 col-sm-3"
            ref="sides"
            :readonly="selectedTemplate.readonly"
            square
            filled
            v-model.number="selectedTemplate.faces"
            :hint="selectedTemplate.readonly ? '' : $t('hint.number')"
            type="number"
            :label="$t('label.sides')"
            lazy-rules
            :rules="[isPositive]"
            @update:model-value="buildDisplay"
          >
            <template v-slot:prepend>
              <q-icon name="content_copy" />
            </template>
          </q-input>
          <q-input
            class="col-12 col-sm-3"
            ref="rows"
            :readonly="selectedTemplate.readonly"
            square
            filled
            v-model.number="selectedTemplate.rows"
            :hint="selectedTemplate.readonly ? '' : $t('hint.number')"
            type="number"
            :label="$t('label.rows')"
            lazy-rules
            :rules="[isPositive]"
            @update:model-value="buildDisplay"
          >
            <template v-slot:prepend>
              <q-icon name="menu" />
            </template>
          </q-input>
          <q-input
            class="col-12 col-sm-3"
            ref="columns"
            square
            :readonly="selectedTemplate.readonly"
            filled
            v-model.number="selectedTemplate.columns"
            :hint="selectedTemplate.readonly ? '' : $t('hint.number')"
            type="number"
            :label="$t('label.columns')"
            lazy-rules
            :rules="[isPositive]"
            @update:model-value="buildDisplay"
          >
            <template v-slot:prepend>
              <q-icon name="menu" style="transform: rotate(0.25turn)" />
            </template>
          </q-input>
        </q-form>
      </q-card-section>
      <q-card-section v-if="errorMessage">
        <div class="text-red-vito text-center">
          <strong>{{ errorMessage }}</strong>
        </div>
      </q-card-section>

      <q-card-section class="row wrap q-gutter-x-sm q-pa-none q-pt-sm">
        <q-toolbar class="text-green-vito bg-grey-1 q-mb-sm q-pl-none shadow-1">
          <q-btn-dropdown
            stretch
            flat
            :label="$t('button.loadingPlan')"
            dropdown-icon="more_vert"
            menu-anchor="bottom left"
            menu-self="top left"
            icon-left
          >
          </q-btn-dropdown>
        </q-toolbar>
      </q-card-section>

      <q-card-section class="row q-pa-none q-gutter-md">
        <div class="col-12 col-sm-3 q-pt-md q-pr-md">
          <q-item-label class="text-italic text-green-vito q-py-sm">{{
            $t("label.selectProductAndAssign")
          }}</q-item-label>
          <product-selection
            v-if="selectedProduct"
            :options="products"
            :selectedProduct="selectedProduct"
            type="list"
            @productSelectionChange="productSelectionChange"
          />
        </div>
        <div class="col-12 col-sm-6">
          <div
            v-for="(side, idxFace) in sides"
            :key="idxFace"
            class="q-pa-xs q-pr-md scroll"
          >
            <div class="text-green-vito text-bold q-my-md">
              {{ $t("label.side") }}
              {{ String.fromCharCode("A".charCodeAt(0) + idxFace) }}
            </div>
            <q-markup-table separator="cell">
              <thead></thead>
              <tbody>
                <tr
                  v-for="(row, idxRow) in selectedTemplate.rows"
                  :key="idxRow"
                >
                  <td
                    class="no-padding"
                    v-for="(column, idxCol) in selectedTemplate.columns"
                    :key="idxCol"
                    @click="handleLoadingPlan(idxFace, idxRow, idxCol)"
                  >
                    <div
                      class="column justify-between"
                      style="min-height: 160px"
                    >
                      <div
                        class="
                          text-green-vito text-center text-bold
                          bg-green-vito-light
                        "
                      >
                        {{ sides[idxFace][idxRow][idxCol].doorName }}
                      </div>
                      <single-product
                        :key="refreshCounter"
                        v-if="sides[idxFace][idxRow][idxCol].product"
                        :selectedItem="sides[idxFace][idxRow][idxCol].product"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </q-markup-table>
          </div>
        </div>
      </q-card-section>
      <q-separator class="q-my-md" />
      <q-card-actions class="q-px-md justify-between">
        <q-btn
          v-if="selectedTemplate.id !== 0 && !selectedTemplate.readonly"
          unelevated
          size="md"
          color="red-vito"
          :label="$t('button.deleteDefinitively')"
          @click="handleDeleteButton"
        />
        <q-space v-else />
        <div>
          <q-btn
            unelevated
            size="md"
            class="text-green-vito"
            :label="$t('button.cancel')"
            @click="refresh"
          />
          <q-btn
            unelevated
            size="md"
            class="bg-green-vito text-white"
            :label="$t('button.save')"
            @click="handleSave"
          />
        </div>
      </q-card-actions>
    </div>
  </q-card>
</template>

<script>
import TemplateApi from "../../apis/Template";
import ProductApi from "../../apis/Product";
import ProductSelection from "../product/ProductSelection.vue";
import SingleProduct from "../product/SingleProduct.vue";
export default {
  components: { ProductSelection, SingleProduct },
  data: () => {
    return {
      templates: [],
      selectedTemplate: null,
      sides: [],
      showNew: false,
      errorMessage: null,
      selectedProduct: null,
      products: null,
      refreshCounter: 0,
    };
  },
  async mounted() {
    this.$store.state.title = "button.templates";
    //console.log("templates mounted");
    await this.getTemplates();
    await this.getProducts();
  },
  methods: {
    handleDeleteButton() {
      this.$q
        .dialog({
          title: this.$t("title.confirmDelete"),
          message: this.$t("message.confirmDeleteTemplate"),
          ok: {
            color: "red-vito",
            label: this.$t("button.deleteDefinitively"),
          },
          cancel: {
            label: this.$t("button.cancel"),
            color: "green-vito",
          },
          persistent: true,
        })
        .onOk(() => {
          this.deleteTemplate();
        });
    },
    async deleteTemplate() {
      try {
        if (!this.selectedTemplate.readonly) {
          const deleteResponse = await TemplateApi.delete(
            this.selectedTemplate.id
          );
          this.$q.notify({
            type: "positive",
            message: this.$t(deleteResponse.data.message),
          });
        }
        //console.log(saveResponse.data);
        this.refresh();
      } catch (error) {
        let message = error.response
          ? error.response.data.message
          : error.message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
        //console.error(message);
      }
    },

    handleAddButton() {
      this.sides = new Array(new Array(new Array()));
      this.sides[0][0][0] = { doorName: "A1" };
      this.selectedTemplate = {
        id: 0,
        name: "New Template",
        description: "",
        faces: 1,
        rows: 1,
        columns: 1,
        readonly: false,
        loadingPlan: null,
      };
    },
    async handleSave() {
      this.errorMessage = null;
      //console.log(this.selectedTemplate);
      this.errorMessage = null;
      ["name", "description", "sides", "rows", "columns"].forEach((f) => {
        if (!this.$refs[f].validate()) {
          this.errorMessage = this.$t("validation.verify");
          return;
        }
      });
      if (!this.errorMessage) {
        try {
          this.selectedTemplate.loadingPlan = this.sides;
          const saveResponse =
            this.selectedTemplate.id == 0
              ? await TemplateApi.create(this.selectedTemplate)
              : await TemplateApi.save(this.selectedTemplate);
          //console.log(saveResponse);

          this.$q.notify({
            type: "positive",
            message: this.$t(saveResponse.data.message),
          });

          await this.getTemplates();
        } catch (error) {
          const message = error.response
            ? error.response.data.message
            : error.message;
          this.errorMessage = message;
          this.$q.notify({
            type: "negative",
            message: message,
          });
        }
      }
    },
    async refresh() {
      this.errorMessage = null;
      await this.getTemplates();
      this.selectedTemplate = null;
    },
    async getTemplates() {
      try {
        const templatesResponse = await TemplateApi.findAll();
        this.templates = templatesResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    buildDisplay() {
      //console.log("selectemplate", this.selectedTemplate);
      this.sides = [];
      for (let i = 0; i < this.selectedTemplate.faces; i++) {
        let rows = [];
        for (let j = 0; j < this.selectedTemplate.rows; j++) {
          let cols = [];
          for (let k = 0; k < this.selectedTemplate.columns; k++) {
            /* let lockers = this.form.lockers.filter(
              (locker) =>
                locker.positionZ == i &&
                locker.positionY == j &&
                locker.positionX == k
            ); */
            let doorName =
              String.fromCharCode("A".charCodeAt(0) + i) +
              (k + 1 + j * this.selectedTemplate.columns);
            cols.push({
              x: k,
              y: j,
              z: i,
              doorName: doorName,
              product:
                this.selectedTemplate &&
                this.selectedTemplate.loadingPlan &&
                typeof this.selectedTemplate.loadingPlan[i] !== "undefined" &&
                typeof this.selectedTemplate.loadingPlan[i][j] !==
                  "undefined" &&
                this.selectedTemplate.loadingPlan[i][j][k]
                  ? this.selectedTemplate.loadingPlan[i][j][k].product
                  : null,
            });
          }
          rows.push(cols);
        }
        this.sides.push(rows);
        //console.log(this.sides);
      }

      //console.log("built", this.sides);
    },
    async getProducts() {
      try {
        const productsResponse = await ProductApi.findActive();
        this.products = productsResponse.data;
        this.selectedProduct = productsResponse.data.length
          ? productsResponse.data[2]
          : null;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    productSelectionChange(selectedItem) {
      //console.log("parsed", selectedItem);
      //console.log("change seleted from", this.selectedProduct.id);
      //console.log("change seleted to ", selectedItem.id);
      this.selectedProduct = selectedItem;
    },
    handleLoadingPlan(side, row, column) {
      //console.log(side, row, column);
      this.sides[side][row][column].product = this.sides[side][row][column]
        .product
        ? null
        : this.selectedProduct;
    },

    required(val) {
      return (
        (val && val.toString().length > 0) || this.$t("validation.required")
      );
    },
    short(val) {
      return (
        (val && val.length > 1) || this.$t("validation.minchar", { chars: 2 })
      );
    },
    isNumber(val) {
      return !isNaN(val) || this.$t("validation.number");
    },
    isPositive(val) {
      return (!isNaN(val) && val > 0) || this.$t("validation.positive");
    },
  },
};
</script>
