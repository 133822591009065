<template>
  <q-card
    v-if="loading==false"
    bordered
    class="q-pa-md shadow-1"
    style="width: 540px; max-width: 95vw"
  >
    <q-card-section class="row text-green-vito no-wrap justify-between">
      <div class="text-h5">
        {{
          type == "filter" ? $t("title.filterOrders") : $t("title.exportOrders")
        }}
      </div>
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>
    
    <q-card-section>
      <div class="text-green-vito text-italic q-mb-xs">
        {{ $t("label.selectStores") }}
      </div>
      <q-btn-toggle
        v-model="allStoresFilter"
        toggle-color="green-vito"
        :options="storesSelectionToggle"
        class="q-mb-sm"
      />
      <q-select
        v-if="!allStoresFilter"
        filled
        v-model="stores"
        multiple
        :options="storeOptions"
        use-chips
        stack-label
        map-options
        emit-value
        :label="$t('label.storeFilter')"
      >
        <template v-slot:prepend>
          <q-icon name="apps" />
        </template>
        <template v-slot:selected>
          <div class="q-gutter-xs q-pt-sm">
            <q-chip
              v-for="(store, index) in stores"
              :key="index"
              removable
              @remove="removeStore(index)"
              color="green-vito"
              text-color="white"
            >
              {{ storeName(store) }}
            </q-chip>
          </div>
        </template>
      </q-select>
    </q-card-section>

    <q-card-section>
      <div class="text-green-vito text-italic q-mb-xs">
        {{ $t("label.selectDates") }}
      </div>
      <q-btn-toggle
        v-model="dateRangeSelection"
        toggle-color="green-vito"
        :options="dateRangeOptions"
        class="q-mb-sm"
        @update:model-value="setDateRange"
      />
      <q-date
        v-model="dateRange"
        range
        :landscape="$q.screen.width > 500"
        color="green-vito"
        ref="dateRangeSelector"
      />
    </q-card-section>

    <q-card-actions class="q-pa-md justify-end">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        v-close-popup
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="type == 'filter' ? $t('button.filter') : $t('button.export')"
        @click="handleFilter"
        :disable="isValidFilter()"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
import StoreApi from "../../../apis/Store";
export default {
  props: ["type"],
  emits: ["filter"],
  data: () => {
    let filters = localStorage.lockerLogFilter ? JSON.parse(localStorage.lockerLogFilter):{dateRange: null, stores: []};
    return {
      loading: true,
      stores: filters.stores,
      startDate: null,
      endDate: null,
      storeOptions: [],
      allStoresFilter: filters.stores.length == 0,
      dateRange: filters.dateRange,
      dateRangeSelection: filters.dateRange == null ? "previousMonth":"selection",
    };
  },
  async mounted() {
    const storeResponse = await StoreApi.findAll();
    storeResponse.data.forEach((store) => {
      this.storeOptions.push({
        label: store.name,
        value: store.id,
      });
    });
    if(this.dateRange == null)
      this.setDateRange(this.dateRangeSelection);
    this.loading = false;
  },

  methods: {
    setDateRange(rangeSelection) {
      let from = null;
      let to = null;
      switch (rangeSelection) {
        case "previousMonth":
          from = this.$moment()
            .subtract(1, "months")
            .startOf("month")
            .format("YYYY/MM/DD");
          to = this.$moment()
            .subtract(1, "months")
            .endOf("month")
            .format("YYYY/MM/DD");
          this.dateRange = { from, to };
          break;
        case "currentMonth":
          from = this.$moment().startOf("month").format("YYYY/MM/DD");
          to = this.$moment().endOf("month").format("YYYY/MM/DD");
          this.dateRange = { from, to };
          break;
        default:
          this.dateRange = this.$moment().format("YYYY/MM/DD");
      }
    },

    handleFilter() {
      this.$emit("filter", {
        stores: this.stores,
        dateRange: this.dateRange,
      });
    },

    removeStore(index) {
      this.stores.splice(index, 1);
    },
    storeName(storeId) {
      const store = this.storeOptions.find((s) => s.value == storeId);
      return store.label;
    },

    isValidFilter() {
      return (
        (!this.allStoresFilter && this.stores.length == 0) ||
        this.dateRange == null
      );
    },
  },
  computed: {
    storesSelectionToggle() {
      return [
        { label: this.$t("label.all"), value: true },
        { label: this.$t("label.selection"), value: false },
      ];
    },
    dateRangeOptions() {
      return [
        { label: this.$t("label.previousMonth"), value: "previousMonth" },
        { label: this.$t("label.currentMonth"), value: "currentMonth" },
        { label: this.$t("label.selection"), value: "selection" },
      ];
    },
  },
};
</script>
