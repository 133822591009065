<template>
  <q-card
    bordered
    class="q-pa-sm q-mb-sm no-padding-md"
    style="width: 480px; max-width: 100vw"
  >
    <q-card-section class="row q-pa-sm text-green-vito justify-between">
      <div class="col-grow">
        <div class="text-h5 text-green-vito" v-if="terminalId">
          {{ $t("title.showLogsTerminal") }} {{ terminal.name }}
        </div>
        <div class="text-h5 text-green-vito" v-else>
          {{ $t("title.addTerminal") }}
        </div>
      </div>
    </q-card-section>

    <q-card-section class="column">
      <q-table
        :rows="rows"
        :columns="columnsI18n"
        row-key="id"
        :loading="loading"
        @request="onRequest"
        binary-state-sort
        class="sticky-header-table size-lg"
      >
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <div class="q-gutter-x-sm">
              <q-btn
                icon="download"
                text-color="green-vito"
                square
                round
                dense
                @click="downloadLogcat(props.row)"
              >
                <q-tooltip class="bg-green-vito text-no-wrap">
                  {{ $t("tooltip.downloadLogcat") }}
                </q-tooltip>
              </q-btn>
            </div>
          </q-td>
        </template>
      </q-table>
    </q-card-section>

    <q-card-section v-if="errorMessage">
      <div class="text-red-vito text-center">
        <strong>{{ errorMessage }}</strong>
      </div>
    </q-card-section>

    <q-card-actions class="q-px-md justify-end">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        @click="handleCancel"
      />
    </q-card-actions>
  </q-card>
</template>
/*

<script>
import TerminalApi from "../../apis/Terminal";
import moment from "moment";

export default {
  emits: ["refresh"],
  props: ["terminalId"],
  data: () => {
    return {
      refreshCounter: 0,
      loading: false,
      rows: [],
      terminal: {},

      errorMessage: null,
    };
  },
  async mounted() {
    if (this.terminalId) {
      await this.getTerminal();
      this.onRequest();
    }
  },
  methods: {
    async getTerminal() {
      try {
        const terminalResponse = await TerminalApi.findOne(this.terminalId);
        this.terminal = terminalResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async onRequest() {
      this.loading = true;

      try {
        const pageReponse = await TerminalApi.findLogcats(this.terminalId);

        this.rows = pageReponse.data;
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    toArrayBuffer: (buffer) => {
      const arrayBuffer = new ArrayBuffer(buffer.length);
      const view = new Uint8Array(arrayBuffer);
      for (let i = 0; i < buffer.length; ++i) {
        view[i] = buffer[i];
      }
      return arrayBuffer;
    },
    async downloadLogcat(row) {
      console.log("download logcat " + row.id);

      var data = await TerminalApi.getLogcat(this.terminalId, row.id);
      console.log(data.data);

      const byteCharacters = atob(data.data.log);
      const byteArrays = [];

      for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays.push(byteCharacters.charCodeAt(i));
      }

      const byteArray = new Uint8Array(byteArrays);
      
      const blob = new Blob([byteArray], { type: data.data.contentType });
      //const blob = new Blob([data.data.log], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = data.data.filename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    handleCancel() {
      this.$emit("refresh");
    },
  },
  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "left",
          field: "id",
          sortable: true,
        },
        {
          name: "date",
          label: this.$t("label.date"),
          field: "date",
          align: "left",
          sortable: true,
          format: (val) => moment(val).format("DD/MM/Y HH:mm"),
        },
        { name: "actions", style: "width:70px" },
      ];
      return columns;
    },
  },
};
</script>
