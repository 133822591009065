<template>
  <q-card
    bordered
    class="q-pa-sm q-mb-sm no-padding-md"
    style="max-width: 100vw"
    v-if="displayId"
  >
    <q-card-section class="row q-pa-sm text-green-vito justify-between">
      <div class="col-grow">
        <div class="text-h5 text-green-vito">
          {{ $t("title.displayReport") }}
        </div>
      </div>

      <div class="row q-gutter-x-sm no-wrap">
        <q-btn
          icon="print"
          text-color="green-vito"
          round
          dense
          v-print="printObj"
          flat
          @click="handlePrint"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.printDisplayReport") }}
          </q-tooltip>
        </q-btn>
        <q-btn icon="close" flat round dense v-close-popup />
      </div>
    </q-card-section>
    <div id="printMe">
      <q-card-section v-if="report" class="q-gutter-x-md highlight">
        <span>
          {{ $t("label.date") }}:
          {{ $moment(report.date).format("DD/MM/Y") }}
        </span>
        <span v-if="report.store">
          {{ $t("label.store") }}: {{ report.store.name }} ({{
            report.store.id
          }})
        </span>
        <span>
          {{ $t("label.display") }}: {{ report.display.name }} ({{
            report.display.id
          }})
        </span>
        <span>
          {{ $t("tooltip.batteryLevel") }}:
          {{ getLevel(report.display.batteryLevel) }}% -
          {{ report.display.batteryLevel }} mV ({{
            $moment(report.display.batteryUpdated).format("DD/MM/Y HH:mm")
          }})
        </span>
      </q-card-section>

      <q-card-section v-if="report">
        <q-markup-table dense separator="cell" class="striped">
          <thead>
            <tr class="bg-green-vito-light">
              <th>{{ $t("label.locker") }}</th>
              <th
                v-for="(action, idx) in Object.keys(
                  report.lockers[0].lastStatus
                )"
                :key="idx"
              >
                {{ $t("action." + action) }}
              </th>
              <th>{{ $t("label.faultCount") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="locker in report.lockers" :key="locker.id">
              <td>{{ locker.name }}</td>
              <td
                v-for="(action, idx) in Object.keys(locker.lastStatus)"
                :key="idx"
              >
                <div v-if="locker.lastStatus[action]" class="q-gutter-x-xs">
                  <q-badge>
                    {{
                      $moment(locker.lastStatus[action].lastLog.date).format(
                        "DD/MM/YY"
                      )
                    }}
                    <q-tooltip>{{
                      $moment(locker.lastStatus[action].lastLog.date).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )
                    }}</q-tooltip>
                  </q-badge>
                  <q-badge
                    :color="
                      sourceColor(locker.lastStatus[action].lastLog.source)
                    "
                    >{{
                      $t(
                        "type.locker." +
                          locker.lastStatus[action].lastLog.source
                      )
                    }}
                    <q-tooltip>
                      {{ $t("label.source") }}
                    </q-tooltip>
                  </q-badge>
                  <q-badge
                    v-if="locker.lastStatus[action].mismatchCount"
                    color="red-vito"
                  >
                    {{ locker.lastStatus[action].mismatchCount }}
                    <q-tooltip>
                      {{ $t("label.mismatchFault") }}
                    </q-tooltip>
                  </q-badge>
                </div>
              </td>
              <td class="text-center">
                <q-badge v-if="locker.faultCount" color="red-vito">
                  {{ locker.faultCount }}
                  <q-tooltip>
                    {{ $t("label.faultCount") }}
                  </q-tooltip>
                </q-badge>
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
    </div>

    <div v-if="loading" class="text-center">
      <q-spinner-hourglass color="green-vito" size="4em" />
    </div>
  </q-card>
</template>

<script>
import DisplayApi from "../../apis/Display";
import { LockerLogActionSource } from "../locker/locker.enum";

import print from "vue3-print-nb";

export default {
  directives: {
    print,
  },
  props: ["displayId"],
  data: () => {
    return {
      report: null,
      printObj: {
        id: "printMe",
      },
      loading: true,
    };
  },
  async mounted() {
    if (this.displayId) {
      await this.getDisplayReport();
      this.printObj.popTitle = this.$t("title.displayReport");
      this.loading = false;
    }
  },
  methods: {
    getLevel(battery) {
      const min = 10000;
      const max = 12000;
      if (battery <= min) return 0;
      if (battery >= max) return 100;
      return Math.ceil(((battery - min) * 100) / (max - min));
    },

    sourceColor(source) {
      return LockerLogActionSource[source]
        ? LockerLogActionSource[source].color
        : "primary";
    },

    async getDisplayReport() {
      try {
        const displayResponse = await DisplayApi.findReport(this.displayId);
        this.report = displayResponse.data;
        //console.log(this.form);
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async handlePrint() {
      console.log("printing", this.displayId);
    },
  },
  computed: {},
};
</script>
