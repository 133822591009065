<template>
  <q-card
    bordered
    class="q-mt-md q-pa-md shadow-1 no-padding-md"
    style="width: 750px; max-width: 90vw"
  >
    <q-card-section class="row text-green-vito no-wrap justify-between">
      <div class="text-h6">
        {{ $t("title.addTags") }}
      </div>
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>

    <q-card-section xclass="q-gutter-md">
      <div class="text-center">
        <q-btn-toggle
          no-caps
          v-model="selectedProductId"
          toggle-color="green-vito"
          :options="products"
          rounded
          unelevated
          size="md"
          class="bg-green-vito-light q-mb-md"
          ><q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.selectTagProduct") }}
          </q-tooltip>
          <template  v-for="product in products" :key="product.id" v-slot:[product.slot]>
            <div class="col items-center q-ma-sm">
              <q-img
                class="col"
                :src="product.image"
                absolute-right
                style="min-width: 50px; max-width: 50px"
              />
              <div class="full-width">{{ product.name }}</div>
            </div>
          </template>
        </q-btn-toggle>
      </div>

      <div v-if="!store">
        <q-select
          v-model="selectedStoreId"
          :options="stores"
          option-value="id"
          option-label="name"
          :label="$t('label.store')"
          filled
          borderless
          square
          emit-value
          hint=""
          map-options
          ref="store"
        >
          <template v-slot:prepend>
            <q-icon name="apps" />
          </template>
        </q-select>
      </div>
      <div v-else class="row">
        <span class="q-ma-sm">{{ $t("label.store") }}</span>
        <q-chip square class="bg-green-vito-light q-mb-md" icon="apps">
          {{ store.name }}
        </q-chip>
      </div>

      <q-input
        stack-label
        square
        filled
        type="textarea"
        :label="$t('label.tags')"
        v-model="tags"
      >
        <template v-slot:prepend> <q-icon name="comment" /> </template
      ></q-input>
    </q-card-section>
    <q-card-actions class="q-pa-md justify-end">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        v-close-popup
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="$t('button.request')"
        :disable="!selectedProductId"
        @click="handleSave"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
import StoreApi from "../../apis/Store";
import ProductApi from "../../apis/Product";
import TagApi from "../../apis/Tag";

export default {
  emits: ["refresh"],
  props: ["store", "product"],

  data: () => {
    return {
      stores: [],
      errorMessage: "",
      selectedStoreId: null,
      selectedProductId: null,
      tags: "",
      products: []
    };
  },

  async mounted() {
    await this.getAllStores();
    await this.getAllProducts();
    //console.log(this.stores);
  },
  methods: {
    async getAllStores() {
      try {
        const storesResponse = await StoreApi.findAll();
        this.stores = storesResponse.data;
        this.selectedStoreId = this.stores[0].id;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async getAllProducts() {
      try {
        const productsResponse = await ProductApi.findAll();
        for(var p in productsResponse.data) {
          var product = productsResponse.data[p];
          let productImage = process.env.VUE_APP_BASE_URL + "/product/images/" + product.image;
          this.products.push({value: product.id, slot: product.name, image: productImage, name: `${product.name} ${product.weight}kg`})
        }
        console.log(this.products)
      }catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async handleSave() {
      //console.log("saving new", this.type);
      this.errorMessage = null;

      try {
        const tagResponse = await TagApi.create(
          this.store ? this.store.id : this.selectedStoreId,
          this.selectedProductId,
          this.tags.split("\n")
        );
        this.$q.notify({
          type: "positive",
          message: this.$t(tagResponse.data.message),
        });
        this.$emit("refresh");
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    required(val) {
      return (val && val.length > 0) || this.$t("validation.required");
    },
    short(val) {
      return (
        (val && val.length > 2) || this.$t("validation.minchar", { chars: 3 })
      );
    },
  },

  computed: {},
};
</script>
