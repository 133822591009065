<template>
  <div style="max-width: 100vw">
    <q-tabs
      v-model="tab"
      class="text-green-vito-light bg-green-vito-light"
      active-class="text-green-vito text-strong"
      indicator-color="green-vito"
      align="justify"
    >
      <q-route-tab
        name="general"
        to="/admin/general"
        :label="$t('button.general')"
        icon="handyman"
      />
      <q-route-tab
        name="templates"
        to="/admin/templates"
        :label="$t('button.templates')"
        icon="extension"
      />
      <q-route-tab
        name="displays"
        to="/admin/displays"
        :label="$t('button.displays')"
        icon="apps"
      />
      <q-route-tab
        name="terminals"
        to="/admin/terminals"
        :label="$t('button.terminals')"
        icon="tablet_android"
      />
    </q-tabs>

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="general">
        <general />
      </q-tab-panel>

      <q-tab-panel name="templates" class="q-pa-md">
        <templates />
      </q-tab-panel>

      <q-tab-panel name="displays" class="q-pa-none">
        <displays />
      </q-tab-panel>

      <q-tab-panel name="terminals" class="q-pa-none">
        <terminals />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import General from "../components/admin/General.vue";
import Templates from "../components/admin/Templates.vue";
import Displays from "../components/admin/Displays.vue";
import Terminals from "../components/admin/Terminals.vue";

export default {
  components: { General, Templates, Displays, Terminals },
  data() {
    return {
      tab: null,
    };
  },
  mounted() {
    this.$store.state.title = "button.settings";
  },
  computed: {},
};
</script>
