export default {
  hello: "hello world",
  dashboard: "dashboard",
  order: "order",
  store: "store",
  product: "product",
  display: "display",
  month: "month",
  active: "Active",
  inactive: "Inactive",
  emptyList: "This list is empty",
  true: "Yes",
  false: "No",
  notFound: "Not found",
  status: {
    ok: "OK",
    new: "New",
    cancel: "Cancel",
    success: "Success",
    failure: "Failure",
    complete: "Completed",
    0: "Success",
    1: "Failure",
    2: "Retry",
    error: "Error",
    message_sent: "Message sent",
    locker_changed: "Locker changed",
    locker_fault_reported: "Locker fault reported",
    locker_fault_cleared: "Locker fault cleared",
    canceled: "Canceled",
    contract_sent: "Sent",
    contract_new: "Pending",
    contract_cancel: "Canceled",
    aborted: "Aborted",
    locker_opened: "Locker opened",
  },
  title: {
    editProfile: "Edit my profile",
    lockerActionNone: "No action available for locker {doorName}",
    lockerActionMoveIn: "Please select article for locker {doorName}",
    lockerActionMoveOut: "Please confirm article removal for locker {doorName}",
    confirmDelete: "Please confirm deletion",
    editProduct: "Edit product",
    addProduct: "Add a new product",
    listProducts: "All products",
    editStore: "Edit store",
    addStore: "Add a new store",
    listStores: "All stores",
    editDisplay: "Edit display",
    addOrder: "New Order",
    completeOrder: "Complete Order",
    contractDetails: "Details for deposit card",
    orders: "Orders",
    devInProgress: "This page is under development",
    users: "Users",
    editUser: "Edit user",
    addUser: "Add new user",
    logDetails: "Log details",
    addCode: "Select code type",
    orderMessage: "Message ",
    logs: "Logs",
    visits: "Visits",
    visit: "Visit",
    completeVisit: "Complete visit",
    addVisit: "New visit request",
    comment: "Comment",
    lockerActionResetFaulty: "Please confirm reset locker faulty status",
    lockerActionResetReserved: "Please confirm reset article reserved",
    displayLocker: "Locker",
    confirmMoveOut: "Please confirm the removal of the article from the locker",
    confirmMoveIn:
      "Please confirm the creation of the article and its insertion into the locker",
    contracts: "Deposit cards",
    confirmContractSent: "Please confirm deposit card has been sent",
    contractMessage: "Message",
    allKPIs: "General KPIs",
    detailledKPIs: "Detailled KPIs",
    alerts: "Alerts",
    lockerActionSetFaulty: "Please confirm fault setup and locker status",
    filterOrders: "Filter orders",
    exportOrders: "Export orders",
    displays: "Displays",
    addDisplay: "New display",
    confirmRemove: "Please confirm removal",
    resendMessage: "Resend message",
    terminals: "Terminals",
    addTerminal: "New terminal",
    editTerminal: "Edit terminal",
    showLogsTerminal: "Show terminal logs",
    statsPerNetwork: "Distribution by network",
    statsPerProduct: "Distribution by product",
    orderLogs: "Orders logs",
    lockerLogs: "Lockers logs",
    messageLogs: "Messages logs",
    message: "Message",
    networks: "Networks",
    batteryHistory: "Battery level history",
    displayReport: "Display status report",
    lockerActionResetFaultCount: "Please confirm reset locker fault count",
    terminalLogs: "Terminals logs",
  },
  button: {
    returnable: "Returnable",
    add: "Add",
    dashboard: "Dashboard",
    orders: "Orders",
    stores: "Stores",
    products: "Products",
    displays: "Displays",
    cancel: "cancel",
    save: "save",
    signIn: "sign in",
    logOut: "Log out",
    myProfile: "My profile",
    deleteDefinitively: "Delete definitely",
    manageProducts: "Manage products",
    manageTemplates: "Manage templates",
    prices: "Prices",
    managePricing: "Manage pricing",
    removeStoreProduct: "Remove from store",
    order: "Order",
    config: "Configuration",
    logs: "Logs",
    bankReturn: "Bank return",
    management: "Management",
    system: "System",
    users: "Users",
    interventions: "Interventions",
    log: "Log",
    documentation: "Documentation",
    complete: "Complete",
    settings: "Settings",
    loadingPlan: "Loading plan",
    codes: "Codes",
    visits: "Visits",
    request: "request",
    comments: "Comments",
    reset: "Reset",
    general: "General",
    templates: "Templates",
    contracts: "Deposit cards",
    contract: "Deposit card",
    supplierVisits: "Supplier visits",
    technicianVisits: "Technician visits",
    ok: "OK",
    lockers: "Lockers",
    confirm: "Confirm",
    messages: "Messages",
    filter: "Filter",
    export: "Export",
    manageDisplays: "Manage displays",
    remove: "Remove",
    send: "Send",
    terminals: "Terminals",
  },
  message: {
    confirmDeleteProduct:
      "Do you really want to definitively delete this product ?",
    confirmDeleteStoreProduct:
      "Do you really want to definitively remove this product from the store ?",
    confirmDeleteStore:
      "Do you really want to definitively delete this store ?",
    confirmDeleteDisplay:
      "Do you really want to definitively delete this display ?",
    notFound404: "This page does not exist, or is under development!",
    confirmDeleteUser: "Do you really want to definitively delete this user ?",
    supplierCode: "A Supplier code",
    technicianCode: "A field engineer code",
    urgencyCode: "An emergency code",
    open_locker: "A code to open a locker",
    copied: "Content has been copied to clipboard",
    confirmRemoveDisplay:
      "Do you really want to remove the display from the store ?",
    confirmDeleteTerminal:
      "Do you really want to to definitively delete this terminal  ?",
    confirmDeleteDoc:
      "Do you really want to to definitively delete this document  ?",
  },
  hint: {
    number: "Number",
    required: "Required",
    minchar: "At least {chars} charaters",
    emailFormat: "Email format",
    password: "Password",
    repeatPassword: "Same as password",
    requireLogin:
      "You will be asked to reconnect with the new information in order to validate the changes.",
    numberLength: "Number with {chars} digits",
    newCodeCommentRequired: "Comment is required",
  },
  label: {
    location: "Location",
    displayName: "Display name",
    language: "Language",
    name: "Name",
    password: "Password",
    email: "Email",
    repeatPassword: "Repeat password",
    weight: "Weight",
    position: "Position",
    image: "Image",
    dragImage: "Drag an image here...",
    selectProducts: "Please select products",
    selectAction: "Please select an action",
    selectTemplate: "Please select a template",
    charge: "Charge",
    promo: "Promo",
    returnable: "Returnable",
    selectPrices: "Please select pricing",
    store: "Store",
    orderProduct: "Order product",
    returnProduct: "Returned product",
    sameReturnable: "Same returnable",
    phone: "Phone",
    firstname: "Firstname",
    lastname: "Lastname",
    address: "Address",
    zip: "Zip",
    city: "City",
    orderAmount: "Order amount",
    search: "Search",
    date: "Date",
    status: "Status",
    source: "Source",
    amount: "Amount",
    actions: "Actions",
    transactionReference: "Banking reference",
    authorisationNumber: "Authorisation number",
    transactionSource: "Payment type",
    transactionLog: "Log",
    delivered: "Delivered",
    returned: "Returned",
    product: "Product",
    type: "Type",
    available: "Available",
    deliveryDone: "Delivery done",
    returnDone: "Returned done ",
    lockers: "Lockers",
    securityCode: "Security code",
    contact: "Contact",
    return: "Return",
    active: "Active",
    roles: "Roles",
    description: "Description",
    template: "Template",
    side: "Side",
    faces: "Sides",
    sides: "Sides",
    rows: "Rows",
    columns: "Columns",
    role: { admin: "Administrator", super: "Super Admin", user: "User" },
    selectProductAndAssign:
      "Please select a product below then click on lockers to assign",
    supplier: "Supplier",
    technician: "Technician",
    urgency: "Urgency",
    open_locker: "Open locker",
    code: "Code",
    validFrom: "Valid From",
    validUntil: "Valid until",
    lastUsedAt: "Last used at",
    usageCount: "Usage count",
    terminal: "Terminal",
    action: "Action",
    content: "Content",
    to: "To",
    log: "Log",
    order: "Order",
    levelAlert: "Alert level",
    suppliersEmail: "Suppliers emails",
    ticket: "Ticket",
    comments: "Comments",
    comment: "Comment",
    from: "From",
    currency: "Currency",
    techniciansEmail: "Technicians emails",
    origin: "Origin",
    lastUpdate: "Last update",
    faulty: "Faulty",
    presence: "Presence",
    locked: "Locked",
    reserved: "Reserved",
    selectProductAndMoveIn:
      "You may select a product below then click on button a the right to create an article and move it into the locker.",
    dateSent: "Date sent",
    created: "Created",
    contractManagersEmail: "Deposit cards managers emails",
    deployedStores: "Deployed stores",
    revenuesSinceJan: "Revenues since January, 1st",
    averageRevenue: "Average revenues",
    volumeSinceJan: "Volume since January, 1st",
    averageVolumeSinceJan: "Average volume since January, 1st",
    salesCB: "Credit card purchases",
    salesSC: "Contactless purchases",
    salesCoupon: "Store purchases",
    openVisits: "Open visits",
    lowBatteryDisplay: "Low battery",
    doorFault: "Door fault",
    lockFault: "Lock fault",
    presenceFault: "Presence sensor fault",
    powerFault: "Power fault",
    timeoutFault: "Timeout fault",
    otherFault: "Other",
    all: "All",
    selection: "Selection",
    storeFilter: "Stores selection",
    selectStores: "Select stores",
    selectDates: "Select date range",
    previousMonth: "Previous month",
    currentMonth: "Current month",
    selectStatus: "Select status",
    battery: "Battery",
    lastPolling: "Last polling",
    lastSupply: "Last supply",
    version: "Version",
    targetVersion: "Target version",
    traceability: "Traceablility",
    selectDisplayToAdd: "Please click on a display to add to the store",
    addComment: "Add a comment",
    serialNumber: "Serial number",
    closed: "Door closed",
    mismatchFault: "Mismatch fault",
    salesReportEmail: "Daily sales report emails",
    display: "Display",
    locker: "Locker",
    network: "Network",
    pendingContracts: "Deposit cards to send",
    pending: "Pending actions",
    title: "Title",
    colors: "Colors",
    batteryUpdated: "Battery updated",
    outOfDateDisplays: "No status update for {minutes} min",
    batteryLevel: "Battery level (mV)",
    faultCount: "Fault count",
    storeTimeout: "Store timeout alert delay (minutes)",
    supplierCodeDuration: "Duration of a supplier code (days)",
    disabled: "Disabled",
    disableLocker: "Disable locker",
    command: "Command",
  },
  type: {
    order: {
      label: "Order type",
      same: "Same model",
      other: "Other model",
      none: "No returnable",
      same_bottle: "Exchange",
      other_bottle: "Other returnable",
      new_bottle: "New returnable",
    },
    code: {
      order: "Order",
      supplier: "Supplier",
      technician: "Technician",
      urgency: "Urgency",
      open_lock: "Open Locker",
    },
    visit: {
      label: "Visit type",
      supplier: "Supplier",
      technical: "Technician",
      technician: "Technician",
    },
    locker: {
      technician_visit: "Tecnician visit",
      supplier_visit: "Supplier visit",
      urgency_code: "Urgency code",
      order_delivery: "Order delivery",
      order_return: "Order return",
      order_cancel: "Order cancelation",
      bo_user: "Back office",
      system: "System",
    },
    transaction: {
      complete: "Complete",
      cancel: "Cancel",
      failure: "Failure",
    },
    message: {
      ORDER_EMAIL: "Order email",
      ORDER_SMS: "Order SMS",
      SUPPLIER_EMAIL: "Supplier",
      TECHNICIAN_EMAIL: "Technician",
      CONTRACT_EMAIL: "Deposit card",
      DAILY_REPORT_EMAIL: "Daily report",
    },
    terminal: {
      actionSource: { 0: "Back Office", 1: "Terminal" },
    },
  },
  validation: {
    verify: "Please correct the form",
    required: "This field must not be empty",
    minchar: "At least {chars} charcaters are required",
    repeatPassword: "Passwords must be the same",
    isEmail: "Invalid email address",
    number: "This field must be a number",
    positive: "This field must be a number greater than 0",
    numberlength: "This field must be a number with {chars} digits",
  },
  tooltip: {
    deleteProduct: "Definitively delete this product",
    editProduct: "Edit product",
    listProduct: "Products list",
    addProduct: "Add a new product",
    deleteStore: "Definitively delete this store",
    editStore: "Edit store",
    listStore: "Stores list",
    addStore: "Add a new store",
    showStore: "Show store",
    showPrices: "Show prices",
    editPrices: "Edit prices",
    showDisplay: "Show display",
    editDisplay: "Edit Display",
    deleteDisplay: "Definitively delete this display",
    addOrder: "Simutates a new order",
    completeOrder: "Simutates order completion",
    showOrder: "Show order",
    listOrder: "Orders list",
    editUser: "Edit user",
    addUser: "Add user",
    deleteUser: "Definitively delete user",
    addCode: "Create a new code",
    displayCodes: "Display codes",
    hideCodes: "Hide codes",
    showCode: "Show code details",
    changeDateRange: "Change validity dates",
    addTemplate: "Add new template",
    showLog: "Show log details",
    addVisit: "Create a visit request",
    showVisit: "Display visit",
    listVisit: "Display all visits",
    completeVisit: "Complete visit",
    selectVisitType: "Select visit type for this request (required)",
    batteryLevel: "Battery level",
    lastSupply: "Last supply",
    displayTechnicianVisits: "Display technician visits",
    hideTechnicianVisits: "Hide technician visits",
    displaySupplierVisits: "Display supplier visits",
    hideSupplierVisits: "Hide supplier visits",
    resetFaulty: "Reset fault status",
    moveIn: "Create article and move it into the locker",
    moveOut: "Move the article out of the locker",
    confirmContractSent: "Confirm deposit card has been sent",
    copyToClipboard: "Copy to clipboard",
    setFaulty: "Set locker fault up",
    exportOrder: "Filter and export to CSV file",
    filterOrder: "Filter orders",
    clearFilterOrder: "Clear filter",
    addDisplay: "Create a new display",
    showStatusReport: "Show status report",
    removeDisplay: "Remove display from store",
    resendMessage: "Resend the message with comments",
    showMessages: "Show messages",
    hideMessages: "Hide messages",
    addTerminal: "Create a new terminal",
    editTerminal: "Edit terminal",
    showLogsTerminal: "Show terminal logs",
    deleteTerminal: "Delete terminal",
    refresh: "Refresh",
    printDisplayReport: "Print report",
    resetFaultCount: "Number of faults. Click to reset...",
    showDeliveryReport: "Show delivery note",
    downloadLogcat: "Download logcat",
  },
  action: {
    new: "Create",
    send_message: "Send message",
    complete: "Close",
    retry_locker: "Change locker",
    create: "Create",
    report_locker_fault: "Report locker fault",
    cancel: "Cancel",
    set_faulty: "Set fault status",
    reset_faulty: "Clear fault status",
    lock_open: "Locker unlocked",
    lock_close: "Locker locked",
    door_open: "Door opened",
    door_close: "Door closed",
    presence_detect: "Presence detected",
    presence_undetect: "Presence lost",
    article_move_in: "Article move in",
    article_move_out: "Article move out",
    clear_locker_fault: "Clear locker fault",
    validate_transaction: "Validate transaction",
    reset_fault_count: "Reset fault count",
    disable: "Deactivation",
    update_terminal: "Update",
    execute_command: "Command execution",
    create_terminal: "creation",
    delete_terminal: "Deletion",
  },
  api: {
    user: {
      profileUpdated: "Profile updated",
      updated: "User updated",
      created: "User created",
      removed: "User removed",
    },
    display: {
      updated: "Display has been updated",
      created: "Display has been created",
      removed: "Display has been deleted",
      itemAdded: "Product has been added",
      itemRemoved: "Article has been removed",
      lockerResetFaulty: "Locker fault status removed",
      articleResetReserved: "Reserved article status removed",
      displayOrLockerNotFound: "Display or locker not found",
      lockerSetFaulty: "Locker has been set faulty",
      added: "Display has been added to store",
      lockerNotFound: "Locker #{lockerId} not found",
      deleted: "Display has been deleted",
      lockerResetFaultCount: "Locker fault count has been reset",
    },
    price: {
      updated: "Prices have been updated",
      created: "Price has been created",
    },
    product: {
      updated: "Product has been updated",
      created: "Product has been created",
      removed: "Product has been removed",
      alertSaved: "Product alert level saved",
    },
    store: {
      created: "Store has been created",
      updated: "Store has been updated",
      removed: "Store has been removed",
      productAdded: "Product has been added to store",
      productRemoved: "Product has been removed from store",
      notFound: "Store not found",
      networkCreated: "Network has been created",
      networkUpdated: "Network has been updated",
      networkRemoved: "Network has been removed",
    },
    order: {
      updated: "Order has been updated",
      created: "Order has been created",
      removed: "Order has been removed",
      complete: "Order is now complete",
      alreadyClosed: "Order is already closed",
      notFound: "Order #{orderId} not found",
      retryEmptyLockerNotFound: "No empty retry locker",
      retryLockerNotFound: "No delivery retry locker",
    },
    visit: {
      updated: "Visit has been updated",
      created: "Visit has been created",
      removed: "Visit has been removed",
      complete: "Visit is now complete",
      messageSent: "Message has been sent",
    },
    template: {
      updated: "Template has been updated",
      created: "Template has been created",
      removed: "Template has been removed",
    },
    code: {
      updated: "Code has been updated",
      created: "Code has been created",
      removed: "Code has been removed",
    },
    param: {
      updated: "Parameters has been updated",
      created: "Parameters has been created",
    },
    login: { failure: "Invalid email or password" },
    contract: { sent: "Confirmation recorded" },
    terminal: {
      updated: "Terminal has been updated",
      created: "Terminal has been created",
      removed: "Terminal has been removed",
    },
    message: {
      recipientNotFound: "Recipient not found",
    },
    doc: {
      updated: "Documentation has been updated",
      created: "Documentation has been created",
      removed: "Documentation has been removed",
      notFound: "Documentation not found",
    },
  },
};
