<template>
  <q-toolbar class="text-green-vito bg-grey-1 q-mb-sm q-pl-none shadow-1">
    <q-btn-dropdown
      stretch
      flat
      :label="$t('button.orders')"
      dropdown-icon="more_vert"
      menu-anchor="bottom left"
      menu-self="top left"
      icon-left
    >
    </q-btn-dropdown>
  </q-toolbar>

  <q-table
    xclass="my-sticky-dynamic"
    class="sticky-header-table size-md"
    table-style="max-height: 300px"
    dense
    flat
    bordered
    :rows="rows"
    separator="cell"
    :columns="columnsI18n"
    :loading="loading"
    row-key="index"
    virtual-scroll
    :virtual-scroll-item-size="28"
    :virtual-scroll-sticky-size-start="28"
    :pagination="pagination"
    :rows-per-page-options="[0]"
    @virtual-scroll="onScroll"
    ref="scroll-table"
  >
    <template v-slot:body-cell-date="props">
      <q-td :props="props">
        <div>
          {{ $moment(props.value).format("DD/MM/YYYY HH:mm") }}
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-product="props">
      <q-td :props="props">
        <div
          v-bind="props"
          v-if="props.value"
          class="flex no-wrap q-gutter-x-sm justify-center"
        >
          <div style="width: 26px">
            <q-img :src="productImage(props.value)" />
          </div>
          <div class="flex flex-center">
            {{ props.value.name }}
            {{ props.value.weight }} kg
          </div>
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-type="props">
      <q-td :props="props">
        <q-badge>{{ props.value }}</q-badge>
      </q-td>
    </template>

    <template v-slot:body-cell-status="props">
      <q-td :props="props">
        <q-badge :color="statusColor(props.value)">{{ props.value }}</q-badge>
      </q-td>
    </template>

    <template v-slot:body-cell-delivered="props">
      <q-td :props="props">
        <q-icon
          size="sm"
          :color="props.value ? 'green-vito' : 'red-vito'"
          :name="props.value ? 'check_box' : 'disabled_by_default'"
        />
      </q-td>
    </template>

    <template v-slot:body-cell-returned="props">
      <q-td :props="props">
        <q-icon
          v-if="props.row.type != 'new_bottle'"
          size="sm"
          :color="props.value ? 'green-vito' : 'red-vito'"
          :name="props.value ? 'check_box' : 'disabled_by_default'"
        />
      </q-td>
    </template>

    <template v-slot:body-cell-actions="props">
      <q-td :props="props">
        <div>
          <q-btn
            icon="visibility"
            square
            round
            dense
            @click="displayOrder(props.row.id)"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.showOrder") }}
            </q-tooltip>
          </q-btn>
        </div>
      </q-td>
    </template>
  </q-table>
</template>

<script>
import OrderApi from "../../apis/Order";
import { OrderStatus } from "../order/order.enum";
export default {
  props: ["locker"],
  data: () => {
    return {
      orders: [],

      filter: null,

      loading: false,
      pagination: {
        rowsPerPage: 0,
      },
      rows: [],

      sortBy: "id",
      descending: true,
      pageSize: 20,
      nextPage: 1,
      lastPage: 1000,
    };
  },

  async mounted() {
    //
  },

  methods: {
    async onScroll({ to, index }) {
      //console.log(to, index);
      //const lastIndex = this.rows.length - 1;
      const startRow = (this.nextPage - 1) * this.pageSize;

      try {
        if (
          this.loading !== true &&
          this.nextPage <= this.lastPage &&
          (to === index || to === -1)
        ) {
          this.loading = true;
          const pageReponse = await OrderApi.paginateLocker(this.locker.id, {
            startRow,
            fetchCount: this.pageSize,
            filter: null,
            sortBy: this.sortBy,
            descending: this.descending,
          });
          this.lastPage = Math.ceil(pageReponse.data.count / this.pageSize);

          pageReponse.data.items.forEach((row, index) => {
            row.index = this.rows.length + index;
          });
          this.rows = this.rows.concat(pageReponse.data.items);

          this.nextPage++;
          this.loading = false;
        }
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    displayOrder(orderId) {
      this.$router.push("/order/" + orderId);
    },
    productImage(product) {
      if (product && product.image)
        return (
          process.env.VUE_APP_BASE_URL + "/product/images/" + product.image
        );
    },
    statusColor(status) {
      return OrderStatus[status] ? OrderStatus[status].color : "primary";
    },
  },
  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "center",
          field: "id",
          sortable: false,
        },
        {
          name: "status",
          label: this.$t("label.status"),
          field: "status",
          align: "center",
          sortable: false,
        },
        {
          name: "date",
          align: "center",
          label: this.$t("label.date"),
          field: "date",
          sortable: false,
        },
        {
          name: "product",
          label: this.$t("label.product"),
          field: "deliveryProduct",
          align: "center",
          sortable: false,
        },
        {
          name: "delivered",
          label: this.$t("label.delivered"),
          field: "delivered",
          align: "center",
          sortable: false,
        },
        {
          name: "returned",
          label: this.$t("label.returned"),
          field: "returned",
          align: "center",
          sortable: false,
        },
        { name: "actions", style: "width:70px" },
      ];
      return columns;
    },
  },
};
</script>
