export const LockerStatus = {
  isClosed_true: { color: "green-vito", icon: "door_front" },
  isClosed_false: { color: "red-vito", icon: "meeting_room" },
  isLocked_true: { color: "green-vito", icon: "lock" },
  isLocked_false: { color: "red-vito", icon: "lock_open" },
  isEmpty_true: { color: "red-vito", icon: "sensors_off" },
  isEmpty_false: { color: "green-vito", icon: "sensors" },
  isFaulty_true: { color: "red-vito", icon: "error" },
  isFaulty_false: { color: "green-vito", icon: "check" },
  faultCount_true: { color: "orange", icon: "warning" },
  faultCount_false: { color: "green-vito", icon: "check" },
  isActive_false: { color: "grey-7", icon: "highlight_off" },
  isActive_true: { color: "green-vito", icon: "check" },
  lockerStatus_ok: { color: "green-vito", icon: "check" },
  lockerStatus_ko: { color: "red-vito", icon: "warning" },
};

export const LockerAction = {
  OPEN: "open",
};

export const LockerLogStatus = {
  0: "OK",
  1: "DOOR_FAULT",
  2: "LOCK_FAULT",
  3: "DOOR_LOCK_FAULT",
  4: "PRESENCE_FAULT",
  5: "PRESENCE_DOOR_FAULT",
  6: "PRESENCE_LOCK_FAULT",
  7: "PRESENCE_DOOR_LOCK_FAULT",
  8: "MISMATCH",
  9: "UNKNOWN",
  10: "COMM_ERROR",
};
export const LockerLogAction = {
  lock_open: { color: "green-vito" },
  lock_close: { color: "green-vito" },
  door_open: { color: "green-vito" },
  door_close: { color: "green-vito" },
  presence_detect: { color: "green-vito" },
  presence_undetect: { color: "green-vito" },
  article_move_in: { color: "green-vito" },
  article_move_out: { color: "green-vito" },
  set_faulty: { color: "red-vito" },
  reset_faulty: { color: "green-vito" },
  reset_fault_count: { color: "green-vito" },
  disable: { color: "grey-7" },
  enable: { color: "green-vito" },
};
export const LockerLogActionSource = {
  technician_visit: { color: "purple-10" },
  supplier_visit: { color: "purple-10" },
  urgency_code: { color: "red-vito" },
  order_delivery: { color: "green-vito" },
  order_return: { color: "green-vito" },
  order_cancel: { color: "orange-5" },
  bo_user: { color: "green-vito" },
  system: { color: "green-vito" },
};
