<template>
  <div class="text-center">
    <q-img :src="productImage" style="max-width: 90px; min-height: 140px" />
  </div>
</template>
<script>
export default {
  props: ["selectedItem"],
  computed: {
    productImage() {
      if (!this.selectedItem) return null;
      return (
        process.env.VUE_APP_BASE_URL +
        "/product/images/" +
        this.selectedItem.image
      );
    },
    productCaption() {
      if (!this.selectedItem) return null;
      return `${this.selectedItem.name} ${this.selectedItem.weight} Kg`;
    },
  },
};
</script>
