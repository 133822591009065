<template>
  <q-toolbar class="text-green-vito bg-grey-1 q-mb-sm q-pl-none shadow-1">
    <q-btn-dropdown
      stretch
      flat
      :label="$t('button.tags')"
      dropdown-icon="more_vert"
      menu-anchor="bottom left"
      menu-self="top left"
      icon-left
    >
    </q-btn-dropdown>
    <q-space />
    <q-btn
      ref="addButton"
      dense
      round
      color="primary"
      @click="showNew = true"
      icon="add"
      class="q-mr-md"
    >
      <q-tooltip class="bg-green-vito text-no-wrap">
        {{ $t("tooltip.addTags") }}
      </q-tooltip>
    </q-btn>
    <q-input
      outlined
      dense
      clearable
      debounce="300"
      v-model="filter"
      :placeholder="$t('label.search')"
    >
      <template v-slot:append>
        <q-icon name="search" />
      </template>
    </q-input>
  </q-toolbar>

  <tag-pages
    :store="store"
    :filter="filter"
    :key="refreshCounter"
  />

  <q-dialog v-model="showNew" ref="newTagsDialog">
    <new-tags @refresh="refresh" :store="store" />
  </q-dialog>
</template>

<script>
import TagPages from "../tag/TagPages.vue";
import NewTags from "../tag/NewTags.vue";

export default {
  emits: ["refresh"],
  components: { TagPages, NewTags },

  props: ["store"],

  data: () => {
    return {
      showNew: false,
      refreshCounter: 0,
      filter: null,
    };
  },

  methods: {
    async refresh() {
      this.showNew = false;
      this.$emit("refresh");
    },
  },

  async mounted() {},

  computed: {},
};
</script>
