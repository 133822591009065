<template>
  <q-card bordered class="q-mt-md q-pa-md shadow-1" style="max-width: 90vw">
    <q-card-section class="row text-green-vito no-wrap justify-between">
      <div class="text-h6">
        {{ $t("title.addCode") }}
      </div>
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>
    <q-card-section class="q-gutter-md">
      <q-btn-toggle
        no-caps
        v-model="type"
        toggle-color="green-vito"
        :options="options"
        unelevated
        size="md"
        padding="md"
        class="bg-green-vito-light"
      >
        <template v-slot:supplier>
          <div style="width: 150px">
            <q-icon top name="wifi_protected_setup" />
            <div class="full-width">{{ $t("label.supplier") }}</div>
            <div class="text-caption">{{ $t("message.supplierCode") }}</div>
          </div>
        </template>
        <template v-slot:technician>
          <div style="width: 150px">
            <q-icon top name="engineering" />
            <div class="full-width">{{ $t("label.technician") }}</div>
            <div class="text-caption">{{ $t("message.technicianCode") }}</div>
          </div>
        </template>
        <template v-slot:urgency>
          <div style="width: 150px">
            <q-icon top name="fire_extinguisher" />
            <div class="full-width">{{ $t("label.urgency") }}</div>
            <div class="text-caption">{{ $t("message.urgencyCode") }}</div>
          </div>
        </template>
        <template v-slot:openLocker>
          <div style="width: 150px">
            <q-icon top name="vpn_key" />
            <div class="full-width">{{ $t("label.open_locker") }}</div>
            <div class="text-caption">{{ $t("message.open_locker") }}</div>
          </div>
        </template>
      </q-btn-toggle>
      <q-input
        stack-label
        clearable
        square
        filled
        :label="$t('label.locker')"
        v-model="lockerName"
        autogrow
        :hint="type != 'open_lock' ? '' : $t('hint.newCodeLockerRequired')"
        lazy-rules
        :rules="type && type == 'open_lock' ? [required] : null"
        :disable="type != 'open_lock'"
      >
      </q-input>
      <q-input
        stack-label
        clearable
        square
        filled
        :label="$t('label.comment')"
        v-model="comment"
        autogrow
        :hint="type == 'urgency' ? '' : $t('hint.newCodeCommentRequired')"
        lazy-rules
        :rules="type && type != 'urgency' ? [required] : null"
        :disable="type == 'urgency'"
      >
        <template v-slot:prepend> <q-icon name="comment" /> </template
      ></q-input>
    </q-card-section>

    <q-card-actions class="q-pa-md justify-end">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        v-close-popup
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="$t('button.save')"
        :disable="!type || (type != 'urgency' && !comment)"
        @click="handleSave"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
import CodeApi from "../../apis/Code";
import VisitApi from "../../apis/Visit";
//import { VisitType } from "../visit/visit.enum";
export default {
  props: ["store"],
  emits: ["refresh"],

  data: () => {
    return {
      type: null,
      lockerName: null,
      comment: null,
      options: [
        {
          value: "supplier",
          slot: "supplier",
        },
        {
          value: "technician",
          slot: "technician",
        },
        { value: "urgency", slot: "urgency" },
        { value: "open_lock", slot: "openLocker" },
      ],
    };
  },

  async mounted() {
    //
  },
  methods: {
    required(val) {
      return (
        (val && val.toString().length > 0) || this.$t("validation.required")
      );
    },
    async handleSave() {
      this.errorMessage = null;

      try {
        const codeResponse =
          this.type == "urgency" || this.type == "open_lock"
            ? await CodeApi.create({
                storeId: this.store.id,
                type: this.type,
                lockerName: this.lockerName,
                comment: this.comment,
              })
            : await VisitApi.create({
                storeId: this.store.id,
                type: this.type,
                lockerName: this.lockerName,
                comment: this.comment,
              });

        this.$q.notify({
          type: "positive",
          message: this.$t(codeResponse.data.message),
        });
        this.$emit("refresh");
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
  },
};
</script>
