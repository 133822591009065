<template>
  <q-card flat style="width: 94vw">
    <q-card-section class="row text-green-vito no-wrap justify-between">
      <div class="text-h6">
        {{ $t("title.batteryHistory") }}
      </div>
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>
    <q-card-section
      class="row text-green-vito justify-around wrap no-padding-md"
    >
      <Line
        v-if="loaded"
        :options="chartOptions"
        :data="chartData"
        :dataset-id-key="datasetIdKey"
        :height="height"
        style="width: 100%"
        class="q-pa-sm"
      />

      <q-spinner-orbit color="green-vito" v-if="!loaded" size="xl" />
    </q-card-section>
  </q-card>
</template>

<script>
import { Line } from "vue-chartjs";
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-moment";
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(...registerables);
Chart.register(zoomPlugin);

import DisplayApi from "../../apis/Display";
export default {
  name: "batteryHistory",
  components: { Line },
  props: {
    datasetIdKey: { type: String, default: "label" },
    store: { type: Object },
    display: { type: Object },
    batteryHistory: { type: Array },
  },
  data: () => {
    return {
      chartData: {},
      chartOptions: {
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 1,
          },
        },
        interaction: {
          mode: "nearest",
          intersect: false,
        },
        responsive: true,
        plugins: {
          legend: {
            display: true,
            labels: {
              font: {
                size: 14,
                family: "Roboto",
              },
              color: "#006560",
            },
            position: "bottom",
            align: "end",
          },
          labels: {
            font: {
              size: 18,
              family: "Roboto",
            },
            color: "#006560",
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'x'
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true
              },
              mode: 'x',
            }
          }
        },
        scales: {
          x: {
            type: "time",
            time: {
              unit: "day",
              displayFormats: {
                hour: "HH:mm",
                minute: "DD/MM/YYYY HH:mm",
                day: "DD/MM/YYYY",
              },
            },
          },
          y: {
            min: 6000,
            ticks: {
              font: {
                size: 14,
                family: "Roboto",
              },
              color: "#006560",
            },
          },
        },
      },

      stats: [],
      max: 20,
      loaded: false,
    };
  },
  async mounted() {
    console.log(this.display, this.store);
    await this.getStats();
    //this.stats = this.batteryHistory;
    this.getDatasets();
    //this.chartOptions.plugins.title.text = this.$t("title.batteryHistory");
    window.setTimeout(() => (this.loaded = true), 1000);
  },
  methods: {
    async getStats() {
      try {
        const statsResponse = await DisplayApi.findBatteryHistory(
          this.display.id
        );
        this.stats = statsResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async getDatasets() {
      this.chartData.labels = this.stats.map((s) => s.date);
      this.chartData.datasets = [
        {
          label: this.$t("label.batteryLevel"),
          data: this.stats.map((s) => s.level),
          backgroundColor: "#006560",
          borderColor: "#006560",
          tension: 0.05,
        },
      ];
    },
  },
  computed: {
    height() {
      return this.$q.screen.lt.md ? 500 : 450;
    },
  },
};
</script>
