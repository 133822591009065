<template>
  <q-card
    flat
    class="q-pa-sm full-width no-padding-md"
    style="max-width: 100vw"
  >
    <q-card-section class="row q-pb-xs text-green-vito justify-between">
      <div class="text-h5">
        {{ $t("title.users") }}
      </div>
      <div class="q-gutter-x-md row">
        <div class="flex column justify-center">
          <q-btn
            color="primary"
            @click="showNew = true"
            icon="add"
            rounded
            dense
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.addUser") }}
            </q-tooltip>
          </q-btn>
        </div>
        <q-input
          outlined
          dense
          clearable
          debounce="300"
          v-model="filter"
          style="width: 150px"
          :placeholder="$t('label.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </q-card-section>

    <q-card-section>
      <q-table
        :rows="users"
        :columns="columnsI18n"
        row-key="id"
        :pagination="pagination"
        :loading="loading"
        :filter="filter"
        binary-state-sort
        class="sticky-header-table"
      >
        <template v-slot:body-cell-roles="props">
          <q-td :props="props">
            <q-badge class="q-ma-xs" v-for="role in props.value" :key="role">
              {{ $t("label.role." + role) }}
            </q-badge>
          </q-td>
        </template>

        <template v-slot:body-cell-isActive="props">
          <q-td :props="props">
            <q-badge>
              {{ $t(props.value.toString()) }}
            </q-badge>
          </q-td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <div class="q-gutter-x-sm">
              <q-btn
                icon="delete_forever"
                square
                round
                dense
                class="text-red-vito"
                @click="handleDeleteButton(props.row)"
              >
                <q-tooltip class="bg-green-vito text-no-wrap">
                  {{ $t("tooltip.deleteUser") }}
                </q-tooltip>
              </q-btn>
              <q-btn
                icon="edit"
                square
                round
                dense
                @click="editUser(props.row)"
              >
                <q-tooltip class="bg-green-vito text-no-wrap">
                  {{ $t("tooltip.editUser") }}
                </q-tooltip>
              </q-btn>
            </div>
          </q-td>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
  <q-dialog v-model="showNew" ref="newUserDialog">
    <user-card @refresh="refresh" :user="newUser" action="create" />
  </q-dialog>
  <q-dialog v-model="showCard" ref="showUserDialog">
    <user-card @refresh="refresh" :user="selectedUser" action="edit" />
  </q-dialog>
</template>
<script>
import UserCard from "../components/user/UserCard.vue";
import UserApi from "../apis/User";
import { useI18n } from "vue-i18n";

export default {
  name: "UsersPage",

  components: { UserCard },
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      action: "list",
      showNew: false,
      showCard: false,
      filter: "",
      loading: false,
      users: [],
      perPage: 50,
      pagination: {
        sortBy: "id",
        descending: false,
        page: 1,
        rowsPerPage: 50,
      },
      newUser: {
        id: 0,
        Name: "",
        email: "",
        roles: ["user"],
        isActive: true,
        language: "en-US",
      },
    };
  },
  methods: {
    async getUsers() {
      this.loading = true;
      try {
        const usersReponse = await UserApi.findAll();

        this.users = usersReponse.data;
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async refresh() {
      //console.log("refresh users");
      this.showNew = false;
      this.showCard = false;
      await this.getUsers();
    },
    editUser(user) {
      this.selectedUser = user;
      this.showCard = true;
    },
    addUser() {
      this.selectedUser = { id: 0 };
      this.showNew = true;
    },
    handleDeleteButton(user) {
      this.$q
        .dialog({
          title: this.$t("title.confirmDelete"),
          message:
            this.$t("message.confirmDeleteUser") +
            ` ${user.name} (${user.email})`,
          ok: {
            color: "red-vito",
            label: this.$t("button.deleteDefinitively"),
          },
          cancel: {
            label: this.$t("button.cancel"),
            color: "green-vito",
          },
          persistent: true,
        })
        .onOk(async () => {
          await this.deleteUser(user);
        });
    },
    async deleteUser(user) {
      //console.log(user);
      try {
        const deleteResponse = await UserApi.delete(user.id);
        this.$q.notify({
          type: "positive",
          message: this.$t(deleteResponse.data.message),
        });
        //console.log(saveResponse.data);
        this.refresh();
      } catch (error) {
        let message = error.response
          ? error.response.data.message
          : error.message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
        //console.error(message);
      }
    },
  },

  async mounted() {
    this.$store.state.title = "button.users";
    await this.getUsers();
  },

  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "left",
          field: "id",
          sortable: true,
        },
        {
          name: "name",
          label: this.$t("label.name"),
          field: "name",
          align: "left",
          sortable: true,
        },
        {
          name: "email",
          align: "left",
          label: this.$t("label.email"),
          field: "email",
          sortable: true,
        },
        {
          name: "roles",
          label: this.$t("label.roles"),
          field: "roles",
          align: "left",
          sortable: false,
        },
        {
          name: "language",
          label: this.$t("label.language"),
          field: "language",
          align: "left",
          sortable: false,
        },
        {
          name: "isActive",
          label: this.$t("label.active"),
          field: "isActive",
          align: "left",
          sortable: false,
        },

        { name: "actions", style: "width:70px" },
      ];
      return columns;
    },
  },
};
</script>
