<template>
  <q-toolbar class="text-green-vito bg-grey-1 q-mb-sm q-pl-none shadow-1">
    <q-btn-dropdown
      stretch
      flat
      :label="$t('button.messages')"
      dropdown-icon="more_vert"
      menu-anchor="bottom left"
      menu-self="top left"
      icon-left
    >
    </q-btn-dropdown>
  </q-toolbar>

  <q-table
    class="sticky-header-table size-md"
    table-style="max-height: 300px; max-width:100%"
    dense
    flat
    bordered
    :rows="rows"
    separator="cell"
    :columns="columnsI18n"
    :loading="loading"
    row-key="index"
    virtual-scroll
    :virtual-scroll-item-size="28"
    :virtual-scroll-sticky-size-start="28"
    :pagination="pagination"
    :rows-per-page-options="[0]"
    @virtual-scroll="onScroll"
    ref="scroll-table"
  >
    <template v-slot:body-cell-date="props">
      <q-td :props="props">
        {{ $moment(props.value).format("DD/MM/YYYY HH:mm:ss") }}
      </q-td>
    </template>

    <template v-slot:body-cell-log="props">
      <q-td :props="props" auto-width>
        <div class="ellipsis" style="max-width: 350px">{{ props.value }}</div>
      </q-td>
    </template>
    <template v-slot:body-cell-contactEmail="props">
      <q-td :props="props" auto-width>
        <div class="ellipsis" style="max-width: 350px">{{ props.value }}</div>
      </q-td>
    </template>

    <template v-slot:body-cell-status="props">
      <q-td :props="props">
        <q-badge :color="props.value == 0 ? 'green-vito' : 'red-vito'">
          {{ props.value == 0 ? $t("status.success") : $t("status.failure") }}
        </q-badge>
      </q-td>
    </template>

    <template v-slot:body-cell-actions="props">
      <q-td :props="props">
        <q-btn
          icon="mail"
          round
          dense
          @click="
            selectedMessage = props.row;
            showLogCard = true;
          "
        >
        </q-btn>
      </q-td>
    </template>
  </q-table>

  <q-dialog v-model="showLogCard" ref="ShowMessageDialog">
    <q-card
      bordered
      class="q-mt-md q-pa-md shadow-1"
      style="width: 550px; max-width: 90vw"
    >
      <q-card-section class="row text-green-vito no-wrap justify-between">
        <div class="text-h6">
          {{ $t("title.contractMessage") }} #{{ selectedMessage.id }}
        </div>
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section class="col-12 col-sm-auto">
        <message :message="selectedMessage"></message>
      </q-card-section>
      <q-card-section>
        <q-input v-model="prettyLog" filled type="textarea" autogrow readonly />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import MessageApi from "../../apis/Message";
import Message from "../message/Message.vue";

export default {
  props: ["locker"],
  components: { Message },
  data: () => {
    return {
      selectedMessage: { log: "" },
      showLogCard: false,
      showMessageCard: false,
      filter: null,

      loading: false,
      pagination: {
        rowsPerPage: 0,
      },
      rows: [],

      sortBy: "id",
      descending: true,
      pageSize: 20,
      nextPage: 1,
      lastPage: 1000,
    };
  },

  async mounted() {
    //
  },

  methods: {
    async onScroll({ to, index }) {
      //console.log(to, index);
      //const lastIndex = this.rows.length - 1;
      const startRow = (this.nextPage - 1) * this.pageSize;

      try {
        if (
          this.loading !== true &&
          this.nextPage <= this.lastPage &&
          (to === index || to === -1)
        ) {
          this.loading = true;
          const pageReponse = await MessageApi.paginateContract({
            startRow,
            fetchCount: this.pageSize,
            filter: null,
            sortBy: this.sortBy,
            descending: this.descending,
          });
          this.lastPage = Math.ceil(pageReponse.data.count / this.pageSize);

          pageReponse.data.items.forEach((row, index) => {
            row.index = this.rows.length + index;
          });
          this.rows = this.rows.concat(pageReponse.data.items);

          this.nextPage++;
          this.loading = false;
        }
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
  },
  computed: {
    prettyLog() {
      //console.log(this.selectedLog);

      if (this.selectedMessage && this.selectedMessage.log) {
        try {
          const obj = JSON.parse(this.selectedMessage.log);
          const text = JSON.stringify(obj, null, 4);
          return text;
        } catch (error) {
          return this.selectedMessage.log;
        }
      }
      return "";
    },
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "center",
          field: "id",
          sortable: true,
        },
        {
          name: "date",
          align: "center",
          label: this.$t("label.date"),
          field: "date",
          sortable: true,
        },
        {
          name: "contactEmail",
          align: "center",
          label: this.$t("label.to"),
          field: "contactEmail",
          sortable: true,
        },
        {
          name: "status",
          label: this.$t("label.status"),
          field: "status",
          align: "center",
          sortable: true,
        },
        {
          name: "log",
          label: this.$t("label.log"),
          field: "log",
          align: "center",
          sortable: true,
        },
        { name: "actions", style: "width:70px" },
      ];

      return columns;
    },
  },
};
</script>
