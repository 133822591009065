import Api from "./Api";
export default {
  moveIn(id, payload) {
    return Api().post("/display/movein/" + id, payload);
  },
  moveOut(id, payload) {
    return Api().post("/display/moveout/" + id, payload);
  },
  findByStoreId(storeId) {
    return Api().get("/display/store/" + storeId);
  },
  findAvailable() {
    return Api().get("/display/available");
  },
  findAll() {
    return Api().get("/display");
  },
  findOne(id) {
    return Api().get(`/display/${id}`);
  },
  save(id, form) {
    return Api().patch("/display/" + id, form);
  },
  create(form) {
    return Api().post("/display", form);
  },
  delete(id) {
    return Api().delete(`/display/${id}`);
  },
  remove(id) {
    return Api().post(`/display/${id}/remove`);
  },
  resetFaulty(id, payload) {
    return Api().post(`/display/${id}/reset`, payload);
  },

  resetFaultCount(id, payload) {
    return Api().post(`/display/${id}/count/reset`, payload);
  },
  setFaulty(id, payload) {
    return Api().post(`/display/${id}/fault`, payload);
  },
  resetReserved(id, payload) {
    return Api().post(`/display/${id}/reserved`, payload);
  },

  paginate(paginate) {
    return Api().post("/display/page", paginate);
  },
  addToStore(displayId, storeId) {
    return Api().post(`/display/${displayId}/store/${storeId}`);
  },
  findBatteryHistory(displayId) {
    return Api().get(`/display/${displayId}/battery`);
  },
  findReport(displayId) {
    return Api().get(`/display/${displayId}/report`);
  },
  findDeliveryReport(displayId) {
    return Api().get(`/display/${displayId}/delivery`);
  },
};
