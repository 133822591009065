<template>
  <div class="row full-width" v-if="order">
    <order-card :order="order" @refresh="refresh" :key="refreshCounter" />
  </div>
  <div v-if="!loading && !order">
    <not-found
      :message="$t('api.order.notFound', { orderId: $route.params.id })"
    />
  </div>
</template>
<script>
import OrderCard from "../components/order/OrderCard.vue";
import OrderApi from "../apis/Order";
import NotFound from "../components/LockerNotFound.vue";
export default {
  name: "OrderPage",
  components: { OrderCard, NotFound },

  data: () => {
    return {
      order: null,
      refreshCounter: 0,
      loading: true,
    };
  },

  async mounted() {
    this.$store.state.title = "button.orders";
    //console.log("orderId", this.$route.params.id);
    await this.getOrder(this.$route.params.id);
    this.loading = false;
  },
  methods: {
    async getOrder(orderId) {
      try {
        const orderResponse = await OrderApi.findOne(orderId);
        this.order = orderResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async refresh() {
      await this.getOrder(this.$route.params.id);
      this.refreshCounter += 1;
    },
  },
};
</script>
