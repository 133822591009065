<template>
  <q-toolbar class="text-green-vito bg-grey-1 q-mb-sm q-pl-none shadow-1">
    <q-btn-dropdown
      stretch
      flat
      :label="$t('button.codes')"
      dropdown-icon="more_vert"
      menu-anchor="bottom left"
      menu-self="top left"
      icon-left
    >
    </q-btn-dropdown>
    <q-space />
    <q-btn
      ref="addButton"
      dense
      round
      color="primary"
      @click="showNew = true"
      icon="add"
      class="q-mr-md"
    >
      <q-tooltip class="bg-green-vito text-no-wrap">
        {{ $t("tooltip.addCode") }}
      </q-tooltip>
    </q-btn>
    <q-input
      outlined
      dense
      clearable
      debounce="300"
      v-model="filter"
      :placeholder="$t('label.code')"
    >
      <template v-slot:append>
        <q-icon name="search" />
      </template>
    </q-input>
  </q-toolbar>

  <code-pages :store="store" :filter="filter" :key="refreshCounter" />

  <q-dialog v-model="showNew" ref="newCodeDialog">
    <new-code @refresh="refresh" :store="store" />
  </q-dialog>
</template>

<script>
import CodePages from "./CodePages.vue";
import NewCode from "./NewCode.vue";

export default {
  emits: ["refresh"],
  components: { CodePages, NewCode },

  props: ["store"],

  data: () => {
    return {
      showNew: false,
      refreshCounter: 0,
      filter: null,
    };
  },

  methods: {
    async refresh() {
      this.showNew = false;
      this.$emit("refresh");
    },
  },

  async mounted() {
    //
  },

  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "left",
          field: "id",
          sortable: true,
        },
        {
          name: "status",
          label: this.$t("label.status"),
          field: "status",
          align: "left",
          sortable: true,
        },
        {
          name: "date",
          align: "left",
          label: this.$t("label.date"),
          field: "date",
          sortable: true,
        },
        {
          name: "store",
          label: this.$t("label.store"),
          field: "store",
          align: "left",
          sortable: false,
        },
        {
          name: "product",
          label: this.$t("label.product"),
          field: "product",
          align: "left",
          sortable: false,
        },
        {
          name: "delivered",
          label: this.$t("label.delivered"),
          //format: (val) => this.$t(val ? "true" : "false"),
          field: "delivered",
          align: "left",
          sortable: false,
        },
        {
          name: "returned",
          label: this.$t("label.returned"),
          //format: (val) => this.$t(val ? "true" : "false"),
          field: "returned",
          align: "left",
          sortable: false,
        },
        {
          name: "source",
          label: this.$t("label.source"),
          field: "source",
          align: "left",
          format: (val) => val.toUpperCase(),

          sortable: true,
        },
        {
          name: "amount",
          label: this.$t("label.amount"),
          field: "amount",
          align: "left",

          sortable: true,
        },
        { name: "actions", style: "width:70px" },
      ];
      return columns;
    },
  },
};
</script>
