<template>
  <div>
  <Bar
    v-if="loaded" 
    :options="chartOptions"
    :data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :css-classes="cssClasses"
    :styles="styles"
    :height="height"
    style="max-width: 100vw"
    class="q-pa-sm" 
  />
</div>
</template>

<script>
import StatsApi from "../apis/Stats";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "StatsByStore",
  components: { Bar },
  props: {
    chartId: { type: String, default: "bar-chart" },
    datasetIdKey: { type: String, default: "label" },
    cssClasses: { default: "", type: String },
    styles: {
      type: Object,
      default: () => {
        return { "font-family": "Roboto" };
      },
    },
  },
  data: () => {
    return {
      loaded: false,
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        indexAxis: "y",
        plugins: {
          title: {
            display: true,
            text: "",
            font: {
              size: 24,
              family: "Roboto",
              weight: "400",
            },
            color: "#006560",
            padding: 20,
          },
          legend: {
            display: true,
            labels: {
              font: {
                size: 14,
                family: "Roboto",
              },
              color: "#006560",
            },
            position: "bottom",
            align: "end",
          },
          labels: {
            font: {
              size: 18,
              family: "Roboto",
            },
            color: "#006560",
          },
        },
        
        scales: {
          x: { min: 0, max: 0 },
          y: {
            ticks: {
              font: {
                size: 16,
                family: "Roboto",
              },
              color: "#006560",
            },
          },
        },
        
        animation: {
          duration: 1000,
          onComplete: function (evt) {
            let chartInstance = evt.chart;
            let ctx = chartInstance.ctx;

            ctx.font = "bold 14px Roboto";
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";

            this.data.datasets.forEach((dataset, i) => {
              let meta = chartInstance.getDatasetMeta(i);
              meta.data.forEach((bar, index) => {
                let data = dataset.data[index];
                if (data > 0) ctx.fillText(data, bar.x + 25, bar.y + 8);
              });
            });
          },
        },
      },

      stats: [],
      max: 20,
    };
  },
  async mounted() {
    await this.getStats();
    this.getDatasets();
    this.getMax();
    this.chartOptions.plugins.title.text = this.$t("title.statsPerNetwork");
    this.chartOptions.scales.x.max = this.max;
    this.loaded = true;
  },
  methods: {
    async getStats() {
      try {
        const statsResponse = await StatsApi.getChartPerNetwork({});
        this.stats = statsResponse.data;
        console.log(JSON.stringify(this.stats));
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    getMax() {
      this.stats.forEach((s) => {
        this.max = s.same_bottle > this.max ? s.same_bottle : this.max;
        this.max = s.other_bottle > this.max ? s.other_bottle : this.max;
        this.max = s.new_bottle > this.max ? s.new_bottle : this.max;
      });
      this.max =
        Math.round(this.max / 10) * 10 + Math.round(this.max / 100) * 10 + 10;
    },
    getDatasets() {
      this.chartData.labels = this.stats.map((s) => s.network);
      let datas =  [
        {
          label: this.$t("type.order.same_bottle"),
          backgroundColor: "#59a0cfa9",
          data: this.stats.map((s) => s.same_bottle),
          categoryPercentage: 1.0,
          barPercentage: 0.5,
        },
        {
          label: this.$t("type.order.new_bottle"),
          backgroundColor: "#228222A9",
          data: this.stats.map((s) => s.new_bottle),
          categoryPercentage: 1.0,
          barPercentage: 0.5,
        },
        {
          label: this.$t("type.order.other_bottle"),
          backgroundColor: "#222222A9",
          fontFamily: "Roboto",
          data: this.stats.map((s) => s.other_bottle),
          categoryPercentage: 1.0,
          barPercentage: 0.5,
        },
      ];
      this.chartData.datasets = datas;
      console.log(JSON.stringify(this.chartData.labels));
      console.log(JSON.stringify(datas));
    },
  },
  computed: {
    height() {
      return 100 + (this.$q.screen.lt.md ? 100 : 50) * this.stats.length;
    },
  },
};
</script>
