<template>
  <q-card
    flat
    class="q-pa-sm full-width no-padding-md"
    style="max-width: 100vw"
  >
    <q-card-section class="row q-pb-xs text-green-vito justify-between">
      <div class="text-h5">
        {{ $t("title.visits") }}
      </div>
      <div class="q-gutter-x-md row">
        <div class="flex column justify-center">
          <q-btn
            color="primary"
            @click="showNew = true"
            icon="add"
            rounded
            dense
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.addVisit") }}
            </q-tooltip>
          </q-btn>
        </div>
        <q-input
          outlined
          dense
          clearable
          debounce="300"
          v-model="filter"
          style="width: 120px"
          :placeholder="$t('label.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </q-card-section>

    <q-card-section>
      <visit-pages
        :filter="filter"
        :store="null"
        :type="null"
        :key="refreshCounter"
      />
    </q-card-section>
  </q-card>

  <q-dialog v-model="showNew" ref="newVisitDialog">
    <new-visit @refresh="refresh" />
  </q-dialog>
</template>
<script>
import NewVisit from "../components/visit/NewVisit.vue";
import { useI18n } from "vue-i18n";
import VisitPages from "../components/visit/VisitPages.vue";

export default {
  name: "VisitsPage",

  components: { NewVisit, VisitPages },
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      showNew: false,
      filter: "",
      refreshCounter: 0,
    };
  },
  methods: {
    refresh() {
      this.showNew = false;
      this.refreshCounter += 1;
    },
  },

  async mounted() {
    this.$store.state.title = "button.visits";
  },

  computed: {},
};
</script>
