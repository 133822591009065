<template>
  <q-card
    bordered
    class="q-mt-md q-pa-md shadow-1"
    style="width: 750px; max-width: 90vw"
  >
    <q-card-section class="row text-green-vito no-wrap justify-between">
      <div class="text-h6">
        {{ $t("title.addOrder") }}
      </div>
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>

    <q-select
      v-model="selectedStoreId"
      :options="stores"
      option-value="id"
      option-label="name"
      :label="$t('label.store')"
      filled
      borderless
      square
      emit-value
      hint=""
      map-options
      ref="store"
      @update:model-value="getStore"
    >
      <template v-slot:prepend>
        <q-icon name="apps" />
      </template>
    </q-select>

    <q-card-section v-if="hasAvailableProduct" class="q-pa-none">
      <q-select
        v-model="returnableType"
        :options="orderTypes"
        :label="$t('label.returnable')"
        :option-value="(item) => (item === null ? null : item)"
        :option-label="
          (item) => (item === null ? null : $t('type.order.' + item))
        "
        filled
        borderless
        square
        emit-value
        hint=""
        map-options
        ref="returnableType"
        @update:model-value="getStore"
      >
        <template v-slot:prepend>
          <q-icon name="settings" />
        </template>
      </q-select>

      <q-card-section class="row q-gutter-x-md q-pa-none justify-start">
        <q-select
          v-model="selectedProduct"
          :options="store.products.filter((p) => p.availableChargeCount)"
          :option-value="(item) => (item === null ? null : item.id)"
          :option-label="(item) => (item === null ? null : item.name)"
          :label="$t('label.orderProduct')"
          filled
          borderless
          square
          map-options
          hint=""
          ref="newProduct"
          style="min-width: 200px"
        >
          <template v-slot:selected>
            <q-item>
              <q-item-section avatar>
                <q-img
                  :src="productImage(selectedProduct)"
                  style="width: 40px"
                />
              </q-item-section>
              <q-item-section>
                <q-item-label>
                  {{ selectedProduct.name }} {{ selectedProduct.weight }}
                  {{ selectedProduct.id ? "kg" : "" }}
                </q-item-label>
                <q-item-label
                  >{{
                    selectedProduct.prices
                      ? getPrice(selectedProduct, false)
                      : ""
                  }}
                  {{ currency }}</q-item-label
                >
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section avatar>
                <q-img :src="productImage(scope.opt)" style="width: 50px" />
              </q-item-section>
              <q-item-section>
                <q-item-label
                  >{{ scope.opt.name }} {{ scope.opt.weight }} kg</q-item-label
                >
                <q-item-label
                  >{{ getPrice(scope.opt, false) }} {{ currency }}</q-item-label
                >
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:prepend>
            <q-icon name="settings" />
          </template>
        </q-select>

        <q-select
          v-if="returnableType == 'other'"
          v-model="selectedReturnProduct"
          :options="store.products.filter((p) => p.id != selectedProduct.id)"
          :option-value="(item) => (item === null ? null : item.id)"
          :option-label="(item) => (item === null ? null : item.name)"
          :label="$t('label.returnProduct')"
          filled
          borderless
          square
          map-options
          hint=""
          ref="returnProduct"
          style="min-width: 200px"
        >
          <template v-slot:selected>
            <q-item>
              <q-item-section avatar>
                <q-img
                  :src="productImage(selectedReturnProduct)"
                  style="width: 40px"
                />
              </q-item-section>
              <q-item-section>
                <q-item-label>
                  {{ selectedReturnProduct.name }}
                  {{ selectedReturnProduct.weight }}
                  {{ selectedReturnProduct.id ? "kg" : "" }}
                </q-item-label>
                <q-item-label
                  >{{
                    selectedReturnProduct.prices
                      ? getPrice(selectedReturnProduct, true)
                      : ""
                  }}
                  {{ currency }}</q-item-label
                >
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section avatar>
                <q-img :src="productImage(scope.opt)" style="width: 50px" />
              </q-item-section>
              <q-item-section>
                <q-item-label
                  >{{ scope.opt.name }} {{ scope.opt.weight }} kg</q-item-label
                >
                <q-item-label
                  >{{ getPrice(scope.opt, true) }} {{ currency }}</q-item-label
                >
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:prepend>
            <q-icon name="settings" />
          </template>
        </q-select>
      </q-card-section>
      <q-card-section horizontal>
        <q-form class="q-gutter-md row">
          <q-input
            ref="phone"
            square
            filled
            clearable
            v-model="form.phone"
            :label="$t('label.phone')"
            :hint="$t('hint.minchar', { chars: 3 })"
          >
            <template v-slot:prepend>
              <q-icon name="phone" />
            </template>
          </q-input>
          <q-input
            ref="email"
            square
            filled
            clearable
            v-model="form.email"
            :hint="$t('hint.emailFormat')"
            type="email"
            :label="$t('label.email')"
          >
            <template v-slot:prepend>
              <q-icon name="email" />
            </template>
          </q-input>
        </q-form>
      </q-card-section>

      <q-card-section class="text-green-vito" v-if="returnableType != 'same'">
        <strong>{{ $t("title.contractDetails") }}</strong>
      </q-card-section>
      <q-card-section horizontal v-if="returnableType != 'same'">
        <q-form class="q-gutter-md row">
          <q-input
            ref="firstname"
            square
            filled
            clearable
            v-model="form.firstname"
            :label="$t('label.firstname')"
            :hint="$t('hint.minchar', { chars: 3 })"
            lazy-rules
            :rules="[this.required, this.short]"
          >
            <template v-slot:prepend>
              <q-icon name="person" />
            </template>
          </q-input>
          <q-input
            ref="lastname"
            square
            filled
            clearable
            v-model="form.lastname"
            :label="$t('label.lastname')"
            :hint="$t('hint.minchar', { chars: 3 })"
            lazy-rules
            :rules="[this.required, this.short]"
          >
            <template v-slot:prepend>
              <q-icon name="person" />
            </template>
          </q-input>
          <q-input
            style="width: 100%"
            ref="address"
            square
            filled
            clearable
            v-model="form.address"
            :hint="$t('hint.minchar', { chars: 3 })"
            :label="$t('label.address')"
            lazy-rules
            :rules="[this.required, this.short]"
          >
            <template v-slot:prepend>
              <q-icon name="location_on" />
            </template>
          </q-input>
          <q-input
            ref="zip"
            square
            filled
            clearable
            v-model="form.zip"
            :hint="$t('hint.minchar', { chars: 3 })"
            :label="$t('label.zip')"
            lazy-rules
            :rules="[this.required, this.short]"
          >
            <template v-slot:prepend>
              <q-icon name="location_on" />
            </template>
          </q-input>
          <q-input
            ref="addrcityess"
            square
            filled
            clearable
            v-model="form.city"
            :hint="$t('hint.minchar', { chars: 3 })"
            :label="$t('label.city')"
            lazy-rules
            :rules="[this.required, this.short]"
          >
            <template v-slot:prepend>
              <q-icon name="location_on" />
            </template>
          </q-input>
        </q-form>
      </q-card-section>
    </q-card-section>
    <q-card-section>
      <div v-if="errorMessage" class="text-red-vito text-center">
        <strong>{{ errorMessage }}</strong>
      </div>
    </q-card-section>

    <q-card-section horizontal>
      <q-input
        outlined
        v-model="orderAmount"
        :label="$t('label.orderAmount')"
        readonly
        :suffix="currency"
      />
      <q-input
        outlined
        v-model="transactionReference"
        :label="$t('label.transactionReference')"
        readonly
      />
      <q-input
        outlined
        v-model="authorisationNumber"
        :label="$t('label.authorisationNumber')"
        readonly
      />
      <q-input
        outlined
        v-model="transactionSource"
        :label="$t('label.transactionSource')"
        readonly
      />
      <q-input
        outlined
        v-model="transactionLog"
        :label="$t('label.transactionLog')"
        readonly
      />
      <q-input
        outlined
        v-model="language"
        :label="$t('label.language')"
        readonly
      />
    </q-card-section>

    <q-card-actions class="q-pa-md justify-end">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        v-close-popup
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="$t('button.order')"
        :disable="!hasAvailableProduct"
        @click="handleSave"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
import StoreApi from "../../apis/Store";
import OrderApi from "../../apis/Order";
import { useStore } from "vuex";

export default {
  emits: ["refresh"],
  setup() {
    const store = useStore();
    const currency = store.getters.currency;
    return {
      currency,
    };
  },
  data: () => {
    return {
      form: {
        phone: "",
        email: "francois.annabi@the-keys.fr",
        firstname: "John",
        lastname: "Doe",
        address: "Kirchstrasse 4",
        zip: "3097",
        city: "Liebefeld",
        country: "ch",
      },
      store: { products: [] },
      stores: [],
      errorMessage: "",
      selectedStoreId: null,
      selectedProduct: {},
      selectedReturnProduct: {},
      returnableType: "same",
      orderTypes: ["same", "other", "none"],
      transactionReference: "123456789",
      authorisationNumber: "987654321",
      transactionSource: "cb",
      transactionLog: "Ticket from bank return.",
      language: "DE",
      hasAvailableProduct: true,
    };
  },

  async mounted() {
    await this.getAllStores();
    await this.getStore();
    //console.log(this.stores);
  },
  methods: {
    async getAllStores() {
      try {
        const storesResponse = await StoreApi.findAll();
        this.stores = storesResponse.data;
        this.selectedStoreId = this.stores[0].id;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async getStore() {
      this.errorMessage = null;
      //console.log("storeId", this.selectedStoreId);
      if (this.selectedStoreId) {
        try {
          const storeResponse = await StoreApi.find(this.selectedStoreId);
          this.store = storeResponse.data;
          //console.log(this.store.products);
          this.hasAvailableProduct = this.store.products.some(
            (p) => p.availableChargeCount
          );
          //console.log(this.hasAvailableProduct);
          this.selectedProduct = this.hasAvailableProduct
            ? this.store.products.find((p) => p.availableChargeCount)
            : null;

          this.selectedReturnProduct = this.selectedProduct
            ? this.store.products.find((p) => p.id != this.selectedProduct.id)
            : null;
        } catch (error) {
          const message = error.response
            ? error.response.data.message
            : error.message;
          this.errorMessage = message;
          this.$q.notify({
            type: "negative",
            message: message,
          });
        }
      }
    },
    getPrice(product, isReturnable) {
      //console.log("PROD", product);
      if (!product) return null;
      let prices = product.prices;
      if (!isReturnable) {
        let normal = prices.find((price) => price.name == "charge");
        let promo = prices.find((price) => price.name == "promo");

        return promo.price == -1
          ? normal.price
          : promo.price < normal.price
          ? promo.price
          : normal.price;
      }
      let returnable = prices.find((price) => price.name == "returnable");
      return returnable.price;
    },

    async handleSave() {
      //console.log("saving new");
      this.errorMessage = null;

      try {
        const orderResponse = await OrderApi.create({
          storeId: this.store.id,
          orderAmount: this.orderAmount,
          transactionReference: this.transactionReference,
          authorisationNumber: this.authorisationNumber,
          transactionSource: this.transactionSource,
          transactionLog: this.transactionLog,
          language: this.language,
          productId: this.selectedProduct.id,
          returnProductId:
            this.returnableType == "none"
              ? null
              : this.returnableType == "same"
              ? this.selectedProduct.id
              : this.selectedReturnProduct.id,
          ...this.form,
        });
        this.$q.notify({
          type: "positive",
          message: this.$t(orderResponse.data.message),
        });
        this.$emit("refresh");
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    selectedClass(product, selected) {
      if (!product) return "";
      if (selected == "new") {
        return product.id == this.selectedProduct.id
          ? "border-vito shadow-2"
          : "border-transparent";
      } else {
        return product.id == this.selectedReturnProduct.id
          ? "border-vito shadow-2"
          : "border-transparent";
      }
    },
    productImage(product) {
      if (product && product.image)
        return (
          process.env.VUE_APP_BASE_URL + "/product/images/" + product.image
        );
    },
    required(val) {
      return (val && val.length > 0) || this.$t("validation.required");
    },
    short(val) {
      return (
        (val && val.length > 2) || this.$t("validation.minchar", { chars: 3 })
      );
    },
    isEmail(val) {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val) || this.$t("validation.isEmail");
    },
  },

  computed: {
    orderAmount() {
      //['same', 'other', 'none']
      if (this.selectedProduct && this.selectedProduct.prices) {
        switch (this.returnableType) {
          case "same":
            return this.getPrice(this.selectedProduct, false);
          case "other":
            return (
              this.getPrice(this.selectedProduct, false) -
              this.getPrice(this.selectedProduct, true) +
              this.getPrice(this.selectedReturnProduct, true)
            );
          case "none":
            return (
              this.getPrice(this.selectedProduct, false) +
              this.getPrice(this.selectedProduct, true)
            );
          default:
            return "error";
        }
      }

      return "";
    },
  },
};
</script>
