<template>
  <div style="max-width: 100vw">
    <q-tabs
      v-model="tab"
      class="text-green-vito-light bg-green-vito-light"
      active-class="text-green-vito text-strong"
      indicator-color="green-vito"
      align="justify"
    >
      <q-route-tab
        name="orders"
        to="/logs/orders"
        :label="$t('button.orders')"
        icon="shopping_cart"
      />
      <q-route-tab
        name="lockers"
        to="/logs/lockers"
        :label="$t('button.lockers')"
        icon="lock"
      />
      <q-route-tab
        name="messages"
        to="/logs/messages"
        :label="$t('button.messages')"
        icon="mail_outline"
      />
      <q-route-tab
        name="terminals"
        to="/logs/terminals"
        :label="$t('button.terminals')"
        icon="tablet_android"
      />
    </q-tabs>

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="orders" class="q-pa-none">
        <orders />
      </q-tab-panel>

      <q-tab-panel name="lockers" class="q-pa-none">
        <lockers />
      </q-tab-panel>

      <q-tab-panel name="messages" class="q-pa-none">
        <messages />
      </q-tab-panel>

      <q-tab-panel name="terminals" class="q-pa-none">
        <terminals />
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script>
import Orders from "../components/log/OrderLogs.vue";
import Lockers from "../components/log/LockerLogs.vue";
import Terminals from "../components/log/TerminalLogs.vue";
import Messages from "../components/log/MessageLogs.vue";
export default {
  components: { Orders, Lockers, Messages, Terminals },
  data() {
    return {
      tab: null,
    };
  },
  mounted() {
    this.$store.state.title = "button.logs";
  },
  computed: {},
};
</script>
