<template>
  <q-card
    bordered
    class="q-pa-sm q-mb-sm no-padding-md"
    style="width: 480px; max-width: 100vw"
  >
    <q-card-section class="row q-pa-sm text-green-vito justify-between">
      <div class="col-grow">
        <div class="text-h5 text-green-vito" v-if="terminalId">
          {{ $t("title.editTerminal") }} #{{ terminalId }}
        </div>
        <div class="text-h5 text-green-vito" v-else>
          {{ $t("title.addTerminal") }}
        </div>
      </div>

      <div class="row q-gutter-x-sm no-wrap">
        <q-btn
          v-if="terminalId"
          icon="delete_forever"
          class="text-red-vito"
          round
          dense
          @click="handleDelete"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.deleteTerminal") }}
          </q-tooltip>
        </q-btn>
        <q-btn icon="close" flat round dense v-close-popup />
      </div>
    </q-card-section>

    <q-card-section class="column">
      <q-form class="q-gutter-y-md q-mt-md">
        <q-input
          ref="name"
          square
          filled
          clearable
          v-model="form.name"
          :label="$t('label.name')"
          :hint="$t('hint.minchar', { chars: 2 })"
          lazy-rules
          :rules="[required, short]"
        >
        </q-input>

        <q-input
          ref="serialNumber"
          square
          filled
          clearable
          v-model="form.serialNumber"
          :label="$t('label.serialNumber')"
          :hint="$t('hint.minchar', { chars: 2 })"
          lazy-rules
          :rules="[short, required]"
        >
        </q-input>

        <q-input
          ref="version"
          square
          filled
          clearable
          type="number"
          v-model.number="form.version"
          :label="$t('label.version')"
          :hint="$t('hint.number')"
          lazy-rules
          :rules="[isNumber]"
        >
        </q-input>

        <q-input
          ref="traceability"
          input-style="max-height: 180px"
          square
          filled
          clearable
          type="textarea"
          autogrow
          v-model="form.traceability"
          :label="$t('label.traceability')"
          hint=""
        >
        </q-input>

        <q-select
          class="col-12 col-sm-4"
          v-model="form.command"
          :options="commands"
          :label="$t('label.command')"
          filled
          borderless
          square
          hint=""
          map-options
          ref="command"
        >
          <template v-slot:prepend>
            <q-icon name="touch_app" />
          </template>
        </q-select>
      </q-form>
    </q-card-section>

    <q-card-section v-if="errorMessage">
      <div class="text-red-vito text-center">
        <strong>{{ errorMessage }}</strong>
      </div>
    </q-card-section>

    <q-card-actions class="q-px-md justify-end">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        @click="handleCancel"
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="$t('button.save')"
        @click="handleSave"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
import TerminalApi from "../../apis/Terminal";

export default {
  emits: ["refresh"],
  props: ["terminalId"],
  data: () => {
    return {
      commands: [],
      refreshCounter: 0,
      form: {
        id: null,
        store: null,
        name: null,
        version: 0,
        serialNumber: null,
        traceability: null,
        command: null,
      },
      errorMessage: null,
    };
  },
  async mounted() {
    if (this.terminalId) {
      await this.getTerminal();
    }
    await this.getCommands();
  },
  methods: {
    async getTerminal() {
      try {
        const terminalResponse = await TerminalApi.findOne(this.terminalId);
        this.form = terminalResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async getCommands() {
      try {
        const commandsResponse = await TerminalApi.getCommands();
        this.commands = commandsResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    handleCancel() {
      this.$emit("refresh");
    },

    async handleSave() {
      //console.log("saving");
      this.errorMessage = null;
      ["name", "serialNumber", "version"].forEach((f) => {
        if (!this.$refs[f].validate()) {
          this.errorMessage = this.$t("validation.verify");
          return;
        }
      });
      if (!this.errorMessage) {
        const payload = {
          name: this.form.name,
          version: this.form.version,
          serialNumber: this.form.serialNumber,
          traceability: this.form.traceability,
          command: this.form.command,
        };
        try {
          const updateTerminalResponse = this.terminalId
            ? await TerminalApi.save(this.form.id, {
                id: this.form.id,
                ...payload,
              })
            : await TerminalApi.create(payload);
          this.$emit("refresh");

          this.$q.notify({
            type: "positive",
            message: this.$t(updateTerminalResponse.data.message),
          });
        } catch (error) {
          const message = error.response
            ? error.response.data.message
            : error.message;
          this.errorMessage = message;
          this.$q.notify({
            type: "negative",
            message: message,
          });
        }
      }
    },

    async handleDelete() {
      this.$q
        .dialog({
          title: this.$t("title.confirmDelete"),
          message: this.$t("message.confirmDeleteTerminal"),
          ok: {
            color: "red-vito",
            label: this.$t("button.deleteDefinitively"),
          },
          cancel: {
            label: this.$t("button.cancel"),
            color: "green-vito",
          },
          persistent: true,
        })
        .onOk(() => {
          this.deleteTerminal();
        });
    },
    async deleteTerminal() {
      try {
        const deleteResponse = await TerminalApi.delete(this.form.id);
        this.$q.notify({
          type: "positive",
          message: this.$t(deleteResponse.data.message),
        });
        //console.log(saveResponse.data);
        this.$emit("refresh");
      } catch (error) {
        let message = error.response
          ? error.response.data.message
          : error.message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
        //console.error(message);
      }
    },
    required(val) {
      return (
        (val && val.toString().length > 0) || this.$t("validation.required")
      );
    },
    short(val) {
      return (
        (val && val.length > 1) || this.$t("validation.minchar", { chars: 2 })
      );
    },
    isNumber(val) {
      return !isNaN(val) || this.$t("validation.number");
    },
  },
  computed: {},
};
</script>
