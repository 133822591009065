<template>
  <q-card
    flat
    class="q-pa-sm full-width no-padding-md"
    :class="isActiveBg"
    style="max-width: 100vw"
  >
    <q-card-section class="row q-pb-none text-green-vito justify-between">
      <div class="text-h5">
        {{ form.name }}
      </div>
      <div class="q-gutter-x-sm row">
        <q-btn
          icon="delete_forever"
          square
          round
          dense
          v-if="form.deletable && !form.products.length"
          class="text-red-vito"
          @click="handleDelete"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.deleteStore") }}
          </q-tooltip>
        </q-btn>
        <q-btn
          v-if="action == 'display' || editing"
          icon="apps"
          square
          round
          dense
          to="/stores"
          @click="$emit('cancel')"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.listStore") }}
          </q-tooltip>
        </q-btn>

        <q-btn
          v-if="action == 'display'"
          icon="vpn_key"
          :color="showCodes ? 'green-vito' : 'white'"
          :text-color="showCodes ? 'white' : 'green-vito'"
          square
          round
          dense
          @click="handleShowCodes"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{
              showCodes ? $t("tooltip.hideCodes") : $t("tooltip.displayCodes")
            }}
          </q-tooltip>
        </q-btn>
        <q-btn
          v-if="action == 'display'"
          icon="edit"
          square
          round
          dense
          @click="$emit('edit', storeId)"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.editStore") }}
          </q-tooltip>
        </q-btn>
        <q-btn
          v-if="action == 'edit'"
          icon="visibility"
          square
          round
          dense
          @click="$emit('display', storeId)"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.showStore") }}
          </q-tooltip>
        </q-btn>
      </div>
    </q-card-section>

    <q-card-section class="col" v-if="!editing" style="min-width: 320px">
      <span class="text-grey-8 text-italic" v-if="form.network">
        {{ form.network.name }}
      </span>
      <p>
        {{ form.location }}
      </p>
      <div>
        <q-icon name="leaderboard" class="q-mr-md" />
        {{
          form.transactions
            ? parseFloat(Math.ceil(form.transactions) / 1000).toLocaleString(
                locale,
                {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 3,
                }
              )
            : ""
        }}
        T/{{ $t("month") }}
      </div>
    </q-card-section>

    <q-card-section class="row q-gutter-md q-mt-md q-mr-md" v-if="editing">
      <q-input
        ref="name"
        class="col-12 col-sm-3"
        square
        filled
        clearable
        v-model="form.name"
        :label="$t('label.name')"
        :hint="$t('hint.minchar', { chars: 2 })"
        lazy-rules
        :rules="[required, short]"
      >
        <template v-slot:prepend>
          <q-icon name="apps" />
        </template>
      </q-input>

      <div class="col-12 col-sm-3 row no-wrap xq-gutter-x-xs">
        <q-select
          class="col-grow"
          v-model="form.network"
          :options="networks"
          option-value="id"
          option-label="name"
          :label="$t('label.network')"
          filled
          borderless
          square
          map-options
          emit-value
          ref="network"
        >
          <template v-slot:prepend>
            <q-icon name="store" />
          </template>
        </q-select>
        <div
          class="q-pa-sm"
          style="height: 56px; background-color: rgba(0, 0, 0, 0.05)"
        >
          <q-btn
            icon="store"
            color="primary"
            round
            dense
            @click.prevent="handleShowNetwork"
          />
        </div>
      </div>

      <q-input
        ref="location"
        class="col-12 col-sm-8"
        square
        filled
        clearable
        v-model="form.location"
        :label="$t('label.location')"
      >
        <template v-slot:prepend>
          <q-icon name="place" />
        </template>
      </q-input>

      <q-field
        :stack-label="form.suppliersEmail.length ? true : false"
        square
        filled
        :label="$t('label.suppliersEmail')"
        class="col-12 col-sm-8"
      >
        <template v-slot:prepend>
          <q-icon name="mail" />
        </template>
        <template v-slot:append>
          <q-btn
            icon="add"
            color="primary"
            round
            dense
            @click="handleSupplierEmail"
          />
        </template>
        <template v-slot:control>
          <div class="q-gutter-xs q-pt-sm">
            <q-chip
              v-for="(email, index) in form.suppliersEmail"
              :key="index"
              removable
              @remove="removeSupplierEmail(index)"
              color="green-vito"
              text-color="white"
            >
              {{ email }}
            </q-chip>
          </div></template
        >
      </q-field>

      <q-select
        class="col-12 col-sm-8"
        v-model="selectedLanguage"
        :options="languages"
        option-value="id"
        option-label="name"
        :label="$t('label.language')"
        filled
        borderless
        square
        hint=""
        map-options
        ref="language"
        @update:model-value="onSelectedLanguage"
      >
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-item-section>
              <q-item-label>
                {{ scope.opt.name }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>

        <template v-slot:prepend>
          <q-icon name="language" />
        </template>
      </q-select>

      <q-select
        class="col-12 col-sm-4"
        v-model="selectedTerminal"
        :options="terminals"
        option-value="id"
        option-label="name"
        :label="$t('label.terminal')"
        filled
        borderless
        square
        hint=""
        map-options
        ref="terminal"
        @update:model-value="onSelectedTerminal"
      >
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-item-section>
              <q-item-label>
                {{ scope.opt.name }}
              </q-item-label>

              <q-item-label caption>
                sn: {{ scope.opt.serialNumber }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>

        <template v-slot:prepend>
          <q-icon name="phone_android" />
        </template>
      </q-select>

      <q-toggle
        class="col-2"
        ref="isActive"
        v-model="form.isActive"
        :label="form.isActive == true ? $t('active') : $t('inactive')"
        color="green-vito"
      >
      </q-toggle>

      <q-card-section v-if="errorMessage">
        <div class="text-red-vito text-center">
          <strong>{{ errorMessage }}</strong>
        </div>
      </q-card-section>
    </q-card-section>
    <q-card-actions class="q-px-md justify-end" v-if="editing">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        @click="
          this.action == 'create' ? $emit('cancel') : $emit('display', form.id)
        "
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="$t('button.save')"
        @click="handleSave"
      />
    </q-card-actions>
    <q-card-section v-if="showCodes && form.id">
      <store-codes :store="form" :key="refreshCounter" @refresh="refresh" />
    </q-card-section>

    <q-card-section v-if="action != 'create'">
      <displays
        :store="form"
        :storeId="storeId"
        :parentAction="action"
        :key="refreshCounter"
        @refresh="refresh"
      />
    </q-card-section>

    <q-card-section v-if="showTags && form.id">
      <store-tags
        :store="form"
        :key="refreshCounter"
        @refresh="refresh"
      />
    </q-card-section>

    <q-card-section v-if="showSupplierVisits && form.id">
      <store-visits
        :store="form"
        type="supplier"
        :key="refreshCounter"
        @refresh="refresh"
      />
    </q-card-section>

    <q-card-section v-if="showTechnicianVisits && form.id">
      <store-visits
        :store="form"
        type="technician"
        :key="refreshCounter"
        @refresh="refresh"
      />
    </q-card-section>

    <q-card-section v-if="action != 'create'">
      <q-toolbar class="text-green-vito bg-grey-1 q-mb-sm q-pl-none shadow-1">
        <q-btn-dropdown
          stretch
          flat
          :label="$t('button.products')"
          dropdown-icon="more_vert"
          menu-anchor="bottom left"
          menu-self="top left"
          icon-left
        >
          <q-list>
            <q-item-label header>{{ $t("label.selectProducts") }}</q-item-label>
            <q-item
              v-for="product in products"
              :key="product.id"
              clickable
              tabindex="0"
            >
              <q-item-section avatar>
                <q-checkbox
                  color="secondary"
                  v-model="selectedProductIds"
                  :val="product.id"
                  @update:model-value="handleProductSelection"
                  :label="product.name + ' ' + product.weight + ' kg'"
                />
              </q-item-section>
            </q-item>
            <q-item-section class="q-mt-md">
              <q-btn to="/products">{{ $t("button.manageProducts") }}</q-btn>
            </q-item-section>
          </q-list>
        </q-btn-dropdown>
      </q-toolbar>
      <div class="row q-gutter-md">
        <div v-for="product in form.products" :key="product.id">
          <store-product-card
            :store="form"
            :product="product"
            :action="action"
            :key="refreshCounter"
            :pricing="pricing"
            @priceUpdate="priceUpdate"
            @levelAlertUpdate="levelAlertUpdate"
            @refresh="refresh"
          />
        </div>
        <div
          v-if="form.products && !form.products.length"
          class="text-grey-6 text-italic q-pl-sm"
        >
          {{ $t("emptyList") }}
        </div>
      </div>
    </q-card-section>
  </q-card>

  <q-dialog v-model="showAddEmail" persistent ref="newEmailDialog">
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-h5 text-green-vito">{{ $t("label.email") }}</div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <q-input
          ref="newEmail"
          dense
          v-model="newEmail"
          autofocus
          :hint="$t('hint.emailFormat')"
          type="email"
          lazy-rules
          :rules="[isEmail]"
        />
      </q-card-section>

      <q-card-actions align="right" class="text-primary">
        <q-btn flat :label="$t('button.cancel')" v-close-popup />
        <q-btn flat :label="$t('button.add')" @click="addSupplierEmail" />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showAddNetwork" ref="newNetworkDialog">
    <network-card @updateNetworks="getNetworks" />
  </q-dialog>
</template>

<script>
import StoreApi from "../../apis/Store";
import PriceApi from "../../apis/Price";
import ProductApi from "../../apis/Product";
import StoreProductCard from "./StoreProductCard.vue";
import Displays from "../display/Displays.vue";
import StoreCodes from "../code/StoreCodes.vue";
import TerminalApi from "../../apis/Terminal";
import { useI18n } from "vue-i18n";
import StoreVisits from "./StoreVisits.vue";
import NetworkCard from "./NetworkCard.vue";
import StoreTags from "./StoreTags.vue";

export default {
  props: ["storeId", "action"],
  components: {
    StoreProductCard,
    Displays,
    StoreCodes,
    StoreVisits,
    NetworkCard,
    StoreTags,
  },
  emits: ["refresh", "edit", "display", "cancel", "hide"],
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      networks: [],
      showTags: true,
      showSupplierVisits: true,
      showTechnicianVisits: true,
      newEmail: null,
      showAddEmail: false,
      showAddNetwork: false,
      terminals: null,
      languages: null,
      selectedLanguage: null,
      selectedTerminal: null,
      showCodes: false,
      passwordFieldType: "password",
      visibilityIcon: "visibility",
      form: {
        id: null,
        name: "",
        location: "",
        isActive: null,
        deletable: false,
        products: [],
        terminals: [],
        defaultLanguage: "",
        suppliersEmail: [],
        network: null,
      },
      errorMessage: null,
      refreshCounter: 0,
      pricing: [],
      selectedProductIds: [],
      products: [],
      origProducts: [],
      newStore: {
        id: 0,
        name: "New Store",
        location: "",
        isActive: true,
        terminals: [],
        defaultLanguage: "",
        suppliersEmail: [],
        network: null,
      },
    };
  },
  async mounted() {
    await this.getStore();
    if (this.action == "create" || this.action == "edit") {
      await this.getLanguages();
      await this.getTerminals();
      await this.getNetworks();
    }
  },
  methods: {
    async getNetworks() {
      try {
        const networksResponse = await StoreApi.findNetworks();
        this.networks = networksResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    handleShowNetwork() {
      this.showAddNetwork = true;
    },

    handleSupplierEmail() {
      this.showAddEmail = true;
    },
    addSupplierEmail() {
      //console.log(this.form);
      this.$refs.newEmail.validate();
      if (this.$refs.newEmail.hasError) {
        this.$refs.newEmailDialog.shake();
        // form has error
      } else {
        this.form.suppliersEmail.push(this.newEmail);
        this.showAddEmail = false;
      }
    },
    removeSupplierEmail(index) {
      //console.log(this.form);
      this.form.suppliersEmail.splice(index, 1);
    },
    handleShowTechnicianVisits() {
      this.showTechnicianVisits = !this.showTechnicianVisits;
    },
    handleShowSupplierVisits() {
      this.showSupplierVisits = !this.showSupplierVisits;
    },
    handleShowCodes() {
      this.showCodes = !this.showCodes;
    },
    async refresh() {
      //console.log("refresh card");
      await this.getStore();
      this.refreshCounter += 1;
    },
    onSelectedTerminal() {
      //console.log(this.selectedTerminal);
      this.form.terminals[0] = this.selectedTerminal;
    },
    onSelectedLanguage() {
      this.form.defaultLanguage = this.selectedLanguage.id;
    },
    async getTerminals() {
      try {
        const terminalsResponse = await TerminalApi.findAll();
        this.terminals = terminalsResponse.data;
        this.selectedTerminal = this.terminals.length
          ? this.terminals.find((t) =>
              t.store ? t.store.id == this.storeId : false
            )
          : null;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async getLanguages() {
      this.languages = [
        { id: "DE", name: "Deutch" },
        { id: "EN", name: "English" },
        { id: "FR", name: "Français" },
        { id: "IT", name: "Italiano" },
      ];
      this.selectedLanguage = this.languages.find(
        (l) => l.id == this.form.defaultLanguage
      );
      console.log(this.form.defaultLanguage);
      this.form.languages = [this.selectedLanguage];
      console.log(this.form);
    },

    async getStore() {
      try {
        const pricingResponse = await PriceApi.findPricing();
        this.pricing = pricingResponse.data;
        if (this.action == "create") {
          this.form = this.newStore;
        } else {
          const storeResponse = await StoreApi.find(this.storeId);
          this.form = storeResponse.data;
          this.origProducts = storeResponse.data.products;
          this.selectedProductIds = storeResponse.data.products.map(
            (product) => product.id
          );
        }
        const allProductsResponse = await ProductApi.findAll();
        this.products = allProductsResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async handleSave() {
      this.errorMessage = null;
      ["name", "location"].forEach((f) => {
        if (!this.$refs[f].validate()) {
          this.errorMessage = this.$t("validation.verify");
          return;
        }
      });
      if (!this.errorMessage) {
        try {
          const payload = (({
            id,
            name,
            location,
            isActive,
            terminals,
            defaultLanguage,
            suppliersEmail,
            network,
          }) => ({
            id,
            name,
            location,
            isActive,
            terminals,
            defaultLanguage,
            suppliersEmail,
            network,
          }))(this.form);
          console.log(payload);

          const saveResponse =
            payload.id == 0
              ? await StoreApi.create(payload)
              : await StoreApi.save(payload);
          this.$q.notify({
            type: "positive",
            message: this.$t(saveResponse.data.message),
          });
          //console.log(saveResponse.data);
          if (this.action == "create") {
            this.$router.push("/store/" + saveResponse.data.store.id);
          } else {
            this.$emit("display", saveResponse.data.store.id);
          }
        } catch (error) {
          const message = error.response
            ? error.response.data.message
            : error.message;
          this.errorMessage = message;
          this.$q.notify({
            type: "negative",
            message: message,
          });
          //console.error(message);
        }
      }
    },

    async handleDelete() {
      this.$q
        .dialog({
          title: this.$t("title.confirmDelete"),
          message: this.$t("message.confirmDeleteStore"),
          ok: {
            color: "red-vito",
            label: this.$t("button.deleteDefinitively"),
          },
          cancel: {
            label: this.$t("button.cancel"),
            color: "green-vito",
          },
          persistent: true,
        })
        .onOk(async () => {
          try {
            const removeResponse = await StoreApi.remove(this.form.id);
            this.$q.notify({
              type: "positive",
              message: this.$t(removeResponse.data.message),
            });
            this.$router.push("/stores");
          } catch (error) {
            const message = error.response
              ? error.response.data.message
              : error.message;
            this.errorMessage = message;
            this.$q.notify({
              type: "negative",
              message: message,
            });
            //console.error(message);
          }
        });
    },

    async levelAlertUpdate(emittedPayload) {
      //console.log("payload", emittedPayload);
      try {
        const payload = {
          productId: emittedPayload.product.id,
          levelAlert: emittedPayload.product.levelAlert,
        };
        //console.log(payload);
        const saveResponse = await StoreApi.saveProductLevelAlert(
          emittedPayload.store.id,
          payload
        );
        this.$q.notify({
          type: "positive",
          message: this.$t(saveResponse.data.message),
        });
        this.$emit("hide");
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async priceUpdate(emittedPayload) {
      //console.log("payload", emittedPayload);
      try {
        const payload = {
          storeId: emittedPayload.store.id,
          productId: emittedPayload.product.id,
          productPrices: emittedPayload.product.prices,
        };
        //console.log(payload);
        const saveResponse = await PriceApi.saveProduct(
          emittedPayload.product.id,
          payload
        );
        this.$q.notify({
          type: "positive",
          message: this.$t(saveResponse.data.message),
        });
        //console.log(saveResponse.data);
        this.$emit("hide");
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
        //console.error(message);
      }
    },

    async handleProductSelection() {
      const currentProducts = this.form.products.map((p) => p.id);
      //console.log("current", currentProducts);
      //console.log("selected", this.selectedProductIds);

      let toAdd = [];
      let toRemove = [];
      this.selectedProductIds.forEach((productId) => {
        let found = this.form.products.find((p) => p.id == productId);
        if (!found) {
          toAdd.push(productId);
        }
      });
      currentProducts.forEach((productId) => {
        //console.log(productId);
        let found = this.selectedProductIds.find((p) => p == productId);
        if (!found) {
          toRemove.push(productId);
        }
      });
      //console.log("toAdd", toAdd);
      //console.log("toRemove", toRemove);
      try {
        toAdd.forEach(async (productId) => {
          const addProductResponse = await StoreApi.addProduct(
            this.storeId,
            productId
          );
          this.$q.notify({
            type: "positive",
            message: this.$t(addProductResponse.data.message),
          });
          await this.getStore();
        });

        toRemove.forEach(async (productId) => {
          this.$q
            .dialog({
              title: this.$t("title.confirmDelete"),
              message: this.$t("message.confirmDeleteStoreProduct"),
              ok: {
                color: "red-vito",
                label: this.$t("button.removeStoreProduct"),
              },
              cancel: {
                label: this.$t("button.cancel"),
                color: "green-vito",
              },
              persistent: true,
            })
            .onOk(async () => {
              const removeProductResponse = await StoreApi.removeProduct(
                this.storeId,
                productId
              );
              this.$q.notify({
                type: "positive",
                message: this.$t(removeProductResponse.data.message),
              });
              await this.getStore();
            })
            .onCancel(async () => {
              await this.getStore();
            });
        });
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    isEmail(val) {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val) || this.$t("validation.isEmail");
    },

    required(val) {
      return (
        (val && val.toString().length > 0) || this.$t("validation.required")
      );
    },
    short(val) {
      return (
        (val && val.length > 1) || this.$t("validation.minchar", { chars: 2 })
      );
    },
    len4(val) {
      return (
        (val && val.toString().length == 4) ||
        this.$t("validation.numberlength", { chars: 4 })
      );
    },
    len8(val) {
      return (
        (val && val.toString().length == 8) ||
        this.$t("validation.numberlength", { chars: 8 })
      );
    },
    isNumber(val) {
      return !isNaN(val) || this.$t("validation.number");
    },
    switchVisibility() {
      this.visibility = !this.visibility;
      this.passwordFieldType = this.visibility ? "number" : "password";
      this.visibilityIcon = this.visibility ? "visibility_off" : "visibility";
    },
  },
  computed: {
    title() {
      let title = this.$t("store");
      return `${title.charAt(0).toUpperCase() + title.slice(1)} #${
        this.form.id
      }`;
    },
    editing() {
      return this.action == "create" || this.action == "edit";
    },
    isActiveBg() {
      return this.form.isActive == false ? "bg-grey-3" : "";
    },
  },
};
</script>
