export const OrderAction = {
  create: { color: "primary" },
  send_message: { color: "secondary" },
  complete: { color: "green-vito" },
  retry_locker: { color: "purple-10" },
  cancel: { color: "orange-10" },
  validate_transaction: { color: "purple-10" },
};

export const OrderType = {
  same_bottle: { color: "green-vito" },
  other_bottle: { color: "secondary" },
  new_bottle: { color: "primary" },
};

export const OrderStatus = {
  new: { color: "primary" },
  complete: { color: "green-vito" },
  0: { color: "green-vito" },
  message_sent: { color: "green-vito" },
  error: { color: "red-vito" },
  1: { color: "red-vito" },
  locker_changed: { color: "green-vito" },
  canceled: { color: "orange-10" },
  aborted: { color: "purple-10" },
};

export const OrderTransactionType = {
  complete: { color: "green-vito" },
  cancel: { color: "red-vito" },
  failure: { color: "red-vito" },
};
