export default {
  hello: "Chuss",
  dashboard: "Armaturenbrett",
  order: "Bestellen",
  store: "Verkaufsstelle",
  product: "Produkt",
  display: "Display",
  month: "Monat",
  active: "Aktiv",
  inactive: "Inaktiv",
  emptyList: "Diese Liste ist leer",
  true: "Ja",
  false: "Nein",
  notFound: "Nicht gefunden",
  status: {
    ok: "OK",
    new: "Neu",
    cancel: "Abgesagt",
    success: "Erfolg",
    failure: "Versagen",
    complete: "Abgeschlossen",
    0: "Erfolg",
    1: "Versagen",
    2: "Retry",
    error: "Error",
    message_sent: "Nachricht gesendet",
    locker_changed: "Schließfach geändert",
    locker_fault_reported: "Schließfachfehler gemeldet",
    locker_fault_cleared: "Schließfachstörung beseitigt",
    canceled: "Storniert",
    contract_sent: "Gesendet",
    contract_new: "Ausstehend",
    contract_cancel: "Storniert",
    aborted: "Abgebrochen",
    locker_opened: "Schließfach geöffnet",
  },
  title: {
    editProfile: "Mein Profil bearbeiten",
    lockerActionNone: "Keine Aktion für Schließfach verfügbar {doorName}",
    lockerActionMoveIn:
      "Bitte wählen Sie einen Artikel für das Schließfach {doorName}",
    lockerActionMoveOut:
      "Bitte bestätigen Sie die Entfernung des Artikels für das Schließfach {doorName}",
    confirmDelete: "Bitte bestätigen Sie die Löschung",
    editProduct: "Produkt bearbeiten",
    addProduct: "Ein neues Produkt hinzufügen",
    listProducts: "Alle Produkte",
    editStore: "Bearbeiten Sie die Verkaufsstelle",
    addStore: "Verkaufsstelle hinzufügen",
    listStores: "Alle Verkaufsstellen",
    editDisplay: "Display bearbeiten",
    addOrder: "Neue Bestellung",
    completeOrder: "Die Bestellung abschließen",
    contractDetails: "Details für die Einzahlungskarte",
    orders: "Bestellungen",
    devInProgress: "Diese Seite ist in Entwicklung",
    users: "Nutzern",
    editUser: "Benutzer bearbeiten",
    addUser: "Neuen Benutzer hinzufügen",
    logDetails: "Logdetails",
    addCode: "Code-Typ auswählen",
    orderMessage: "Nachricht ",
    logs: "Logs",
    visits: "Besuche",
    visit: "Besuch",
    completeVisit: "Vollständiger Besuch",
    addVisit: "Neue Besuchsanfrage",
    comment: "Kommentar",
    lockerActionResetFaulty:
      "Bitte bestätigen Sie den Status des zurückgesetzten Schließfachs als fehlerhaft",
    lockerActionResetReserved:
      "Bitte bestätigen Sie den Status Artikel reserviert",
    displayLocker: "Schließfach",
    confirmMoveOut:
      "Bitte bestätigen Sie die Entnahme des Artikels aus dem Schließfach",
    confirmMoveIn:
      "Bitte bestätigen Sie das Anlegen des Artikels und sein Einlegen in das Schließfach",
    contracts: "Pfandkarten",
    confirmContractSent:
      "Bitte bestätigen Sie, dass die Pfandkarte gesendet wurde",
    contractMessage: "Nachricht",
    allKPIs: "Allgemeine KPIs",
    detailledKPIs: "Detaillierte KPIs",
    alerts: "Warnungen",
    lockerActionSetFaulty:
      "Bitte bestätigen Sie die Fehlereinstellung und den Schließfachstatus",
    filterOrders: "Aufträge filtern",
    exportOrders: "Filtern und exportieren",
    displays: "Anzeigen",
    addDisplay: "Neue Anzeige",
    confirmRemove: "Bitte bestätigen Sie das Entfernen",
    resendMessage: "Nachricht erneut senden",
    terminals: "Terminals",
    addTerminal: "Neues Terminal",
    editTerminal: "Terminal bearbeiten",
    showLogsTerminal: "Logs des Terminals ansehen",
    statsPerNetwork: "Verteilung nach Netz",
    statsPerProduct: "Verteilung nach Flaschen",
    orderLogs: "Bestellungsprotokolle",
    lockerLogs: "Schließfachprotokolle",
    messageLogs: "Nachrichtenprotokolle",
    message: "Nachricht",
    networks: "Netzwerke",
    batteryHistory: "Verlauf des Batteriestands",
    displayReport: "Statusbericht anzeigen",
    lockerActionResetFaultCount:
      "Bitte bestätigen Sie die Anzahl der zurückgesetzten Schließfachfehler",

    terminalLogs: "Terminals logs",
  },

  button: {
    returnable: "Mehrweg",
    add: "Hinzufügen",
    dashboard: "Armaturenbrett",
    orders: "Bestellungen",
    stores: "Verkaufsstellen",
    products: "Produkte",
    displays: "Displays",
    cancel: "abbrechen",
    save: "speichern",
    signIn: "anmelden",
    logOut: "Ausloggen",
    myProfile: "Mein Profil",
    deleteDefinitively: "Definitiv löschen",
    manageProducts: "Produkte verwalten",
    manageTemplates: "Vorlagen verwalten",
    prices: "Preise",
    managePricing: "Preise verwalten",
    removeStoreProduct: "Aus der Verkaufsstelle entfernen",
    order: "Bestellung",
    config: "Konfiguration",
    logs: "Logs",
    bankReturn: "Rückkehr von der Bank",
    management: "Verwaltung",
    system: "System",
    users: "Benutzer",
    interventions: "Interventionen",
    log: "Log",
    documentation: "Dokumentation",
    complete: "Abschließen",
    settings: "Einstellungen",
    loadingPlan: "Ladeplan",
    codes: "Codes",
    visits: "Besuche",
    request: "Anfrage",
    comments: "Kommentare",
    reset: "Zurücksetzen",
    general: "Allgemein",
    templates: "Vorlagen",
    contracts: "Einzahlungskarten",
    contract: "Einzahlungskarte",
    supplierVisits: "Lieferantenbesuche",
    technicianVisits: "Besuche von Technikern",
    ok: "OK",
    lockers: "Schließfächer",
    confirm: "Bestätigen",
    messages: "Nachrichten",
    filter: "Filter",
    export: "Exportieren",
    manageDisplays: "Anzeigen verwalten",
    remove: "Entfernen",
    send: "Senden",
    terminals: "Terminals",
  },
  message: {
    confirmDeleteProduct:
      "Wollen Sie dieses Produkt wirklich endgültig löschen?",
    confirmDeleteStoreProduct:
      "Wollen Sie dieses Produkt wirklich endgültig aus dem Verkaufsstelle entfernen?",
    confirmDeleteStore:
      "Wollen Sie diesen Verkaufsstelle wirklich endgültig löschen?",
    confirmDeleteDisplay:
      "Wollen Sie diese Display wirklich endgültig löschen?",
    notFound404: "Diese Seite existiert nicht, oder ist in Entwicklung!",
    confirmDeleteUser:
      "Möchten Sie diesen Benutzer wirklich endgültig löschen?",
    supplierCode: "Ein Lieferantenkodex",
    technicianCode: "Der Code eines Ingenieurs vor Ort",
    urgencyCode: "Ein Notfallcode",
    copied: "Der Inhalt wurde in die Zwischenablage kopiert",
    confirmRemoveDisplay:
      "Wollen Sie das Display wirklich aus dem Laden entfernen?",
    confirmDeleteTerminal:
      "Wollen Sie dieses Terminal wirklich endgültig löschen?",
    confirmDeleteDoc: "Wollen Sie dieses Dokument wirklich endgültig löschen?",
  },
  hint: {
    number: "Nummer",
    required: "Erforderlich",
    minchar: "Mindestens {chars} Zeichen",
    emailFormat: "im E-Mail-Format",
    password: "Passwort",
    repeatPassword: "wie Passwort",
    requireLogin:
      "Sie werden aufgefordert, sich erneut mit den neuen Informationen zu verbinden, um die Änderungen zu bestätigen.",
    numberLength: "Zahl mit {chars} Ziffern",
    newCodeCommentRequired: "Kommentar ist erforderlich",
  },
  label: {
    location: "Standort",
    displayName: "Displayname",
    language: "Sprache",
    name: "Name",
    password: "Passwort",
    email: "Email",
    repeatPassword: "Bestätige das Passwort",
    weight: "Gewicht",
    position: "Position",
    image: "Bild",
    dragImage: "Ziehen Sie ein Bild hierher...",
    selectProducts: "Bitte wählen Sie Produkte",
    selectAction: "Bitte wählen Sie eine Aktion",
    selectTemplate: "Bitte wählen Sie eine Vorlage",
    charge: "Gefüllt",
    promo: "Promo",
    returnable: "Pfandflasche",
    selectPrices: "Bitte wählen Sie einen Preis",
    store: "Verkaufsstelle",
    orderProduct: "Bestelltes Produkt",
    returnProduct: "Zurückgegebenes Produkt",
    sameReturnable: "dieselbe Pfandflasche",
    phone: "Telefon",
    firstname: "Vorname",
    lastname: "Nachname",
    address: "Adresse",
    zip: "Postleitzahl",
    city: "Stadt",
    orderAmount: "Betrag bestellen",
    search: "Suche",
    date: "Datum",
    status: "Status",
    source: "Quelle",
    amount: "Betrag",
    actions: "Aktionen",
    transactionReference: "Referenz im Bankwesen",
    authorisationNumber: "Zulassungsnummer",
    transactionSource: "Art der Zahlung",
    transactionLog: "Log",
    delivered: "Geliefert",
    returned: "Zurückgeschickt",
    product: "Produkt",
    type: "Typ",
    available: "Verfügbar",
    deliveryDone: "Lieferung erfolg",
    returnDone: "Zurückgegeben erledigt",
    lockers: "Schließfächer",
    securityCode: "Sicherheitscode",
    contact: "Kontakt",
    return: "Rückkehr",
    active: "Aktiv",
    roles: "Rollen",
    description: "Beschreibung",
    template: "Vorlage",
    side: "Seite",
    faces: "Seiten",
    sides: "Seiten",
    rows: "Zeilen",
    columns: "Rubriken",
    role: { admin: "Verwalter", super: "Super Verwalter", user: "Benutzer" },
    selectProductAndAssign:
      "Bitte wählen Sie unten ein Produkt aus und klicken Sie dann auf Schließfächer, um es zuzuordnen",
    supplier: "Anbieter",
    technician: "Techniker",
    urgency: "Dringlichkeit",
    code: "Code",
    validFrom: "Gültig von",
    validUntil: "Gültig bis",
    lastUsedAt: "Zuletzt verwendet am",
    usageCount: "Anzahl der Nutzungen",
    terminal: "Terminal",
    action: "Aktion",
    content: "Inhalt",
    to: "Zu",
    log: "Log",
    order: "Bestellung",
    levelAlert: "Alarmstufe",
    suppliersEmail: "E-Mail-Adressen der Zulieferer",
    ticket: "Fahrschein",
    comments: "Kommentare",
    comment: "Kommentar",
    from: "Von",
    currency: "Währung",
    techniciansEmail: "E-Mails der Techniker",
    origin: "Herkunft",
    lastUpdate: "Letzte Aktualisierung",
    faulty: "Fehlerhaft",
    presence: "Anwesenheit",
    locked: "Gesperrt",
    reserved: "Reserviert",
    selectProductAndMoveIn:
      "Sie können unten ein Produkt auswählen und dann auf die Schaltfläche rechts klicken, um einen Artikel zu erstellen und ihn in das Schließfach zu verschieben.",
    dateSent: "Datum des Versands",
    created: "Erstellt",
    contractManagersEmail: "Einzahlungskarten Manager E-Mails",
    deployedStores: "Eingesetzte Filialen",
    revenuesSinceJan: "Umsätze seit dem 1. Januar",
    averageRevenue: "Durchschnittlicher Umsatz",
    volumeSinceJan: "Volumen seit dem 1. Januar",
    averageVolumeSinceJan: "Durchschnittliches Volumen seit dem 1. Januar",
    salesCB: "Käufe mit Kreditkarte",
    salesSC: "Kontaktlose Käufe",
    salesCoupon: "Einkäufe im Geschäft",
    openVisits: "Offene Besuche",
    lowBatteryDisplay: "Niedriger Batteriestand",
    doorFault: "Türstörung",
    lockFault: "Fehler am Schloss",
    presenceFault: "Fehler des Anwesenheitssensors",
    powerFault: "Stromfehler",
    timeoutFault: "Zeitüberschreitungsfehler",
    otherFault: "Sonstiges",
    all: "Alle",
    selection: "Auswahl",
    storeFilter: "Filialauswahl",
    selectStores: "Filialen auswählen",
    selectDates: "Datumsbereich auswählen",
    previousMonth: "Vormonat",
    currentMonth: "Aktueller Monat",
    selectStatus: "Status auswählen",
    battery: "Batterie",
    lastPolling: "Letzte Abfrage",
    lastSupply: "Letzte Versorgung",
    version: "Version",
    targetVersion: "Zielversion",
    traceability: "Rückverfolgbarkeit",
    selectDisplayToAdd:
      "Bitte klicken Sie auf ein Display, um es dem Speicher hinzuzufügen",
    addComment: "Einen Kommentar hinzufügen",
    serialNumber: "Seriennummer",
    geschclosedlossen: "Tür geschlossen",
    mismatchFault: "Nicht übereinstimmende Erkennung",
    salesReportEmail: "Tägliche E-Mails mit Verkaufsberichten",
    display: "Anzeige",
    locker: "Schließfach",
    network: "Netz",
    pendingContracts: "Zu versendende Pfandkarten",
    pending: "Ausstehende Aktionen",
    title: "Titel",
    colors: "Farben",
    batteryUpdated: "Batterie aktualisiert",
    outOfDateDisplays: "Keine Statusaktualisierung seit {Minuten} min",
    batteryLevel: "Batteriestand (mV)",
    faultCount: "Fehlerzahl",
    storeTimeout: "Warnzeit für die Verkaufsstelle (Minuten)",
    disabled: "Deaktiviert",
    disableLocker: "Schließfach deaktivieren",
    command: "Befeh",
  },
  type: {
    order: {
      label: "Art der Bestellung",
      same: "Gleiches Pfandflasche",
      other: "Anderes Pfandflasche",
      none: "Kein Pfandflasche",
      same_bottle: "Austausch",
      other_bottle: "Anderen Pfandflasche",
      new_bottle: "Neuer Pfandflasche",
    },
    code: {
      order: "Bestellung",
      supplier: "Anbieter",
      technician: "Techniker",
      urgency: "Dringlichkeit",
    },
    visit: {
      label: "Besuchstyp",
      supplier: "Lieferant",
      technical: "Technisch",
      technician: "Techniker",
    },
    locker: {
      technician_visit: "Technikerbesuch",
      supplier_visit: "Lieferantenbesuch",
      urgency_code: "Dringlichkeitscode",
      order_delivery: "Lieferung der Bestellung",
      order_return: "Mehrweg-Rückgabe",
      order_cancel: "Stornierung der Bestellung",
      bo_user: "Backoffice",
      system: "System",
    },
    transaction: {
      complete: "Complete",
      cancel: "Abbrechen",
      failure: "Fehlschlag",
    },
    message: {
      ORDER_EMAIL: "Bestellung E-Mail",
      ORDER_SMS: "Bestellung SMS",
      SUPPLIER_EMAIL: "Lieferant",
      TECHNICIAN_EMAIL: "Techniker",
      CONTRACT_EMAIL: "Einzahlungskarte",
      DAILY_REPORT_EMAIL: "Tagesbericht",
    },

    terminal: {
      actionSource: { 0: "Back Office", 1: "Terminal" },
    },
  },

  validation: {
    verify: "Bitte korrigieren Sie das Formular",
    required: "Dieses Feld darf nicht leer sein",
    minchar: "Mindestens {chars} Zeichen sind erforderlich",
    repeatPassword: "Passwörter müssen gleich sein",
    isEmail: "Ungültige E-Mail-Adresse",
    number: "Dieses Feld muss eine Zahl sein",
    positive: "Dieses Feld muss eine positive Zahl sein",
    numberlength: "Dieses Feld muss eine Zahl mit {chars} Ziffern sein",
  },
  tooltip: {
    deleteProduct: "Dieses Produkt auf jeden Fall löschen",
    editProduct: "Produkt bearbeiten",
    listProduct: "Alle Produkte",
    addProduct: "Neues Produkt hinzufügen",
    deleteStore: "Löschen Sie diesen Verkaufsstelle auf jeden Fall",
    editStore: "Verkaufsstelle bearbeiten",
    listStore: "Alle Verkaufsstelle",
    addStore: "Neues Verkaufsstelle hinzufügen",
    showStore: "Verkaufsstelle anzeigen",
    showPrices: "Preise anzeigen",
    editPrices: "Preise bearbeiten",
    showDisplay: "Display anzeigen",
    editDisplay: "Display bearbeiten",
    deleteDisplay: "Diese Display endgültig löschen",
    addOrder: "Simuliert einen neuen Auftrag",
    completeOrder: "Simuliert den Abschluss der Bestellung",
    showOrder: "Bestellung anzeigen",
    listOrder: "Liste der Bestellungen",
    editUser: "Benutzer bearbeiten",
    addUser: "Benutzer hinzufügen",
    deleteUser: "Endgültig Benutzer löschen",
    addCode: "Einen neuen Code erstellen",
    displayCodes: "Codes anzeigen",
    hideCodes: "Codes ausblenden",
    showCode: "Code-Details anzeigen",
    changeDateRange: "Gültigkeitsdaten ändern",
    addTemplate: "Neue Vorlage hinzufügen",
    showLog: "Logdetails anzeigen",
    addVisit: "Eine Besuchsanfrage erstellen",
    showVisit: "Besuch anzeigen",
    listVisit: "Alle Besuche anzeigen",
    completeVisit: "Besuch abschließen",
    selectVisitType: "Besuchsart für diese Anfrage auswählen (erforderlich)",
    batteryLevel: "Batteriestand",
    lastSupply: "Letzte Versorgung",
    displayTechnicianVisits: "Technikerbesuche anzeigen",
    hideTechnicianVisits: "Technikerbesuche ausblenden",
    displaySupplierVisits: "Besuche von Lieferanten anzeigen",
    hideSupplierVisits: "Lieferantenbesuche ausblenden",
    resetFaulty: "Fehlerstatus zurücksetzen",
    moveIn: "Artikel anlegen und in das Schließfach verschieben",
    moveOut: "Artikel aus dem Schließfach herausnehmen",
    confirmContractSent: "Bestätigung, dass die Pfandkarte versendet wurde",
    copyToClipboard: "In die Zwischenablage kopieren",
    setFaulty: "Schließfachfehler einrichten",
    exportOrder: "Filtern und in CSV-Datei exportieren",
    filterOrder: "Aufträge filtern",
    clearFilterOrder: "Filter löschen",
    addDisplay: "Eine neue Anzeige erstellen",
    showStatusReport: "Statusbericht anzeigen",
    removeDisplay: "Display aus dem Laden entfernen",
    resendMessage: "Senden Sie die Nachricht erneut mit Kommentaren",
    showMessages: "Nachrichten anzeigen",
    hideMessages: "Nachrichten ausblenden",
    addTerminal: "Ein neues Terminal erstellen",
    editTerminal: "Terminal bearbeiten",
    showLogsTerminal: "Logs des Terminals ansehen",
    deleteTerminal: "Terminal löschen",
    refresh: "Auffrischen",
    printDisplayReport: "Bericht drucken",
    resetFaultCount: "Anzahl von Fehlern. Klicken Sie zum Zurücksetzen...",
    showDeliveryReport: "Anzeige des Lieferscheins",
  },
  action: {
    new: "Erstellung",
    send_message: "Nachricht senden",
    complete: "Finalisierung",
    retry_locker: "Schließfach wechseln",
    create: "Erstellen",
    report_locker_fault: "Schließfachfehler melden",
    cancel: "Abbrechen",
    set_faulty: "Fehlerstatus setzen",
    reset_faulty: "Störungsstatus löschen",
    lock_open: "Schließfach entriegelt",
    lock_close: "Schließfach verschlossen",
    door_open: "Tür geöffnet",
    door_close: "Tür geschlossen",
    presence_detect: "Anwesenheitserkennung",
    presence_undetect: "Anwesenheit verloren",
    article_move_in: "Artikel einziehen",
    article_move_out: "Artikel ausziehen",
    clear_locker_fault: "Schließfachfehler löschen",
    validate_transaction: "Transaktion validieren",
    reset_fault_count: "Fehlerzahl zurücksetzen",
    disable: "Deaktivierung",
    update_terminal: "aktualisieren",
    execute_command: "Ausführung eines Befehls",
    create_terminal: "Erstellung",
    delete_terminal: "Unterdrückung",
  },

  api: {
    user: {
      profileUpdated: "Profil aktualisiert",
      updated: "Benutzer aktualisiert",
      created: "Benutzer erstellt",
      removed: "Benutzer gelöscht",
    },
    display: {
      updated: "Display aktualisiert",
      created: "Display erstellt",
      removed: "Display entfernt",
      itemAdded: "Artikel wurde hinzugefügt",
      itemRemoved: "Artikel gelöscht",
      lockerResetFaulty: "Schließfach-Fehlerstatus entfernt",
      articleResetReserved: "Reservierter Artikelstatus entfernt",
      displayOrLockerNotFound: "Display oder Schließfach nicht gefunden",
      lockerSetFaulty: "Schließfach wurde fehlerhaft gesetzt",
      added: "Display wurde zum Laden hinzugefügt",
      lockerNotFound: "Schließfach #{lockerId} nicht gefunden",
      deleted: "Anzeige wurde gelöscht",
      lockerResetFaultCount:
        "Die Anzahl der Schließfachfehler wurde zurückgesetzt",
    },
    price: {
      updated: "Preise aktualisiert",
      created: "Preise erstellt",
    },
    product: {
      updated: "Produkt aktualisiert",
      created: "Produkt erstellt",
      removed: "Produkt gelöscht",
      alertSaved: "Produktwarnstufe aktualisiert",
    },
    store: {
      created: "Verkaufsstelle erstellt",
      updated: "Verkaufsstelle aktualisiert",
      removed: "Verkaufsstelle gelöscht",
      productAdded: "Produkt wurde dem Verkaufsstelle hinzugefügt",
      productRemoved: "Das Produkt wurde aus dem Verkaufsstelle entfernt",
      notFound: "Verkaufsstelle nicht gefunden",
      networkCreated: "Das Netzwerk wurde erstellt",
      networkUpdated: "Das Netzwerk wurde aktualisiert",
      networkRemoved: "Netzwerk wurde entfernt",
    },
    order: {
      updated: "Bestellung aktualisiert",
      created: "Bestellung erstellt",
      removed: "Bestellung gelöscht",
      complete: "Bestellung abgeschlossen",
      alreadyClosed: "Auftrag ist bereits abgeschlossen",
      notFound: "Der Befehl #{orderId} wurde nicht gefunden",
      retryEmptyLockerNotFound: "Kein leeres Notfallfach",
      retryLockerNotFound: "Kein Notfallprodukt",
    },
    visit: {
      updated: "Besuch aktualisiert",
      created: "Besuch erstellt",
      removed: "Bestellung gelöscht",
      complete: "Bestellung abgeschlossen",
      messageSent: "Nachricht wurde gesendet",
    },
    template: {
      updated: "Vorlage aktualisiert",
      created: "Vorlage erstellt",
      removed: "Vorlage gelöscht",
    },
    code: {
      updated: "Code aktualisiert",
      created: "Code erstellt",
      removed: "Code gelöscht",
    },
    param: {
      updated: "Parameter wurde aktualisiert",
      created: "Parameter wurde erstellt",
    },
    login: { failure: "Ungültige E-Mail oder Passwort" },
    contract: { sent: "Bestätigung aufgenommen" },
    terminal: {
      updated: "Das Terminal wurde aktualisiert",
      created: "Terminal wurde erstellt",
      removed: "Terminal wurde entfernt",
    },
    doc: {
      updated: "Die Dokumentation wurde aktualisiert",
      created: "Die Dokumentation wurde erstellt",
      removed: "Die Dokumentation wurde entfernt",
      notFound: "Dokumentation nicht gefunden",
    },
  },
};
