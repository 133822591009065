import Api from "./Api";
export default {
  findByStoreId(storeId) {
    return Api().get("/order/store/" + storeId);
  },
  paginate(paginate) {
    return Api().post("/order/page", paginate);
  },
  paginateLocker(lockerId, paginate) {
    return Api().post(`/order/${lockerId}/lockerpage`, paginate);
  },
  findAll() {
    return Api().get("/order");
  },
  findOne(id) {
    return Api().get(`/order/${id}`);
  },
  save(id, form) {
    return Api().patch("/order/" + id, form);
  },
  create(form) {
    return Api().post("/order", form);
  },
  complete(form) {
    return Api().post("/order/complete", form);
  },
  cancel(id, form) {
    return Api().post("/order/" + id + "/cancel", form);
  },
  delete(id) {
    return Api().delete(`/order/${id}`);
  },
  getMessages(id) {
    return Api().get(`/order/${id}/message`);
  },
  csvExport(filter) {
    return Api().post(`/order/csv`, filter, {responseType: 'arraybuffer'});
  },
};
