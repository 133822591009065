<template>
  <q-toolbar class="text-green-vito bg-grey-1 q-mb-sm q-pl-none shadow-1">
    <q-btn-dropdown
      stretch
      flat
      :label="$t('button.' + type + 'Visits')"
      dropdown-icon="more_vert"
      menu-anchor="bottom left"
      menu-self="top left"
      icon-left
    >
    </q-btn-dropdown>
    <q-space />
    <q-btn
      ref="addButton"
      dense
      round
      color="primary"
      @click="showNew = true"
      icon="add"
      class="q-mr-md"
    >
      <q-tooltip class="bg-green-vito text-no-wrap">
        {{ $t("tooltip.addVisit") }}
      </q-tooltip>
    </q-btn>
    <q-input
      outlined
      dense
      clearable
      debounce="300"
      v-model="filter"
      :placeholder="$t('label.search')"
    >
      <template v-slot:append>
        <q-icon name="search" />
      </template>
    </q-input>
  </q-toolbar>

  <visit-pages
    :store="store"
    :filter="filter"
    :type="type"
    :key="refreshCounter"
  />

  <q-dialog v-model="showNew" ref="newCodeDialog">
    <new-visit @refresh="refresh" :store="store" :type="type" />
  </q-dialog>
</template>

<script>
import VisitPages from "../visit/VisitPages.vue";
import NewVisit from "../visit/NewVisit.vue";

export default {
  emits: ["refresh"],
  components: { VisitPages, NewVisit },

  props: ["store", "type"],

  data: () => {
    return {
      showNew: false,
      refreshCounter: 0,
      filter: null,
    };
  },

  methods: {
    async refresh() {
      this.showNew = false;
      this.$emit("refresh");
    },
  },

  async mounted() {},

  computed: {},
};
</script>
