<template>
  <q-table
    :rows="rows"
    :columns="columnsI18n"
    row-key="id"
    v-model:pagination="pagination"
    :loading="loading"
    :filter="filter"
    @request="onRequest"
    binary-state-sort
    class="sticky-header-table"
    :class="store ? 'size-md' : ''"
  >
    <template v-slot:body-cell-date="props">
      <q-td :props="props">
        <div>
          {{ $moment(props.value).format("DD/MM/YYYY HH:mm") }}
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-type="props">
      <q-td :props="props">
        <q-badge>{{ $t("type.visit." + props.value) }}</q-badge>
      </q-td>
    </template>

    <template v-slot:body-cell-status="props">
      <q-td :props="props">
        <q-badge :color="statusColor(props.value)">{{
          $t("status." + props.value)
        }}</q-badge>
      </q-td>
    </template>

    <template v-slot:body-cell-actions="props">
      <q-td :props="props">
        <div>
          <q-btn
            icon="visibility"
            square
            round
            dense
            @click="displayVisit(props.key)"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.showVisit") }}
            </q-tooltip>
          </q-btn>
        </div>
      </q-td>
    </template>
  </q-table>
</template>
<script>
import VisitApi from "../../apis/Visit";
import { VisitStatus } from "./visit.enum";
export default {
  props: ["store", "filter", "type"],

  data: () => {
    return {
      loading: false,
      pagination: {
        sortBy: "id",
        descending: true,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 0,
      },
      rows: [],
    };
  },
  methods: {
    async onRequest(props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const filter = props.filter;
      this.loading = true;

      try {
        const startRow = (page - 1) * rowsPerPage;
        /* console.log({
          startRow,
          fetchCount: rowsPerPage,
          filter,
          sortBy,
          descending,
          store: this.store ? this.store.id : null,
          type: this.type,
        }); */
        const pageReponse = await VisitApi.paginate({
          startRow,
          fetchCount: rowsPerPage,
          filter,
          sortBy,
          descending,
          store: this.store ? this.store.id : null,
          type: this.type,
        });

        this.rows = pageReponse.data.items;
        this.pagination.rowsNumber = pageReponse.data.count;
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async refresh() {
      this.onRequest({
        pagination: this.pagination,
        filter: this.filter,
      });
      this.showNew = false;
    },
    displayVisit(visitId) {
      this.$router.push("/visit/" + visitId);
    },
    statusColor(status) {
      return VisitStatus[status] ? VisitStatus[status].color : "primary";
    },
  },

  async mounted() {
    this.onRequest({
      pagination: this.pagination,
      filter: this.filter,
    });
  },

  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "left",
          field: "id",
          sortable: true,
        },
        {
          name: "status",
          label: this.$t("label.status"),
          field: "status",
          align: "left",
          sortable: true,
        },
        {
          name: "date",
          align: "left",
          label: this.$t("label.date"),
          field: "date",
          sortable: true,
        },
        {
          name: "store",
          label: this.$t("label.store"),
          field: "store",
          align: "left",
          sortable: false,
        },
        {
          name: "type",
          label: this.$t("label.type"),
          field: "type",
          align: "left",
          sortable: false,
        },
        {
          name: "ticket",
          label: this.$t("label.ticket"),
          field: "ticket",
          align: "left",
          sortable: true,
        },
        {
          name: "code",
          label: this.$t("label.code"),
          field: "code",
          align: "left",
          sortable: false,
        },
        {
          name: "source",
          label: this.$t("label.source"),
          field: "source",
          align: "left",

          sortable: true,
        },
        { name: "actions", style: "width:70px" },
      ];
      if (this.store && this.type) {
        return columns.filter((c) => c.name != "store" && c.name != "type");
      }
      return columns;
    },
  },
};
</script>
