import Api from "./Api";
export default {
  login(form) {
    return Api().post("/auth/login", form);
  },
  profile() {
    return Api().get("/auth/profile");
  },
  resetPassword(id, pass) {
    return Api().patch("/users/" + id, pass);
  },
};
