<template>
  <q-markup-table flat>
    <tr>
      <td>{{ $t("label.date") }}</td>
      <td class="highlight-values">
        <span>{{ $moment(message.date).format("DD/MM/Y HH:mm:ss") }}</span>
      </td>
    </tr>
    <tr>
      <td>{{ $t("label.type") }}</td>
      <td class="highlight-values">
        <q-chip
          square
          text-color="green-vito"
          :icon="typeIcon(message.type)"
          class="q-ma-none"
        >
          {{ typeValue(message.type) }}
        </q-chip>
      </td>
    </tr>

    <tr>
      <td>{{ $t("label.to") }}</td>
      <td class="highlight-values">
        <span>{{ messageTo }}</span>
      </td>
    </tr>

    <tr>
      <td>{{ $t("label.status") }}</td>
      <td class="highlight-values">
        <span>{{ status }}</span>
      </td>
    </tr>
    <tr>
      <td>{{ $t("label.content") }}</td>
      <td>
        <span v-html="nl2br(message.content, true, true)"></span>
      </td>
    </tr>
  </q-markup-table>
</template>
<script>
import {
  MessageType,
  ReversedMessageStatus,
  ReversedMessageType,
} from "./message.enum";
export default {
  props: ["message"],
  mounted() {
    //console.log(this.message);
  },
  methods: {
    nl2br(str, replaceMode, isXhtml) {
      var breakTag = isXhtml ? "<br />" : "<br>";
      var replaceStr = replaceMode ? "$1" + breakTag : "$1" + breakTag + "$2";
      return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
    },

    typeIcon(type) {
      return ReversedMessageType[type]
        ? MessageType[ReversedMessageType[type]].icon
        : "question_mark";
    },

    typeValue(type) {
      return this.$t("type.message." + ReversedMessageType[type]);
    },
  },
  computed: {
    messageTo() {
      return this.message.type == MessageType.ORDER_SMS.value
        ? this.message.contactPhone
        : this.message.contactEmail;
    },
    status() {
      return this.$t(
        "status." + ReversedMessageStatus[this.message.status].toLowerCase()
      );
    },
  },
};
</script>
