<template>
  <div class="text-h1">404</div>
  <div class="text-h3 light-dimmed">
    {{ $t("message.notFound404") }}
  </div>
</template>

<script>
export default {};
</script>
