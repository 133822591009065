export const BatteryIcon = {
  13: { icon: "battery_alert", color: "red-vito" },
  25: { icon: "battery_alert", color: "red-vito" },
  38: { icon: "battery_alert", color: "red-vito" },
  50: { icon: "battery_alert", color: "orange-10" },
  63: { icon: "battery_alert", color: "orange-10" },
  75: { icon: "battery_alert", color: "orange-10" },
  88: { icon: "battery_full", color: "green-vito" },
  100: { icon: "battery_full", color: "green-vito" },
};

export const statusUpdateTimeout = 15; // minutes
