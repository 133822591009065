<template>
  <div class="flex full-width reverse-wrap no-wrap justify-between bg-white">
    <q-card flat class="q-pa-sm" style="max-width: 100vw">
      <q-card-section class="text-green-vito justify-between no-padding-md">
        <div class="text-h5">{{ $t("title.allKPIs") }}</div>
      </q-card-section>
      <q-separator />
      <q-card-section horizontal>
        <div class="row justify-between">
          <q-markup-table
            v-if="statsLoaded"
            flat
            dense
            :wrap-cells="false"
            class="q-mt-md"
            style="width: 500px; max-width: 100vw"
          >
            <tr>
              <td>{{ $t("label.deployedStores") }}</td>
              <td>{{ stats.deployedStores }}</td>
              <td></td>
            </tr>

            <tr>
              <td>{{ $t("label.revenuesSinceJan") }}</td>
              <td :title="stats.revenuesSinceJan + ' ' + currency">
                {{
                  parseFloat(
                    Math.ceil(stats.revenuesSinceJan / 100) / 10
                  ).toLocaleString(locale, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                k
                {{ currency }}
              </td>
              <td></td>
            </tr>

            <tr>
              <td>{{ $t("label.averageRevenue") }}</td>
              <td :title="stats.averageRevenue + ' ' + currency">
                {{
                  parseFloat(
                    Math.ceil(stats.averageRevenue / 100) / 10
                  ).toLocaleString(locale, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                k
                {{ currency }}
              </td>
              <td></td>
            </tr>

            <tr>
              <td>{{ $t("label.volumeSinceJan") }}</td>
              <td :title="stats.volumeSinceJan + ' kg'">
                {{
                  parseFloat(
                    Math.ceil(stats.volumeSinceJan / 100) / 10
                  ).toLocaleString(locale, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                T
              </td>
              <td></td>
            </tr>

            <tr>
              <td>{{ $t("label.averageVolumeSinceJan") }}</td>
              <td :title="stats.averageVolumeSinceJan + ' kg'">
                {{
                  parseFloat(
                    Math.ceil(stats.averageVolumeSinceJan / 100) / 10
                  ).toLocaleString(locale, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                T
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="3"><q-separator /></td>
            </tr>
            <tr>
              <td>{{ $t("label.salesCB") }}</td>
              <td>
                {{
                  Math.round(
                    (stats.sourceSinceJan.cb / stats.sourceSinceJan.total) * 100
                  )
                }}
                %
              </td>
              <td></td>
            </tr>

            <tr>
              <td>{{ $t("label.salesSC") }}</td>
              <td>
                {{
                  Math.round(
                    (stats.sourceSinceJan.contactless /
                      stats.sourceSinceJan.total) *
                      100
                  )
                }}
                %
              </td>
              <td></td>
            </tr>

            <tr>
              <td>{{ $t("label.salesCoupon") }}</td>
              <td>
                {{
                  Math.round(
                    (stats.sourceSinceJan.coupon / stats.sourceSinceJan.total) *
                      100
                  )
                }}
                %
              </td>
              <td></td>
            </tr>
          </q-markup-table>
        </div>
      </q-card-section>
    </q-card>

    <div class="q-pa-md no-padding-md" style="max-width: 100vw">
      <alerts
        :alerts="alertsPayload()"
        @refresh="refresh"
        :key="refreshCounter"
      />
    </div>
  </div>
  <q-card flat>
    <q-card-section
      class="text-green-vito justify-between no-padding-md q-ml-sm"
      style="max-width: 100vw"
    >
      <div class="text-h5">{{ $t("title.detailledKPIs") }}</div>
    </q-card-section>

    <q-separator class="q-ma-sm" />

    <q-card-section
      class="row text-green-vito justify-around wrap no-padding-md"
    >
      <stats-by-network class="col-md-6 col-12" />
      <stats-by-product class="col-md-6 col-12" />
    </q-card-section>
  </q-card>
</template>

<script>
import StatsApi from "../apis/Stats";
import Alerts from "../components/Alerts.vue";
import StatsByNetwork from "../components/StatsByNetwork.vue";
import StatsByProduct from "../components/StatsByProduct.vue";
import { useI18n } from "vue-i18n";
export default {
  components: { Alerts, StatsByNetwork, StatsByProduct },
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      stats: { deployedStores: 0, displayAlerts: [] },
      statsLoaded: false,
      refreshCounter: 0,
    };
  },
  mounted() {
    this.$store.state.title = "button.dashboard";
    this.locale = localStorage.getItem("language");
    this.getStats();
  },
  methods: {
    async getStats() {
      try {
        const statsResponse = await StatsApi.findAll();
        this.stats = statsResponse.data;
        this.statsLoaded = true;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    alertsPayload() {
      return {
        openVisits: this.stats.visitAlerts,
        lowBatteryDisplays: this.stats.displayAlerts.batteryAlerts,
        outOfDateDisplays: this.stats.displayAlerts.outOfDateAlerts,
        pendingContracts: this.stats.pendingContracts,
        storeTimeout: this.stats.displayAlerts.storeTimeout,
      };
    },
    async refresh() {
      console.log("refreshing");
      this.refreshCounter += 1;
      await this.getStats();
    },
  },
  computed: {
    currency() {
      return this.$store.getters.currency;
    },
  },
};
</script>
