<template>
  <q-card
    bordered
    class="q-pa-sm q-mb-sm no-padding-md"
    style="max-width: 100vw"
    v-if="displayId"
  >
    <q-card-section class="row q-pa-sm text-green-vito justify-between">
      <div class="col-grow">
        <div class="text-h5 text-green-vito">
          {{ $t("title.displayReport") }}
        </div>
      </div>

      <div class="row q-gutter-x-sm no-wrap">
        <q-btn
          icon="print"
          text-color="green-vito"
          round
          dense
          v-print="printObj"
          flat
          @click="handlePrint"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.printDisplayReport") }}
          </q-tooltip>
        </q-btn>
        <q-btn icon="close" flat round dense v-close-popup />
      </div>
    </q-card-section>
    <div id="printMe">
      <q-card-section v-if="report" class="q-gutter-x-md highlight">
        <span>
          {{ $t("label.date") }}:
          {{ $moment(report.date).format("DD/MM/Y") }}
        </span>
        <span v-if="report.store">
          {{ $t("label.store") }}: {{ report.store.name }} ({{
            report.store.id
          }})
        </span>
        <span>
          {{ $t("label.display") }}: {{ report.display.name }} ({{
            report.display.id
          }})
        </span>
        <span>
          {{ $t("tooltip.batteryLevel") }}:
          {{ getLevel(report.display.batteryLevel) }}% -
          {{ report.display.batteryLevel }} mV ({{
            $moment(report.display.batteryUpdated).format("DD/MM/Y HH:mm")
          }})
        </span>
      </q-card-section>

      <q-card-section v-if="report">
        <q-markup-table dense separator="cell" class="striped text-center">
          <thead>
            <tr class="bg-green-vito-light">
              <th class="text-center">{{ $t("label.locker") }}</th>
              <th
                v-for="(action, idx) in Object.keys(report.lockers[0].status)"
                :key="idx"
              >
                {{ $t("action." + action) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="locker in report.lockers" :key="locker.id">
              <td>{{ locker.name }}</td>
              <td
                v-for="(action, idx) in Object.keys(locker.status)"
                :key="idx"
              >
                <div v-if="locker.status[action]" class="text-center">
                  <q-badge
                    :color="
                      locker.status[action] == 'OK' ? 'green-vito' : 'red-vito'
                    "
                  >
                    {{ locker.status[action] }}
                  </q-badge>
                </div>
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
    </div>

    <div v-if="loading" class="text-center">
      <q-spinner-hourglass color="green-vito" size="4em" />
    </div>
  </q-card>
</template>

<script>
import DisplayApi from "../../apis/Display";

import print from "vue3-print-nb";

export default {
  directives: {
    print,
  },
  props: ["displayId"],
  data: () => {
    return {
      report: null,
      printObj: {
        id: "printMe",
      },
      loading: true,
    };
  },
  async mounted() {
    if (this.displayId) {
      await this.getDisplayReport();
      this.printObj.popTitle = this.$t("title.displayReport");
      this.loading = false;
    }
  },
  methods: {
    getLevel(battery) {
      const min = 10000;
      const max = 12000;
      if (battery <= min) return 0;
      if (battery >= max) return 100;
      return Math.ceil(((battery - min) * 100) / (max - min));
    },

    async getDisplayReport() {
      try {
        const displayResponse = await DisplayApi.findDeliveryReport(
          this.displayId
        );
        this.report = displayResponse.data;
        //console.log(this.form);
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async handlePrint() {
      console.log("printing", this.displayId);
    },
  },
  computed: {},
};
</script>
