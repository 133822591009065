import Api from "./Api";
export default {
  findAll() {
    return Api().get("/param");
  },
  findOne(id) {
    return Api().get(`/param/${id}`);
  },
  save(form) {
    return Api().patch("/param", form);
  }
};
