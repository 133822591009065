<template>
  <div v-if="action == 'create'">
    <div class="text-h5 text-green-vito full-width q-pa-lg">
      {{ $t("title.addStore") }}
    </div>
    <div class="row full-width q-gutter-md">
      <store-card
        :storeId="0"
        action="create"
        @cancel="cancel"
        @refresh="refresh"
        @displayOne="action = 'display'"
      />
    </div>
  </div>
  <div v-if="action == 'edit'" class="row full-width">
    <store-card
      :storeId="storeId"
      action="edit"
      @display="handleDisplay"
      @cancel="cancel"
      @refresh="refresh"
      :key="refreshCounter"
    />
  </div>

  <div class="row full-width" v-if="action == 'display'">
    <store-card
      :storeId="storeId"
      action="display"
      @edit="handleEdit"
      @refresh="refresh"
    />
  </div>
</template>
<script>
import StoreCard from "../components/store/StoreCard.vue";
export default {
  name: "OrderPage",
  components: { StoreCard },

  data: () => {
    return {
      storeId: "",
      refreshCounter: 0,
      action: "display",
      newStore: {
        id: 0,
        name: "",
        location: "",
        isActive: true,
      },
      store: {},
    };
  },
  mounted() {
    this.$store.state.title = "button.stores";
    this.storeId = this.$route.params.id;
  },
  methods: {
    async cancel() {
      this.action = "list";
      if (this.$refs.addButton) this.$refs.addButton.hide();
    },
    async refresh() {
      this.action = "display";
      this.refreshCounter += 1;
    },

    handleEdit() {
      this.action = "edit";
    },
    async handleDisplay() {
      this.action = "display";
    },
  },
};
</script>
