export const VisitStatus = {
  complete: { color: "green-vito" },
  message_sent: { color: "info" },
  new: { color: "primary" },
  error: { color: "red-vito" },
  locker_fault_reported: { color: "purple-10" },
  locker_fault_cleared: { color: "purple-10" },
  locker_opened: { color: "purple-10" },
};

export const VisitAction = {
  complete: { color: "green-vito" },
  send_message: { color: "info" },
  new: { color: "primary" },
  cancel: { color: "warning" },
  report_locker_fault: { color: "purple-10" },
  clear_locker_fault: { color: "purple-10" },
  open_locker: { color: "purple-10" },
};

export const VisitType = {
  SUPPLIER: "supplier",
  TECHNICIAN: "technician",
};
