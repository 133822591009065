<template>
  <q-select
    v-if="type == 'select'"
    v-model="selectedItem"
    :options="options"
    :label="$t('label.product')"
    filled
    borderless
    square
    map-options
    ref="newProduct"
    style="min-width: 200px"
    @update:model-value="$emit('productSelectionChange', { selectedItem })"
  >
    <template v-slot:selected>
      <q-item>
        <q-item-section avatar>
          <q-img :src="productImage(selectedItem)" style="width: 40px" />
        </q-item-section>
        <q-item-section>
          <q-item-label>
            {{ selectedItem.name }} {{ selectedItem.weight }}
            {{ selectedItem.id ? "kg" : "" }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps">
        <q-item-section avatar>
          <q-img :src="productImage(scope.opt)" style="width: 50px" />
        </q-item-section>
        <q-item-section>
          <q-item-label
            >{{ scope.opt.name }} {{ scope.opt.weight }} kg</q-item-label
          >
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:prepend>
      <q-icon name="settings" />
    </template>
  </q-select>
  <q-list bordered v-if="type == 'list'">
    <q-item
      clickable
      v-ripple
      v-for="item in options"
      :key="item.id"
      active-class="bg-green-vito-light"
      :active="isActive(item)"
      @click="setCurrentProduct(item)"
      class="text-center"
    >
      <q-item-section avatar>
        <q-img :src="productImage(item)" style="width: 50px" />
      </q-item-section>
      <q-item-section>
        <q-item-label>{{ item.name }} {{ item.weight }} kg</q-item-label>
      </q-item-section>
    </q-item>
  </q-list>
</template>
s

<script>
export default {
  emits: ["productSelectionChange"],
  props: ["options", "selectedProduct", "type"],
  data: () => {
    return { selectedItem: {} };
  },
  mounted() {
    //console.log(this.selectedProduct);
    this.selectedItem = this.selectedProduct;
  },
  computed: {},
  methods: {
    isActive(product) {
      return this.selectedItem.id == product.id;
    },
    setCurrentProduct(product) {
      this.selectedItem = product;
      this.$emit("productSelectionChange", this.selectedItem);
      //console.log(product);
    },
    productImage(product) {
      if (product && product.image)
        return (
          process.env.VUE_APP_BASE_URL + "/product/images/" + product.image
        );
    },
  },
};
</script>
