import Api from "./Api";
export default {
  findAll() {
    return Api().get("/stats");
  },
  getChartPerNetwork(filter) {
    return Api().post("/stats/store", filter);
  },
  getChartPerProduct(filter) {
    return Api().post("/stats/product", filter);
  },
};
