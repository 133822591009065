<template>
  <q-fab
    ref="addButton"
    color="primary"
    @click="action = action == 'list' ? 'create' : 'list'"
    icon="add"
    class="absolute add-product"
    style="top: 0; right: 20px; transform: translateY(25%)"
    :disable="action == 'edit'"
  >
    <q-tooltip class="bg-green-vito text-no-wrap" target=".add-product">
      {{ $t("tooltip.addProduct") }}
    </q-tooltip>
  </q-fab>
  <div v-if="action == 'create'">
    <div class="text-h5 text-green-vito full-width q-pa-lg">
      {{ $t("title.addProduct") }}
    </div>
    <div class="row full-width q-gutter-md">
      <product-card
        :product="newProduct"
        action="create"
        @cancel="cancel"
        @refreshAll="refreshAll"
      />
    </div>
  </div>
  <div v-if="action == 'edit'">
    <div class="text-h5 text-green-vito full-width q-pa-lg">
      {{ $t("title.editProduct") }}
    </div>
    <div class="row full-width q-gutter-md">
      <product-card
        :product="product"
        action="edit"
        @cancel="cancel"
        @refreshAll="refreshAll"
        @saveImage="saveImage"
        :key="refreshCounter"
      />
    </div>
  </div>
  <div v-if="action == 'list'">
    <div class="text-h5 text-green-vito full-width q-pa-lg">
      {{ $t("title.listProducts") }} ({{ products.length }})
    </div>
    <div class="row full-width q-gutter-md q-mb-md">
      <product-card
        v-for="product in products"
        :key="product.id"
        :product="product"
        action="display"
        @edit="handleEdit"
        @refreshAll="refreshAll"
      />
    </div>
  </div>
</template>
<script>
import ProductCard from "../components/product/ProductCard.vue";
import ProductApi from "../apis/Product";
export default {
  name: "ProductsPage",
  components: { ProductCard },

  data: () => {
    return {
      refreshCounter: 0,
      action: "list",
      newProduct: {
        id: 0,
        image: "EmptyImage.png",
        weight: 0,
        name: "",
        isActive: true,
      },
      product: {},
      products: [],
    };
  },
  async mounted() {
    this.$store.state.title = "button.products";
    this.getAllProducts();
  },
  methods: {
    async cancel() {
      await this.getAllProducts();
      this.action = "list";
      this.$refs.addButton.hide();
    },
    async refreshAll() {
      await this.getAllProducts();
      this.action = "list";
      this.refreshCounter += 1;
      this.$refs.addButton.hide();
    },
    async getAllProducts() {
      try {
        const productsResponse = await ProductApi.findAll();
        this.products = productsResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    handleEdit(id) {
      this.product = this.products.find((p) => p.id == id);
      this.action = "edit";
    },
    async saveImage(payload) {
      try {
        await ProductApi.save({
          id: payload.productId,
          image: payload.image,
        });
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
  },
};
</script>
