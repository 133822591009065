import Api from "./Api";
export default {
  paginate(paginate) {
    return Api().post("/tag/page", paginate);
  },

  reset(tagId) {
    return Api().post(`tag/${tagId}/reset`)
  },

  create(storeId, productId, tags) {
    return Api().post("/tag", {storeId: storeId, productId: productId, tags: tags})
  }
};
