import Api from "./Api";
export default {
  storePaginate(paginate) {
    return Api().post("/code/storepage", paginate);
  },
  findAll() {
    return Api().get("/code");
  },

  findOne(id) {
    return Api().get(`/code/${id}`);
  },

  save(id, form) {
    return Api().patch("/code/" + id, form);
  },

  create(form) {
    return Api().post("/code", form);
  },

  delete(id) {
    return Api().delete(`/code/${id}`);
  },
};
