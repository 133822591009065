<template>
  <q-table
    :rows="rows"
    :columns="columnsI18n"
    row-key="id"
    v-model:pagination="pagination"
    :loading="loading"
    :filter="filter"
    @request="onRequest"
    binary-state-sort
    class="sticky-header-table size-lg"
  >
    <template v-slot:body-cell-store="props">
      <q-td :props="props">
        <div v-if="props.value">
          <router-link
            :to="'/store/' + props.value.id"
            class="vito-link text-bold"
          >
            {{ props.value.name }}
          </router-link>
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-actions="props">
      <q-td :props="props">
        <div class="q-gutter-x-sm">
          <q-btn
            icon="edit"
            text-color="green-vito"
            square
            round
            dense
            @click="displayTerminal(props.row)"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.editTerminal") }}
            </q-tooltip>
          </q-btn>
          <q-btn
            icon="folder"
            text-color="green-vito"
            square
            round
            dense
            @click="displayLogsTerminal(props.row)"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.showLogsTerminal") }}
            </q-tooltip>
          </q-btn>
        </div>
      </q-td>
    </template>
  </q-table>

  <q-dialog v-model="showCard" ref="editTerminalDialog">
    <terminal-card @refresh="refresh" :terminalId="selectedTerminalId" />
  </q-dialog>
  <q-dialog v-model="showLogsCard" ref="showLogsTerminalDialog">
    <terminal-logs-card @refresh="refresh" :terminalId="selectedTerminalId" />
  </q-dialog>
</template>
<script>
import TerminalApi from "../../apis/Terminal";
import TerminalCard from "./TerminalCard.vue";
import TerminalLogsCard from "./TerminalLogsCard.vue";
//import { TerminalStatus } from "./terminal.enum";
export default {
  props: ["filter"],
  emits: ["refresh"],
  components: { TerminalCard, TerminalLogsCard },

  data: () => {
    return {
      showCard: false,
      showLogsCard: false,
      selectedTerminalId: null,
      loading: false,
      pagination: {
        sortBy: "id",
        descending: false,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 0,
      },
      rows: [],
    };
  },
  methods: {
    async onRequest(props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const filter = props.filter;
      this.loading = true;

      try {
        const startRow = (page - 1) * rowsPerPage;

        const pageReponse = await TerminalApi.paginate({
          startRow,
          fetchCount: rowsPerPage,
          filter,
          sortBy,
          descending,
        });

        this.rows = pageReponse.data.items;
        this.pagination.rowsNumber = pageReponse.data.count;
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async refresh() {
      this.showCard = false;
      this.showLogsCard = false;
      this.onRequest({
        pagination: this.pagination,
        filter: this.filter,
      });
    },
    displayTerminal(terminal) {
      this.selectedTerminalId = terminal.id;
      this.showCard = true;
    },
    displayLogsTerminal(terminal) {
      this.selectedTerminalId = terminal.id;
      this.showLogsCard = true;
    },
  },

  async mounted() {
    this.$store.state.title = "button.terminals";
    this.onRequest({
      pagination: this.pagination,
      filter: this.filter,
    });
  },

  computed: {
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "left",
          field: "id",
          sortable: true,
        },
        {
          name: "name",
          label: this.$t("label.name"),
          field: "name",
          align: "left",
          sortable: true,
        },
        {
          name: "serialNumber",
          label: this.$t("label.serialNumber"),
          field: "serialNumber",
          align: "left",
          sortable: true,
        },
        {
          name: "version",
          label: this.$t("label.version"),
          field: "version",
          align: "left",
          sortable: false,
        },
        {
          name: "store",
          label: this.$t("label.store"),
          field: "store",
          align: "left",
          sortable: false,
        },
        {
          name: "command",
          label: this.$t("label.command"),
          field: "command",
          align: "left",
          sortable: true,
        },
        { name: "actions", style: "width:70px" },
      ];
      return columns;
    },
  },
};
</script>
