import Api from "./Api";
export default {
  findAll() {
    return Api().get("/template");
  },
  findOne(id) {
    return Api().get(`/template/${id}`);
  },
  save(form) {
    return Api().patch("/template/" + form.id, form);
  },
  saveLoadingPlan(id, form) {
    return Api().patch(`/template/${id}/plan/`, form);
  },
  create(form) {
    return Api().post("/template", form);
  },
  delete(id) {
    return Api().delete(`/template/${id}`);
  },
};
