<template>
  <q-card
    flat
    class="q-pa-sm full-width no-padding-md"
    style="max-width: 100vw"
  >
    <q-card-section class="row q-pb-xs text-green-vito justify-between">
      <div class="text-h5">
        {{ $t("title.orderLogs") }}
      </div>
      <div class="q-gutter-x-md row">
        <q-input
          outlined
          dense
          clearable
          debounce="300"
          v-model="filter"
          style="width: 120px"
          :placeholder="$t('label.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </q-card-section>

    <q-card-section>
      <q-table
        :rows="rows"
        :columns="columnsI18n"
        row-key="id"
        v-model:pagination="pagination"
        :loading="loading"
        :filter="filter"
        @request="onRequest"
        binary-state-sort
        class="sticky-header-table size-lg"
      >
        <template v-slot:body-cell-date="props">
          <q-td :props="props">
            <div>
              {{ $moment(props.value).format("DD/MM/YYYY HH:mm") }}
            </div>
          </q-td>
        </template>

        <template v-slot:body-cell-status="props">
          <q-td :props="props">
            <q-badge :color="statusColor(props.value)">{{
              statusValue(props.value)
            }}</q-badge>
          </q-td>
        </template>

        <template v-slot:body-cell-action="props">
          <q-td :props="props">
            <q-badge :color="actionColor(props.value)">{{
              $t("action." + props.value)
            }}</q-badge>
          </q-td>
        </template>

        <template v-slot:body-cell-log="props">
          <q-td :props="props">
            <div class="ellipsis" style="max-width: 200px">
              {{ props.value }}
            </div>
          </q-td>
        </template>

        <template v-slot:body-cell-orderId="props">
          <q-td :props="props">
            <router-link
              :to="'/order/' + props.value"
              class="vito-link text-bold"
            >
              <span>{{ props.value }}</span>
            </router-link>
          </q-td>
        </template>

        <template v-slot:body-cell-orderType="props">
          <q-td :props="props">
            <q-badge :color="typeColor(props.value)">{{
              $t("type.order." + props.value)
            }}</q-badge>
          </q-td>
        </template>

        <template v-slot:body-cell-orderAmount="props">
          <q-td :props="props">
            <div>
              {{
                parseFloat(props.value).toLocaleString(locale, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
              {{ currency }}
            </div>
          </q-td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <div>
              <q-btn
                icon="visibility"
                square
                round
                dense
                @click="displayLog(props.row)"
              >
                <q-tooltip class="bg-green-vito text-no-wrap">
                  {{ $t("tooltip.showLog") }}
                </q-tooltip>
              </q-btn>
            </div>
          </q-td>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
  <q-dialog v-model="showCard" ref="showLogDialog">
    <log-card :log="selectedLog"></log-card>
  </q-dialog>
</template>
<script>
import LogApi from "../../apis/Log";
import { useI18n } from "vue-i18n";
import { OrderAction, OrderType } from "../order/order.enum";
import { ReversedMessageStatus, MessageStatus } from "../message/message.enum";
import LogCard from "./LogCard.vue";

export default {
  name: "Logs",
  components: { LogCard },

  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      showCard: false,
      filter: "",
      loading: false,
      pagination: {
        sortBy: "id",
        descending: true,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 0,
      },
      rows: [],
      selectedLog: null,
    };
  },
  methods: {
    async onRequest(props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const filter = props.filter;
      this.loading = true;
      try {
        const startRow = (page - 1) * rowsPerPage;
        const pageReponse = await LogApi.paginate({
          startRow,
          fetchCount: rowsPerPage,
          filter,
          sortBy,
          descending,
        });

        this.rows = pageReponse.data.items;
        this.pagination.rowsNumber = pageReponse.data.count;
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async refresh() {
      //console.log("refresh log");
      this.onRequest({
        pagination: this.pagination,
        filter: this.filter,
      });
      this.showNew = false;
    },
    displayLog(log) {
      this.selectedLog = log;
      this.showCard = true;
    },
    actionColor(action) {
      return OrderAction[action] ? OrderAction[action].color : action;
    },
    statusValue(status) {
      return ReversedMessageStatus[status]
        ? ReversedMessageStatus[status].toLowerCase()
        : status;
    },
    statusColor(status) {
      return status == MessageStatus.ERROR ? "red-vito" : "green-vito";
    },

    typeColor(type) {
      return OrderType[type] ? OrderType[type].color : "primary";
    },
  },

  async mounted() {
    this.$store.state.title = "button.logs";
    this.onRequest({
      pagination: this.pagination,
      filter: this.filter,
    });
  },

  computed: {
    currency() {
      return this.$store.getters.currency;
    },
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "left",
          field: "id",
          sortable: true,
        },
        {
          name: "date",
          align: "left",
          label: this.$t("label.date"),
          field: "date",
          sortable: true,
        },
        {
          name: "store",
          label: this.$t("label.store"),
          field: "store",
          align: "left",
          sortable: false,
        },
        {
          name: "action",
          label: this.$t("label.action"),
          field: "action",
          align: "left",
          sortable: false,
        },
        {
          name: "status",
          label: this.$t("label.status"),
          field: "status",
          align: "left",
          sortable: true,
        },
        {
          name: "log",
          label: this.$t("label.log"),
          field: "log",
          align: "left",
          sortable: false,
        },
        {
          name: "orderId",
          label: this.$t("label.order"),
          field: "orderId",
          align: "left",
          sortable: false,
        },
        {
          name: "orderType",
          label: this.$t("label.type"),
          field: "orderType",
          align: "left",
          sortable: false,
        },

        {
          name: "orderAmount",
          label: this.$t("label.amount"),
          field: "orderAmount",
          align: "left",

          sortable: true,
        },
        { name: "actions", style: "width:70px" },
      ];
      return columns;
    },
  },
};
</script>
