<template>
  <div>
    <q-splitter v-model="splitterModel" :horizontal="$q.screen.lt.sm">
      <template v-slot:before>
        <div class="q-pa-md">
          <q-tree
            ref="docTree"
            :nodes="docTree"
            node-key="id"
            label-key="title"
            selected-color="green-vito"
            v-model:selected="selectedId"
            @update:selected="getDoc"
            no-selection-unset
          >
            <template v-slot:header-root="prop">
              <div class="row items-center justify-between full-width no-wrap">
                <div class="row items-center no-wrap text-green-vito">
                  <!--img
                    src="/img/display.png"
                    class="q-mr-sm"
                    style="width: 50px; height: 50px"
                  /-->
                  <q-icon name="auto_stories" size="34px" class="q-mx-sm" />
                  <div class="text-h5">
                    {{ prop.node.title }}
                  </div>
                </div>
                <div>
                  <q-btn-dropdown
                    stretch
                    dense
                    flat
                    dropdown-icon="more_vert"
                    menu-anchor="top right"
                    menu-self="bottom right"
                    icon-left
                    auto-close
                  >
                    <q-btn-group class="text-green-vito">
                      <q-btn dense icon="edit" @click="editDoc(prop.node.id)" />
                      <q-btn dense icon="add" @click="addDoc(prop.node.id)"
                    /></q-btn-group>
                  </q-btn-dropdown>
                </div>
              </div>
            </template>

            <template v-slot:default-header="prop">
              <div class="row items-center justify-between full-width no-wrap">
                <div class="row items-center no-wrap">
                  <q-icon
                    name="o_article"
                    color="green-vito"
                    size="28px"
                    class="q-mr-sm"
                  />
                  <div>
                    {{ prop.node.title }}
                  </div>
                </div>
                <div>
                  <q-btn-dropdown
                    stretch
                    dense
                    rounded
                    flat
                    dropdown-icon="more_vert"
                    color="green-vito"
                    menu-anchor="top right"
                    menu-self="bottom right"
                    icon-left
                    auto-close
                  >
                    <q-btn-group class="text-green-vito">
                      <q-btn dense icon="edit" @click="editDoc(prop.node.id)" />
                      <q-btn dense icon="add" @click="addDoc(prop.node.id)" />
                      <q-btn
                        dense
                        icon="delete"
                        @click="deleteDoc(prop.node.id)"
                        :disable="!prop.node.deletable"
                    /></q-btn-group>
                  </q-btn-dropdown>
                </div>
              </div>
            </template>
          </q-tree>
        </div>
      </template>

      <template v-slot:after>
        <q-tab-panels
          v-model="selectedId"
          animated
          transition-prev="jump-up"
          transition-next="jump-up"
        >
          <q-tab-panel
            :name="doc.id"
            v-for="doc of docs"
            :key="doc.id"
            class="bg-grey-1"
          >
            <div class="text-h5 text-green-vito q-mb-md">{{ doc.title }}</div>
            <div v-html="doc.content" />
          </q-tab-panel>
        </q-tab-panels>
      </template>
    </q-splitter>
  </div>

  <q-dialog full-width v-model="showEditDoc">
    <doc-card :selectedDoc="selectedDoc" @saveDoc="saveDoc" />
  </q-dialog>
</template>

<script>
import DocCard from "../components/DocCard.vue";
import DocApi from "../apis/Doc";

export default {
  components: { DocCard },
  data: () => {
    return {
      showEditDoc: false,
      splitterModel: 30,
      selectedId: 0,
      selectedDoc: {},
      docs: [],
      docTree: [],
      testdocTree: [
        {
          title: "Documentation",
          header: "root",
          doc: `
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis
              praesentium cumque magnam odio iure quidem, quod illum numquam
              possimus obcaecati commodi minima assumenda consectetur culpa fuga
              nulla ullam. In, libero.
            </p>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis
              praesentium cumque magnam odio iure quidem, quod illum numquam
              possimus obcaecati commodi minima assumenda consectetur culpa fuga
              nulla ullam. In, libero.
            </p>`,
          id: 1,
          children: [
            {
              title: "Borne",
              deletable: true,
              id: 2,
            },
            {
              title: "Back Office",
              deletable: true,
              id: 3,
            },
            {
              id: 4,
              title: "Parcours Client",
              deletable: false,
              children: [
                {
                  title: "Food",
                  deletable: true,
                  id: 2,
                },
                {
                  title: "Room service",
                  deletable: true,
                  id: 3,
                },
              ],
            },
          ],
        },
      ],
    };
  },
  async mounted() {
    await this.findDocTree();
    if (this.docTree.length) {
      this.$refs.docTree.setExpanded(this.docTree[0].id, true);
      this.setDocs(this.docTree[0]);
      this.selectedDoc = this.docs[0];
      this.selectedId = this.docTree[0].id;
    }
    //console.log(this.docs);
  },
  methods: {
    async saveDoc(doc) {
      //console.log("save", doc);
      try {
        const docTreeResponse =
          doc.id == 0
            ? await DocApi.create({
                parentId: this.selectedId,
                title: doc.title,
                content: doc.content,
                position: doc.position,
              })
            : await DocApi.save(doc.id, {
                id: doc.id,
                title: doc.title,
                content: doc.content,
                position: doc.position,
              });

        this.docTree = docTreeResponse.data.docs;
        this.selectedId = docTreeResponse.data.doc.id;

        this.docs = [];
        this.setDocs(this.docTree[0]);
        this.selectedDoc = this.docs.find((d) => d.id == this.selectedId);
        this.selectedDoc.content = docTreeResponse.data.doc.content;

        //console.log(this.docs);

        this.$q.notify({
          type: "positive",
          message: this.$t(docTreeResponse.data.message),
        });
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }

      this.showEditDoc = false;
    },
    async findDocTree() {
      try {
        const docTreeResponse = await DocApi.findAll();
        this.docTree = docTreeResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async getDoc(nodeId) {
      if (nodeId) {
        //let node = this.$refs.docTree.getNodeByKey(nodeId);
        let doc = this.docs.find((doc) => doc.id === nodeId);
        //console.log(nodeId, node, doc);
        if (!doc.content) {
          try {
            const docResponse = await DocApi.findOne(nodeId);
            doc.content = docResponse.data.content;
          } catch (error) {
            const message = error.response
              ? error.response.data.message
              : error.message;
            this.errorMessage = message;
            this.$q.notify({
              type: "negative",
              message: message,
            });
          }
        }
        this.selectedDoc = doc;
      }
    },
    setDocs(node) {
      let doc = {
        id: node.id,
        title: node.title,
        content: node.content,
        position: node.position,
      };
      this.docs.push(doc);
      if (node.children && node.children.length) {
        node.children.forEach((child) => this.setDocs(child));
      }
    },
    addDoc() {
      this.selectedDoc = { id: 0, title: "", content: "" };
      this.showEditDoc = true;
    },
    editDoc() {
      //console.log(this.selectedDoc);
      this.showEditDoc = true;
    },
    deleteDoc() {
      //console.log("deletedoc", this.selectedId);
      this.$q
        .dialog({
          title: this.$t("title.confirmDelete"),
          message: this.$t("message.confirmDeleteDoc"),
          ok: {
            color: "red-vito",
            label: this.$t("button.deleteDefinitively"),
          },
          cancel: {
            label: this.$t("button.cancel"),
            color: "green-vito",
          },
          persistent: true,
        })
        .onOk(async () => {
          try {
            const docResponse = await DocApi.delete(this.selectedId);
            this.docTree = docResponse.data.docs;
            this.selectedId = null;

            this.docs = [];
            this.setDocs(this.docTree[0]);
            this.selectedDoc = this.docs[0];
            this.selectedId = this.docTree[0].id;
            //console.log(this.docs);
            this.$q.notify({
              type: "positive",
              message: this.$t(docResponse.data.message),
            });
          } catch (error) {
            const message = error.response
              ? error.response.data.message
              : error.message;
            this.errorMessage = message;
            this.$q.notify({
              type: "negative",
              message: message,
            });
          }
        });
    },
  },
};
</script>
