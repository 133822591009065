<template>
  <q-card
    bordered
    class="q-mt-md q-pa-md shadow-1 no-padding-md"
    style="width: 750px; max-width: 90vw"
  >
    <q-card-section class="row text-green-vito no-wrap justify-between">
      <div class="text-h6">
        {{ $t("title.addVisit") }}
      </div>
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>

    <q-card-section xclass="q-gutter-md">
      <div class="text-center" v-if="!type">
        <q-btn-toggle
          no-caps
          v-model="selectedType"
          toggle-color="green-vito"
          :options="options"
          roundedo
          unelevated
          size="md"
          class="bg-green-vito-light q-mb-md"
          ><q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.selectVisitType") }}
          </q-tooltip>
          <template v-slot:supplier>
            <div class="row items-center no-wrap q-ma-sm">
              <q-icon left name="wifi_protected_setup" />
              <div class="full-width">{{ $t("type.visit.supplier") }}</div>
            </div>
          </template>
          <template v-slot:technician>
            <div class="row items-center no-wrap q-ma-sm">
              <q-icon left name="engineering" />
              <div class="">{{ $t("type.visit.technician") }}</div>
            </div>
          </template>
        </q-btn-toggle>
      </div>
      <div v-else class="row">
        <span class="q-ma-sm">{{ $t("type.visit.label") }}</span>
        <q-chip
          square
          class="bg-green-vito-light q-mb-md"
          :icon="type == 'supplier' ? 'wifi_protected_setup' : 'engineering'"
        >
          {{ $t("type.visit." + type) }}
        </q-chip>
      </div>

      <div v-if="!store">
        <q-select
          v-model="selectedStoreId"
          :options="stores"
          option-value="id"
          option-label="name"
          :label="$t('label.store')"
          filled
          borderless
          square
          emit-value
          hint=""
          map-options
          ref="store"
        >
          <template v-slot:prepend>
            <q-icon name="apps" />
          </template>
        </q-select>
      </div>
      <div v-else class="row">
        <span class="q-ma-sm">{{ $t("label.store") }}</span>
        <q-chip square class="bg-green-vito-light q-mb-md" icon="apps">
          {{ store.name }}
        </q-chip>
      </div>

      <q-input
        stack-label
        square
        filled
        :label="$t('label.comment')"
        v-model="comment"
        autogrow
      >
        <template v-slot:prepend> <q-icon name="comment" /> </template
      ></q-input>
    </q-card-section>
    <q-card-actions class="q-pa-md justify-end">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        v-close-popup
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="$t('button.request')"
        :disable="
          (type ? false : !selectedType) || (store ? false : !selectedStoreId)
        "
        @click="handleSave"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
import StoreApi from "../../apis/Store";
import VisitApi from "../../apis/Visit";
import { VisitType } from "./visit.enum";

export default {
  emits: ["refresh"],
  props: ["store", "type"],

  data: () => {
    return {
      stores: [],
      errorMessage: "",
      selectedType: null,
      selectedStoreId: null,
      comment: null,
      options: [
        {
          value: "supplier",
          slot: "supplier",
        },
        {
          value: "technician",
          slot: "technician",
        },
      ],
    };
  },

  async mounted() {
    await this.getAllStores();
    //console.log(this.stores);
  },
  methods: {
    async getAllStores() {
      try {
        const storesResponse = await StoreApi.findAll();
        this.stores = storesResponse.data;
        this.selectedStoreId = this.stores[0].id;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async handleSave() {
      //console.log("saving new", this.type);
      this.errorMessage = null;

      try {
        const visitResponse = await VisitApi.create({
          storeId: this.store ? this.store.id : this.selectedStoreId,
          type: this.type || VisitType[this.selectedType.toUpperCase()],
          comment: this.comment,
        });
        this.$q.notify({
          type: "positive",
          message: this.$t(visitResponse.data.message),
        });
        this.$emit("refresh");
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    selectedClass(product, selected) {
      if (!product) return "";
      if (selected == "new") {
        return product.id == this.selectedProduct.id
          ? "border-vito shadow-2"
          : "border-transparent";
      } else {
        return product.id == this.selectedReturnProduct.id
          ? "border-vito shadow-2"
          : "border-transparent";
      }
    },
    required(val) {
      return (val && val.length > 0) || this.$t("validation.required");
    },
    short(val) {
      return (
        (val && val.length > 2) || this.$t("validation.minchar", { chars: 3 })
      );
    },
  },

  computed: {},
};
</script>
