<template>
  <q-card
    flat
    class="q-pa-sm full-width no-padding-md"
    style="max-width: 100vw"
  >
    <q-card-section class="row q-pb-xs text-green-vito justify-between">
      <div class="text-h5">
        {{ $t("title.orders") }}
      </div>

      <div class="q-gutter-x-md row">
        <div class="flex column justify-center">
          <q-btn
            v-if="isSuperAdmin"
            ref="addButton"
            color="primary"
            round
            dense
            icon="add"
            @click="showNew = true"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.addOrder") }}
            </q-tooltip>
          </q-btn>
        </div>

        <div class="flex column justify-center">
          <q-btn
            ref="exportButton"
            text-color="green-vito"
            round
            dense
            icon="file_download"
            @click="showCsvFilter = true"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.exportOrder") }}
            </q-tooltip>
          </q-btn>
        </div>
        <div class="flex column justify-center">
          <q-btn
            ref="filterButton"
            :color="showFilter || filterApplied ? 'green-vito' : 'white'"
            :text-color="showFilter || filterApplied ? 'white' : 'green-vito'"
            round
            dense
            :icon="'filter_alt'"
            @click="handleShowFilter"
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{
                $t("tooltip.filterOrder")
              }}
            </q-tooltip>
          </q-btn>
        </div>
        <q-input
          outlined
          dense
          clearable
          debounce="300"
          v-model="filterById"
          @update:model-value="setFilterById"
          :placeholder="$t('label.search')"
          style="width: 120px"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </q-card-section>

    <q-card-section>
      <q-table
        :rows="rows"
        :columns="columnsI18n"
        row-key="id"
        v-model:pagination="pagination"
        :loading="loading"
        :filter="filter"
        @request="onRequest"
        binary-state-sort
        class="sticky-header-table"
      >
        <template v-slot:body-cell-date="props">
          <q-td :props="props">
            <div>
              {{ $moment(props.value).format("DD/MM/YYYY HH:mm") }}
            </div>
          </q-td>
        </template>
        <template v-slot:body-cell-store="props">
          <q-td :props="props">
            <div>
              <router-link
                :to="'/store/' + props.row.store.id"
                class="vito-link text-bold"
              >
                {{ props.row.store.name }}
              </router-link>
            </div>
          </q-td>
        </template>
        <template v-slot:body-cell-product="props">
          <q-td :props="props">
            <div
              v-bind="props"
              v-if="props.value"
              class="row no-wrap q-gutter-x-sm"
            >
              <div style="width: 26px">
                <q-img :src="productImage(props.value)" />
              </div>
              <div class="flex flex-center">
                {{ props.value.name }}
                {{ props.value.weight }} kg
              </div>
            </div>
          </q-td>
        </template>

        <template v-slot:body-cell-type="props">
          <q-td :props="props">
            <q-badge>{{ props.value }}</q-badge>
          </q-td>
        </template>
        <template v-slot:body-cell-status="props">
          <q-td :props="props">
            <q-badge :color="statusColor(props.value)">{{
              $t("status." + props.value)
            }}</q-badge>
            <q-badge
              class="q-ml-xs"
              :color="statusColor(props.value)"
              v-if="props.row.reason"
              >{{ $t(props.row.reason) }}</q-badge
            >
          </q-td>
        </template>
        <template v-slot:body-cell-delivered="props">
          <q-td :props="props">
            <q-icon
              size="sm"
              :color="props.value ? 'green-vito' : 'red-vito'"
              :name="props.value ? 'check_box' : 'disabled_by_default'"
            />
          </q-td>
        </template>
        <template v-slot:body-cell-returned="props">
          <q-td :props="props">
            <q-icon
              v-if="props.row.type != 'new_bottle'"
              size="sm"
              :color="props.value ? 'green-vito' : 'red-vito'"
              :name="props.value ? 'check_box' : 'disabled_by_default'"
            />
          </q-td>
        </template>
        <template v-slot:body-cell-amount="props">
          <q-td :props="props">
            <div>
              {{
                parseFloat(props.value).toLocaleString(locale, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
              {{ currency }}
            </div>
          </q-td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <div>
              <q-btn
                icon="visibility"
                square
                round
                dense
                @click="displayOrder(props.key)"
              >
                <q-tooltip class="bg-green-vito text-no-wrap">
                  {{ $t("tooltip.showOrder") }}
                </q-tooltip>
              </q-btn>
            </div>
          </q-td>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
  <q-dialog v-model="showNew" ref="newOrderDialog">
    <new-order @refresh="refresh" />
  </q-dialog>

  <q-dialog v-model="showFilter" ref="filterOrderDialog">
    <order-filter @filter="applyFilter" type="filter" />
  </q-dialog>

  <q-dialog v-model="showCsvFilter" ref="csvFilterOrderDialog">
    <order-filter @filter="applyCsvFilter" type="export" />
  </q-dialog>
</template>
<script>
import { exportFile } from "quasar";
import NewOrder from "../components/order/NewOrder.vue";
import OrderApi from "../apis/Order";
import { useI18n } from "vue-i18n";
import { OrderStatus } from "../components/order/order.enum";
import OrderFilter from "../components/order/OrderFilter.vue";
import { Buffer } from 'buffer';

export default {
  name: "OrderPage",

  components: { NewOrder, OrderFilter },
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      filterApplied: localStorage.orderFilter,
      showFilter: false,
      showCsvFilter: false,
      showNew: false,
      filter: localStorage.orderFilter ? JSON.parse(localStorage.orderFilter) : {},
      filterById: "",
      loading: false,
      pagination: {
        sortBy: "id",
        descending: true,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 0,
      },
      rows: [],
    };
  },
  async mounted() {
    this.$store.state.title = "button.orders";
    this.onRequest({
      pagination: this.pagination,
      filter: this.filter,
    });
  },

  methods: {
    async applyCsvFilter(filter) {
      this.showCsvFilter = false;
      try {
        const csv = await OrderApi.csvExport({
          filter: filter,
          lang: this.locale,
        });
        if (csv) {
          const status = exportFile(
            `dab-${filter.report}-${this.$moment(new Date()).format("YYMMDDHHmm")}.xlsx`,
            Buffer.from( new Uint8Array(csv.data)),
            {
              mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            }
          );

          if (status === true) {
            // browser allowed it
          } else {
            // browser denied it
            console.error("Error: " + status);
          }
        }
      } catch (error) {
        console.log(error);
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: this.$t(message),
        });
      }
    },
    setFilterById(filter) {
      this.filter = filter;
    },
    handleShowFilter() {
      this.showFilter = true;
    },
    applyFilter(filter) {
      this.filterById = "";
      this.filter = filter;
      localStorage.orderFilter = JSON.stringify(filter);
      this.filterApplied = true;
      this.showFilter = false;

      this.onRequest({
        pagination: this.pagination,
        filter: filter,
      });
    },

    async onRequest(props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const filter = props.filter;
      this.loading = true;
      try {
        const startRow = (page - 1) * rowsPerPage;
        const pageReponse = await OrderApi.paginate({
          startRow,
          fetchCount: rowsPerPage,
          filter,
          sortBy,
          descending,
        });

        this.rows = pageReponse.data.items;
        this.pagination.rowsNumber = pageReponse.data.count;
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;
        this.loading = false;
        //
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    async refresh() {
      //console.log("refresh ordeers");
      this.onRequest({
        pagination: this.pagination,
        filter: this.filter,
      });
      this.showNew = false;
    },

    displayOrder(orderId) {
      this.$router.push("/order/" + orderId);
    },

    productImage(product) {
      if (product && product.image)
        return (
          process.env.VUE_APP_BASE_URL + "/product/images/" + product.image
        );
    },

    statusColor(status) {
      return OrderStatus[status] ? OrderStatus[status].color : "primary";
    },
  },

  computed: {
    currency() {
      return this.$store.getters.currency;
    },
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "center",
          field: "id",
          sortable: true,
        },
        {
          name: "status",
          label: this.$t("label.status"),
          field: "status",
          align: "center",
          sortable: true,
        },
        {
          name: "date",
          align: "center",
          label: this.$t("label.date"),
          field: "date",
          sortable: true,
        },
        {
          name: "store",
          label: this.$t("label.store"),
          field: "store",
          align: "center",
          sortable: false,
        },
        {
          name: "product",
          label: this.$t("label.product"),
          field: "deliveryProduct",
          align: "center",
          sortable: false,
        },
        {
          name: "delivered",
          label: this.$t("label.delivered"),
          //format: (val) => this.$t(val ? "true" : "false"),
          field: "delivered",
          align: "center",
          sortable: false,
        },
        {
          name: "returned",
          label: this.$t("label.returned"),
          //format: (val) => this.$t(val ? "true" : "false"),
          field: "returned",
          align: "center",
          sortable: false,
        },
        {
          name: "source",
          label: this.$t("label.source"),
          field: "source",
          align: "center",
          format: (val) => val.toUpperCase(),

          sortable: true,
        },
        {
          name: "amount",
          label: this.$t("label.amount"),
          field: "amount",
          align: "center",

          sortable: true,
        },
        { name: "actions", style: "width:70px" },
      ];
      return columns;
    },
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
  },
};
</script>
