<template>
  <q-card class="alert-panel" xstyle="max-width: 100vw; xwidth: 540px">
    <q-card-section
      class="row text-green-vito justify-between xno-padding-md q-pa-sm"
    >
      <div class="text-h5">
        <q-icon dense name="notifications" class="q-pb-xs" />
        {{ $t("title.alerts") }}
      </div>
      <div class="q-gutter-x-sm row">
        <q-btn icon="refresh" round dense @click="$emit('refresh')">
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.refresh") }}
          </q-tooltip>
        </q-btn>
      </div>
    </q-card-section>

    <q-card-section class="q-gutter-y-lg">
      <div
        class="flex justify-start no-wrap bg-grey-3 q-pa-sm rounded-borders"
        v-if="alerts.outOfDateDisplays && alerts.outOfDateDisplays.length"
      >
        <div>
          <q-badge
            color="red-vito"
            class="q-py-xs q-px-sm q-ml-xs"
            style="margin-top: -25px; position: absolute"
          >
            <q-icon name="o_timer" class="q-mr-xs" size="xs" />{{
              statusUpdateLabel
            }}
          </q-badge>
        </div>
        <div class="flex q-pa-md q-gutter-md">
          <div
            v-for="displayAlert of alerts.outOfDateDisplays"
            :key="displayAlert.storeId"
          >
            <q-btn
              no-caps
              size="sm"
              color="green-vito"
              :to="'/store/' + displayAlert.storeId"
              class="text-no-wrap"
            >
              {{ displayAlert.name }}
              <q-badge color="red-vito" floating>{{
                displayAlert.count
              }}</q-badge>
              <q-tooltip class="bg-green-vito text-no-wrap">
                {{ $t("tooltip.showStore") }}
              </q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>

      <div
        class="flex justify-start no-wrap bg-grey-3 q-pa-sm rounded-borders"
        v-if="alerts.lowBatteryDisplays && alerts.lowBatteryDisplays.length"
      >
        <div>
          <q-badge
            color="red-vito"
            class="q-py-xs q-px-sm q-ml-xs"
            style="margin-top: -25px; position: absolute"
          >
            <q-icon name="battery_alert" class="q-mr-xs" size="xs" />{{
              $t("label.lowBatteryDisplay")
            }}
          </q-badge>
        </div>
        <div class="flex q-pa-md q-gutter-md">
          <div
            v-for="displayAlert of alerts.lowBatteryDisplays"
            :key="displayAlert.storeId"
          >
            <q-btn
              no-caps
              size="sm"
              color="green-vito"
              :to="'/store/' + displayAlert.storeId"
              class="text-no-wrap"
            >
              {{ displayAlert.name }}
              <q-badge color="red-vito" floating>{{
                displayAlert.count
              }}</q-badge>
              <q-tooltip class="bg-green-vito text-no-wrap">
                {{ $t("tooltip.showStore") }}
              </q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>

      <div
        class="flex justify-start no-wrap bg-grey-3 q-pa-sm rounded-borders"
        v-if="alerts.openVisits && alerts.openVisits.length"
      >
        <div>
          <q-badge
            color="warning"
            class="q-py-xs q-px-sm q-ml-xs text-green-vito"
            style="margin-top: -25px; position: absolute"
          >
            <q-icon name="engineering" class="q-mr-xs" size="xs" />{{
              $t("label.openVisits")
            }}</q-badge
          >
        </div>
        <div class="flex q-pa-md q-gutter-md">
          <div
            v-for="visitAlert of alerts.openVisits"
            :key="visitAlert.storeId"
          >
            <q-btn
              color="green-vito"
              no-caps
              size="sm"
              :to="'/store/' + visitAlert.storeId"
              class="text-no-wrap"
            >
              {{ visitAlert.name }}
              <q-badge color="red-vito" floating>{{
                visitAlert.count
              }}</q-badge>
              <q-tooltip class="bg-green-vito text-no-wrap">
                {{ $t("tooltip.showStore") }}
              </q-tooltip>
            </q-btn>
          </div>
        </div>
      </div>

      <div
        class="flex justify-start no-wrap bg-grey-3 q-pa-sm rounded-borders"
        v-if="alerts.pendingContracts && alerts.pendingContracts > 0"
      >
        <div>
          <q-badge
            color="warning"
            class="q-py-xs q-px-sm q-ml-xs text-green-vito"
            style="margin-top: -25px; position: absolute"
          >
            <q-icon name="hourglass_bottom" class="q-mr-xs" size="xs" />{{
              $t("label.pending")
            }}</q-badge
          >
        </div>
        <div class="flex q-pa-md q-gutter-md">
          <q-btn
            color="green-vito"
            no-caps
            size="sm"
            to="/contracts"
            class="text-no-wrap"
          >
            {{ $t("label.pendingContracts") }}
            <q-badge color="red-vito" floating>{{
              alerts.pendingContracts
            }}</q-badge>
          </q-btn>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  emits: ["refresh"],
  props: ["alerts"],

  computed: {
    statusUpdateLabel() {
      return this.$t("label.outOfDateDisplays", {
        minutes: this.alerts.storeTimeout,
      });
    },
  },
};
</script>
