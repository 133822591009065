<template>
  <div class="row justify-around text-green-vito no-wrap">
    <q-icon
      :color="statusColor('isClosed')"
      :name="statusIcon('isClosed')"
      size="xs"
    />
    <q-icon
      :color="statusColor('isLocked')"
      :name="statusIcon('isLocked')"
      size="xs"
    />
    <q-icon
      :color="statusColor('isEmpty')"
      :name="statusIcon('isEmpty')"
      size="xs"
    />
    <q-icon
      :color="statusColor('isFaulty')"
      :name="statusIcon('isFaulty')"
      size="xs"
    />
  </div>
</template>

<script>
import { LockerStatus } from "./locker.enum";
export default {
  props: ["locker"],
  data: () => {
    return {};
  },

  async mounted() {
    //console.log("status", this.locker.isFaulty);
  },

  methods: {
    statusIcon(field) {
      let value = this.locker[field];
      let lockerStatus = LockerStatus[field + "_" + value.toString()];
      return lockerStatus ? lockerStatus.icon : "question_mark";
    },
    statusColor(field) {
      let lockerStatus = null;
      let value = this.locker[field];
      if (field == "isEmpty") {
        let mismatch = (this.locker.article === null) === !value;
        lockerStatus = LockerStatus["isEmpty_" + mismatch.toString()];
      } else {
        lockerStatus = LockerStatus[field + "_" + value.toString()];
      }

      return lockerStatus ? lockerStatus.color : "primary";
    },
  },
};
</script>
