<template>
  <q-card
    bordered
    class="col q-pa-sm q-mb-sm no-padding-md"
    style="max-width: 100vw"
  >
    <q-card-section class="row q-pa-sm text-green-vito justify-between">
      <div v-if="action == 'display'" xclass="col-grow">
        <div class="text-h5 text-green-vito">{{ form.name }}</div>
      </div>
      <div v-if="editing" class="col-grow">
        <div class="text-h5 text-green-vito">
          {{ $t("title.editDisplay") }} #{{ display.id }}
        </div>
      </div>

      <div v-if="parentAction == 'display'" class="row q-gutter-x-sm no-wrap">
        <q-btn
          v-if="!editing"
          icon="sync"
          round
          dense
          @click="$emit('refreshDisplay')"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.showDisplay") }}
          </q-tooltip>
        </q-btn>
        <q-btn
          v-if="editing && form.isDeletable"
          icon="delete"
          class="text-red-vito"
          round
          dense
          @click="handleRemove"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.removeDisplay") }}
          </q-tooltip>
        </q-btn>
        <q-btn
          v-if="editing"
          icon="visibility"
          round
          dense
          @click="handleCancel"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.showDisplay") }}
          </q-tooltip>
        </q-btn>
        <q-btn v-else icon="edit" square round dense @click="handleEdit">
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.editDisplay") }}
          </q-tooltip>
        </q-btn>
      </div>
    </q-card-section>
    <q-card-section class="q-pa-sm" v-if="!editing">
      <q-chip
        class="q-pr-none"
        outline
        square
        :color="batteryColor(display.battery)"
        :icon="batteryIcon(display.battery)"
      >
        {{ getLevel(display.battery) }}%
        {{
          display.batteryUpdated
            ? $moment(display.batteryUpdated).fromNow()
            : $moment(display.created_at).fromNow()
        }}
        <q-btn
          dense
          size="sm"
          icon="show_chart"
          class="q-ml-md"
          @click="handleBatteryHistory"
        />
        <q-tooltip class="bg-green-vito text-no-wrap">
          {{ $t("tooltip.batteryLevel") }}: {{ display.battery }} mV,
          {{
            display.batteryUpdated
              ? $moment(display.batteryUpdated).format("DD/MM/Y HH:mm")
              : $moment(display.created_at).format("DD/MM/Y HH:mm")
          }}</q-tooltip
        >
      </q-chip>
      <q-chip outline square color="green-vito" icon="wifi_protected_setup">
        {{
          display.lastSupply
            ? $moment(display.lastSupply).fromNow()
            : $moment(display.created_at).fromNow()
        }}
        <q-tooltip class="bg-green-vito text-no-wrap"
          >{{ $t("tooltip.lastSupply") }}:
          {{
            display.lastSupply
              ? $moment(display.lastSupply).format("DD/MM/Y HH:mm")
              : $moment(display.created_at).format("DD/MM/Y HH:mm")
          }}</q-tooltip
        >
      </q-chip>
    </q-card-section>

    <q-card-section
      class="col"
      v-if="editing"
      style="width: 420px; max-width: 100%"
    >
      <q-form class="q-gutter-y-md q-mt-md">
        <q-input
          ref="name"
          square
          filled
          clearable
          v-model="form.name"
          :label="$t('label.name')"
          :hint="$t('hint.minchar', { chars: 2 })"
          lazy-rules
          :rules="[required, short]"
        >
        </q-input>
        <q-input
          ref="targetVersion"
          square
          filled
          clearable
          v-model="form.targetVersion"
          :label="$t('label.targetVersion')"
          lazy-rules
        >
        </q-input>
      </q-form>
    </q-card-section>
    <q-card-section class="row wrap q-pa-sm q-gutter-x-sm" v-else>
      <q-card-section
        v-for="(face, idxFace) in faces"
        :key="idxFace"
        class="q-pa-sm scroll"
      >
        <div>Face {{ String.fromCharCode("A".charCodeAt(0) + idxFace) }}</div>
        <q-markup-table separator="cell">
          <thead></thead>
          <tbody>
            <tr v-for="(row, idxRow) in form.template.rows" :key="idxRow">
              <td
                class="no-padding"
                style="width: 75px; height: 120px"
                v-for="(column, idxCol) in form.template.columns"
                :key="idxCol"
                @click="lockerAction(idxFace, idxRow, idxCol)"
              >
                <locker
                  :key="refreshCounter"
                  :locker="faces[idxFace][idxRow][idxCol]"
                  :isSelected="
                    faces[idxFace][idxRow][idxCol] == selectedLocker && showCard
                  "
                ></locker>
              </td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
    </q-card-section>

    <q-card-section v-if="errorMessage">
      <div class="text-red-vito text-center">
        <strong>{{ errorMessage }}</strong>
      </div>
    </q-card-section>

    <q-card-actions class="q-px-md justify-end" v-if="editing">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        @click="handleCancel"
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="$t('button.save')"
        @click="handleSave"
      />
    </q-card-actions>
  </q-card>
  <q-dialog v-model="showCard">
    <locker-card
      :store="store"
      :locker="selectedLocker"
      :display="form"
      @refresh="$emit('refresh')"
    />
  </q-dialog>

  <q-dialog full-width v-model="showBatteryHistory">
    <battery-history
      :store="store"
      :display="form"
      :batteryHistory="batteryHistory"
    />
  </q-dialog>
</template>

<script>
import LockerCard from "../locker/LockerCard.vue";
import DisplayApi from "../../apis/Display";
import Locker from "../locker/Locker.vue";
import { BatteryIcon } from "./display.enum";
import BatteryHistory from "./BatteryHistory.vue";

export default {
  emits: ["refresh", "displayUpdate", "refreshDisplay"],
  props: ["store", "display", "parentAction"],
  components: { LockerCard, Locker, BatteryHistory },
  data: () => {
    return {
      showBatteryHistory: false,
      batteryHistory: [],
      refreshCounter: 0,
      form: {
        id: null,
        name: "",
        targetVersion: 0,
        lockers: [],
      },
      errorMessage: null,
      action: "display",
      faces: [],
      selectedLocker: {},
      showCard: false,
    };
  },
  async mounted() {
    //console.log(this.display);
    try {
      this.form = { ...this.display };
      //console.log(this.form);

      for (let i = 0; i < this.form.template.faces; i++) {
        let rows = [];
        for (let j = 0; j < this.form.template.rows; j++) {
          let cols = [];
          for (let k = 0; k < this.form.template.columns; k++) {
            let lockers = this.form.lockers.filter(
              (locker) =>
                locker.positionZ == i &&
                locker.positionY == j &&
                locker.positionX == k
            );
            cols.push(lockers[0]);
          }
          rows.push(cols);
        }
        this.faces.push(rows);
      }
    } catch (error) {
      const message = error.response
        ? error.response.data.message
        : error.message;
      this.errorMessage = message;
      this.$q.notify({
        type: "negative",
        message: message,
      });
    }
  },
  methods: {
    async handleBatteryHistory() {
      try {
        const statsResponse = await DisplayApi.findBatteryHistory(
          this.display.id
        );
        this.batteryHistory = statsResponse.data;
        this.showBatteryHistory = true;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },

    getLevel(battery) {
      const min = 10000;
      const max = 12000;
      if (battery <= min) return 0;
      if (battery >= max) return 100;
      return Math.ceil(((battery - min) * 100) / (max - min));
    },

    batteryIcon(battery) {
      var getKeys = function (batteryIcon) {
        var keys = [];
        for (var key in batteryIcon) {
          keys.push(key);
        }
        return keys;
      };
      var icon = "";
      getKeys(BatteryIcon).every((level) => {
        if (this.getLevel(battery) <= parseInt(level)) {
          icon = BatteryIcon[level].icon;
          return false;
        }
        return true;
      });
      return icon;
    },
    batteryColor(battery) {
      var getKeys = function (batteryIcon) {
        var keys = [];
        for (var key in batteryIcon) {
          keys.push(key);
        }
        return keys;
      };
      var color = "";
      getKeys(BatteryIcon).every((level) => {
        if (this.getLevel(battery) <= parseInt(level)) {
          color = BatteryIcon[level].color;
          return false;
        }
        return true;
      });
      return color;
    },

    lockerAction(z, y, x) {
      this.selectedLocker = this.faces[z][y][x];
      this.showCard = true;
    },
    handleEdit() {
      this.action = "edit";
    },

    handleCancel() {
      //console.log(this.display);
      this.form = this.display;
      this.action = "display";
      this.errorMessage = null;
    },

    async handleSave() {
      //console.log("saving");
      this.errorMessage = null;
      ["name"].forEach((f) => {
        if (!this.$refs[f].validate()) {
          this.errorMessage = this.$t("validation.verify");
          return;
        }
      });
      if (!this.errorMessage) {
        try {
          this.$emit("displayUpdate", {
            id: this.form.id,
            name: this.form.name,
            targetVersion: this.form.targetVersion,
          });
        } catch (error) {
          const message = error.response
            ? error.response.data.message
            : error.message;
          this.errorMessage = message;
          this.$q.notify({
            type: "negative",
            message: message,
          });
        }
      }
    },

    async handleRemove() {
      this.$q
        .dialog({
          title: this.$t("title.confirmRemove"),
          message: this.$t("message.confirmRemoveDisplay"),
          ok: {
            color: "red-vito",
            label: this.$t("button.remove"),
          },
          cancel: {
            label: this.$t("button.cancel"),
            color: "green-vito",
          },
          persistent: true,
        })
        .onOk(() => {
          this.removeDisplay();
        });
    },
    async removeDisplay() {
      try {
        if (this.form.isDeletable) {
          const deleteResponse = await DisplayApi.remove(this.form.id);
          this.$q.notify({
            type: "positive",
            message: this.$t(deleteResponse.data.message),
          });
        }
        //console.log(saveResponse.data);
        this.$emit("refresh");
      } catch (error) {
        let message = error.response
          ? error.response.data.message
          : error.message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
        //console.error(message);
      }
    },
    required(val) {
      //console.log("val=", val, val.toString().length);
      return (
        (val && val.toString().length > 0) || this.$t("validation.required")
      );
    },
    short(val) {
      return (
        (val && val.length > 1) || this.$t("validation.minchar", { chars: 2 })
      );
    },
    isNumber(val) {
      return !isNaN(val) || this.$t("validation.number");
    },
    productImage(product) {
      return process.env.VUE_APP_BASE_URL + "/product/images/" + product.image;
    },
  },
  computed: {
    title() {
      return `${this.form.name} ${this.form.weight}  kg. ${this.form.description} `;
    },
    editing() {
      return this.action == "create" || this.action == "edit";
    },
    isActiveBg() {
      return this.form.isActive == false ? "bg-grey-3" : "";
    },
  },
};
</script>
