import Api from "./Api";
export default {
  findAll() {
    return Api().get("/doc");
  },

  findOne(id) {
    return Api().get(`/doc/${id}`);
  },

  save(id, payload) {
    return Api().patch("/doc/" + id, payload);
  },

  create(payload) {
    return Api().post("/doc", payload);
  },

  delete(id) {
    return Api().delete(`/doc/${id}`);
  },
};
