import { createStore } from "vuex";

export default createStore({
  state: {
    access_token: localStorage.getItem("token") || null,
    token: null,
    user: { name: "?", roles: [] },
    title: "button.dashboard",
    search: "",
    fromRoute: null,
    language: "",
    currency: "",
  },
  mutations: {
    setToken(state, data) {
      state.access_token = data;
    },
    setUser(state, data) {
      state.user = data;
    },
    setSearch(state, data) {
      state.search = data;
    },
    setTitle(state, data) {
      state.title = data;
    },
    setFromRoute(state, data) {
      state.fromRoute = data;
    },
    setCurrency(state, data) {
      state.currency = data;
    },
  },
  getters: {
    isSuperAdmin(state) {
      return state.user ? state.user.roles.includes("super") : false;
    },
    isAdmin(state) {
      return state.user ? state.user.roles.includes("admin") : false;
    },
    loggedIn(state) {
      return state.access_token !== null;
    },
    search(state) {
      return state.search;
    },
    title(state) {
      return state.title;
    },
    userName(state) {
      return state.user.name;
    },
    fromRoute(state) {
      return state.fromRoute;
    },
    language(state) {
      return state.language;
    },
    currency(state) {
      return state.currency;
    },
  },
  actions: {},
  modules: {},
});
