import Api from "./Api";
export default {
  save(id, form) {
    return Api().patch("/user/" + id, form);
  },
  adminSave(id, form) {
    return Api().patch("/user/admin/" + id, form);
  },
  adminCreate(form) {
    return Api().post("/user/admin", form);
  },
  resetPassword(id, pass) {
    return Api().patch("/user/" + id, pass);
  },
  findAll() {
    return Api().get("/user");
  },
  delete(id) {
    return Api().delete("/user/" + id);
  },
};
