<template>
  <q-card
    flat
    class="q-pa-sm full-width no-padding-md"
    style="max-width: 100vw"
  >
    <q-card-section class="row q-pb-xs text-green-vito justify-between">
      <div class="text-h5">
        {{ $t("title.displays") }}
      </div>
      <div class="row q-gutter-md no-padding-md">
        <div class="flex column justify-center">
          <q-btn
            color="primary"
            @click="showNew = true"
            icon="add"
            rounded
            dense
          >
            <q-tooltip class="bg-green-vito text-no-wrap">
              {{ $t("tooltip.addDisplay") }}
            </q-tooltip>
          </q-btn>
        </div>
        <q-input
          style="width: 120px"
          outlined
          dense
          clearable
          debounce="300"
          v-model="filter"
          :placeholder="$t('label.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </q-card-section>

    <q-card-section>
      <display-pages :filter="filter" :key="refreshCounter" />
    </q-card-section>
  </q-card>

  <q-dialog v-model="showNew" ref="newDisplayDialog">
    <display-card @refresh="refresh" />
  </q-dialog>
</template>

<script>
import DisplayPages from "./DisplayPages.vue";
import DisplayCard from "./DisplayCard.vue";

export default {
  components: { DisplayPages, DisplayCard },

  data: () => {
    return {
      showNew: false,
      filter: "",
      refreshCounter: 0,
    };
  },
  methods: {
    refresh() {
      this.showNew = false;
      this.refreshCounter += 1;
    },

    handleAddDisplay() {
      this.showNew = true;
    },
  },

  mounted() {
    this.$store.state.title = "button.displays";
    //console.log("displays mounted");
  },
};
</script>
