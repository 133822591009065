import { createRouter, createWebHistory } from "vue-router";
import ClearLayout from "../layouts/Clear.vue";
import FullLayout from "../layouts/Full.vue";
import Login from "../pages/Login.vue";
import Home from "../pages/Home.vue";
import Orders from "../pages/Orders.vue";
import Stores from "../pages/Stores.vue";
import Products from "../pages/Products.vue";
import NotFound404 from "../components/NotFound404.vue";
import Store from "../pages/Store.vue";
import Order from "../pages/Order.vue";
import Users from "../pages/Users.vue";
import Visits from "../pages/Visits.vue";
import Visit from "../pages/Visit.vue";
import Logs from "../pages/Logs.vue";
import Docs from "../pages/Docs.vue";
import Settings from "../pages/Settings.vue";
import Contracts from "../pages/Contracts.vue";
import Contract from "../pages/Contract.vue";
import Locker from "../pages/Locker.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: FullLayout,
    meta: { requireAuth: true },
    children: [
      { path: "/", component: Home },
      { path: "orders", component: Orders },
      {
        path: "stores",
        component: Stores,
      },
      { path: "products", component: Products },
      { path: "store/:id", component: Store },
      { path: "order/:id", component: Order },
      { path: "users", component: Users },
      { path: "visits", component: Visits },
      { path: "visit/:id", component: Visit },
      {
        path: "logs",
        component: Logs,
        children: [
          { path: "orders", component: Logs },
          { path: "lockers", component: Logs },
          { path: "messages", component: Logs },
          { path: "terminals", component: Logs },
        ],
      },
      { path: "docs", component: Docs },
      { path: "contracts", component: Contracts },
      { path: "contract/:id", component: Contract },
      { path: "locker/:id", component: Locker },
      {
        path: "admin",
        component: Settings,
        children: [
          { path: "general", component: Settings },
          { path: "displays", component: Settings },
          { path: "templates", component: Settings },
          { path: "terminals", component: Settings },
        ],
      },
      //{ path: "admin/:pathMatch(.*)", component: Settings },
    ],
  },
  {
    path: "/login",
    component: ClearLayout,
    children: [
      { path: "", component: Login },
      { path: "signin", component: Login },
      { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound404 },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.FRONT_BASE_URL),
  routes,
});

export default router;
