<template>
  <q-toolbar class="text-green-vito bg-grey-1 q-mb-sm q-pl-none shadow-1">
    <q-btn-dropdown
      stretch
      flat
      :label="$t('button.bankReturn')"
      dropdown-icon="more_vert"
      menu-anchor="bottom left"
      menu-self="top left"
      icon-left
    >
    </q-btn-dropdown>
  </q-toolbar>
  <q-markup-table flat dense bordered separator="cell">
    <thead>
      <tr class="bg-green-vito-light">
        <th>ID</th>
        <th>{{ $t("label.date") }}</th>
        <th>{{ $t("label.type") }}</th>
        <th>{{ $t("label.return") }}</th>
        <th>{{ $t("label.transactionReference") }}</th>
        <th>{{ $t("label.authorisationNumber") }}</th>
        <th>{{ $t("label.amount") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="transaction in order.transactions"
        :key="transaction.id"
        class="text-center"
      >
        <td>{{ transaction.id }}</td>
        <td>{{ $moment(transaction.date).format("DD/MM/YYYY HH:mm:ss") }}</td>
        <td>
          <q-badge :color="transactionTypeColor(transaction.type)">{{
            transactionTypeValue(transaction.type)
          }}</q-badge>
        </td>
        <td>
          <q-badge :color="transaction.success ? 'green-vito' : 'red-vito'">{{
            transaction.success ? $t("status.success") : $t("status.failure")
          }}</q-badge>
        </td>
        <td>{{ transaction.reference }}</td>
        <td>{{ transaction.authorisation }}</td>
        <td>
          {{
            parseFloat(transaction.amount).toLocaleString(locale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }}
          {{ currency }}
        </td>
      </tr>
    </tbody>
  </q-markup-table>
</template>

<script>
import { useI18n } from "vue-i18n";
import { OrderTransactionType } from "./order.enum";
export default {
  props: ["order"],
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {};
  },

  async mounted() {
    //console.log(this.order);
  },

  methods: {
    transactionTypeColor(type) {
      return OrderTransactionType[type]
        ? OrderTransactionType[type].color
        : "primary";
    },

    transactionTypeValue(type) {
      return this.$t("type.transaction." + type);
    },
  },
  computed: {
    currency() {
      return this.$store.getters.currency;
    },
  },
};
</script>
