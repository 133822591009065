<template>
  <q-card
    flat
    class="q-pa-sm full-width no-padding-md"
    style="max-width: 100vw"
  >
    <q-card-section class="row q-pb-xs text-green-vito justify-between">
      <div class="text-h5">
        {{ $t("title.terminalLogs") }}
      </div>
      <div class="q-gutter-x-md row" style="height: 40px">
        <!--q-input
          outlined
          dense
          clearable
          debounce="300"
          v-model="filter"
          style="width: 120px"
          :placeholder="$t('label.search')"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input-->
      </div>
    </q-card-section>

    <q-card-section>
      <q-table
        class="sticky-header-table size-lg"
        xtable-style="max-height: 300px"
        dense
        flat
        bordered
        :rows="rows"
        separator="cell"
        :columns="columnsI18n"
        :loading="loading"
        row-key="index"
        virtual-scroll
        :virtual-scroll-item-size="28"
        :virtual-scroll-sticky-size-start="28"
        :pagination="pagination"
        :rows-per-page-options="[0]"
        @virtual-scroll="onScroll"
        ref="scroll-table"
      >
        <template v-slot:body-cell-date="props">
          <q-td :props="props">
            {{ $moment(props.value).format("DD/MM/YYYY HH:mm:ss") }}
          </q-td>
        </template>

        <template v-slot:body-cell-store="props">
          <q-td :props="props">
            <router-link
              v-if="props.value"
              :to="'/store/' + props.value.id"
              class="vito-link text-bold"
              >{{ props.value.name }}</router-link
            >
          </q-td>
        </template>

        <template v-slot:body-cell-terminal="props">
          <q-td :props="props">
            {{ props.value ? props.value.name : "" }}
          </q-td>
        </template>

        <template v-slot:body-cell-source="props">
          <q-td :props="props">
            <q-badge :color="sourceColor(props.value)">{{
              sourceValue(props.value)
            }}</q-badge>
          </q-td>
        </template>

        <template v-slot:body-cell-action="props">
          <q-td :props="props">
            <q-badge :color="actionColor(props.value)">{{
              $t("action." + props.value)
            }}</q-badge>
          </q-td>
        </template>

        <template v-slot:body-cell-status="props">
          <q-td :props="props">
            <q-badge :color="statusColor(props.value)">
              {{ statusValue(props.value) }}
            </q-badge>
          </q-td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <q-btn
              icon="visibility"
              round
              dense
              @click="
                showCard = true;
                selectedLog = props.row;
              "
            />
          </q-td>
        </template>
      </q-table>
    </q-card-section>
  </q-card>

  <q-dialog v-model="showCard" ref="ShowLockerLogDialog">
    <q-card
      bordered
      class="q-mt-md q-pa-md shadow-1"
      style="width: 550px; max-width: 90vw"
    >
      <q-card-section class="row text-green-vito no-wrap justify-between">
        <div class="text-h6">
          {{ $t("title.logDetails") }} #{{ selectedLog.id }}
        </div>
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section class="col-12 col-sm-auto">
        <q-markup-table flat class="highlight-values">
          <tr>
            <td>{{ $t("label.date") }}</td>
            <td>
              <span>{{
                $moment(selectedLog.date).format("DD/MM/Y HH:mm:ss")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.store") }}</td>
            <td>
              <span v-if="selectedLog.store">{{ selectedLog.store.name }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.terminal") }}</td>
            <td>
              <span v-if="selectedLog.terminal">{{
                selectedLog.terminal.name
              }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.source") }}</td>
            <td>
              <span>{{ sourceValue(selectedLog.source) }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.action") }}</td>
            <td>
              <span>{{ $t("action." + selectedLog.action) }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.status") }}</td>
            <td>
              <span>{{ statusValue(selectedLog.status) }}</span>
            </td>
          </tr>
        </q-markup-table>
      </q-card-section>
      <q-card-section>
        <q-input v-model="prettyLog" filled type="textarea" autogrow readonly />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import {
  TerminalActionStatus,
  //TerminalActionSource,
} from "../admin/terminal.enum";
import TerminalApi from "../../apis/Terminal";
export default {
  data: () => {
    return {
      logs: [],
      selectedLog: { log: "" },
      showCard: false,
      filter: null,
      loading: false,
      pagination: {
        rowsPerPage: 0,
      },
      rows: [],

      sortBy: "id",
      descending: true,
      pageSize: 50,
      nextPage: 1,
      lastPage: 1000,
    };
  },

  async mounted() {
    //
  },

  methods: {
    async onScroll({ to, index }) {
      //console.log(to, index);
      //const lastIndex = this.rows.length - 1;
      const startRow = (this.nextPage - 1) * this.pageSize;

      try {
        if (
          this.loading !== true &&
          this.nextPage <= this.lastPage &&
          (to === index || to === -1)
        ) {
          this.loading = true;
          const pageReponse = await TerminalApi.paginateLog({
            startRow,
            fetchCount: this.pageSize,
            filter: null,
            sortBy: this.sortBy,
            descending: this.descending,
          });
          this.lastPage = Math.ceil(pageReponse.data.count / this.pageSize);

          pageReponse.data.items.forEach((row, index) => {
            row.index = this.rows.length + index;
          });
          this.rows = this.rows.concat(pageReponse.data.items);

          this.nextPage++;
          this.loading = false;
        }
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    actionColor(action) {
      return action ? "primary" : "primary";
    },
    statusColor(status) {
      return status == 0 ? "green-vito" : "red-vito";
    },
    statusValue(status) {
      return TerminalActionStatus[status]
        ? TerminalActionStatus[status]
        : status;
    },
    sourceColor(source) {
      return source ? "primary" : "primary";
    },
    sourceValue(source) {
      return this.$t("type.terminal.actionSource." + source);
    },
  },
  computed: {
    prettyLog() {
      //console.log(this.selectedLog);

      if (this.selectedLog && this.selectedLog.log) {
        try {
          const obj = JSON.parse(this.selectedLog.log);
          const text = JSON.stringify(obj, null, 4);
          return text;
        } catch (error) {
          return this.selectedLog.log;
        }
      }
      return "";
    },
    columnsI18n() {
      const columns = [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "center",
          field: "id",
          sortable: false,
        },
        {
          name: "date",
          align: "center",
          label: this.$t("label.date"),
          field: "date",
          sortable: false,
        },
        {
          name: "store",
          label: this.$t("label.store"),
          field: "store",
          align: "center",
          sortable: false,
        },
        {
          name: "terminal",
          label: this.$t("label.terminal"),
          field: "terminal",
          align: "center",
          sortable: false,
        },
        {
          name: "source",
          label: this.$t("label.source"),
          field: "source",
          align: "center",
          sortable: false,
        },
        {
          name: "action",
          label: this.$t("label.action"),
          field: "action",
          align: "center",
          sortable: false,
        },
        {
          name: "status",
          label: this.$t("label.status"),
          field: "status",
          align: "center",
          sortable: false,
        },
        { name: "actions", style: "width:70px" },
      ];

      return columns;
    },
  },
};
</script>
