<template>
  <div class="row full-width">
    <visit-card
      v-if="visit"
      :visit="visit"
      @refresh="refresh"
      :key="refreshCounter"
    />
  </div>
</template>
<script>
import VisitCard from "../components/visit/VisitCard.vue";
import VisitApi from "../apis/Visit";
export default {
  name: "VisitPage",
  components: { VisitCard },

  data: () => {
    return {
      visit: null,
      refreshCounter: 0,
    };
  },

  async mounted() {
    this.$store.state.title = "button.visits";
    //console.log("visitId", this.$route.params.id);
    await this.getVisit();
  },
  methods: {
    async getVisit() {
      try {
        const visitResponse = await VisitApi.findOne(this.$route.params.id);
        this.visit = visitResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async refresh() {
      await this.getVisit();
      this.refreshCounter += 1;
    },
  },
};
</script>
