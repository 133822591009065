<template>
  <q-toolbar class="text-green-vito bg-grey-1 q-mb-sm q-pl-none shadow-1">
    <q-btn-dropdown
      stretch
      flat
      :label="$t('button.logs')"
      dropdown-icon="more_vert"
      menu-anchor="bottom left"
      menu-self="top left"
      icon-left
    >
    </q-btn-dropdown>
  </q-toolbar>
  <q-markup-table flat dense bordered separator="cell">
    <thead>
      <tr class="bg-green-vito-light">
        <th>ID</th>
        <th>{{ $t("label.date") }}</th>
        <th>{{ $t("label.action") }}</th>
        <th>{{ $t("label.status") }}</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="log in logs" :key="log.id" class="text-center">
        <td>{{ log.id }}</td>
        <td>{{ $moment(log.date).format("DD/MM/YYYY HH:mm:ss") }}</td>
        <td>
          <q-badge :color="actionColor(log.action)">{{
            $t("action." + log.action)
          }}</q-badge>
        </td>
        <td>
          <q-badge :color="statusColor(log.status)">{{
            $t("status." + log.status)
          }}</q-badge>
        </td>

        <td width="100" class="text-right">
          <q-btn
            v-if="log.action == 'send_message' && log.message"
            icon="mail"
            class="q-mr-sm"
            round
            dense
            @click="
              showMessageCard = true;
              selectedLog = log;
            "
          >
          </q-btn>
          <q-btn
            icon="visibility"
            round
            dense
            @click="
              showCard = true;
              selectedLog = log;
            "
          >
          </q-btn>
        </td>
      </tr>
    </tbody>
  </q-markup-table>

  <q-dialog v-model="showCard" ref="ShowOrderLogDialog">
    <q-card
      bordered
      class="q-mt-md q-pa-md shadow-1"
      style="width: 550px; max-width: 90vw"
    >
      <q-card-section class="row text-green-vito no-wrap justify-between">
        <div class="text-h6">
          {{ $t("title.logDetails") }} #{{ selectedLog.id }}
        </div>
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section class="col-12 col-sm-auto">
        <q-markup-table flat class="highlight-values">
          <tr>
            <td>{{ $t("label.date") }}</td>
            <td>
              <span>{{
                $moment(selectedLog.date).format("DD/MM/Y HH:mm:ss")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.action") }}</td>
            <td>
              <span>{{ $t("action." + selectedLog.action) }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.status") }}</td>
            <td>
              <span>{{ $t("status." + selectedLog.status) }}</span>
            </td>
          </tr>
        </q-markup-table>
      </q-card-section>
      <q-card-section>
        <q-input v-model="prettyLog" filled type="textarea" autogrow readonly />
      </q-card-section>
    </q-card>
  </q-dialog>

  <q-dialog v-model="showMessageCard" ref="ShowOrderMessage">
    <order-message
      :message="selectedLog.message"
      v-if="selectedLog.message"
    ></order-message>
  </q-dialog>
</template>

<script>
import OrderMessage from "./OrderMessage.vue";
import { OrderAction, OrderStatus } from "./order.enum";
export default {
  components: { OrderMessage },
  props: ["order"],
  data: () => {
    return {
      logs: [],
      selectedLog: { log: "" },
      showCard: false,
      showMessageCard: false,
    };
  },

  async mounted() {
    this.logs = JSON.parse(JSON.stringify(this.order.logs)).sort((a, b) =>
      a.id < b.id ? 1 : -1
    );
  },

  methods: {
    hasError(log) {
      return !["new", "complete", "0"].includes(log.status);
    },
    actionColor(action) {
      return OrderAction[action] ? OrderAction[action].color : "primary";
    },
    statusColor(status) {
      return OrderStatus[status] ? OrderStatus[status].color : "primary";
    },
  },
  computed: {
    prettyLog() {
      //console.log(this.selectedLog);

      if (this.selectedLog && this.selectedLog.log) {
        try {
          const obj = JSON.parse(this.selectedLog.log);
          const text = JSON.stringify(obj, null, 4);
          return text;
        } catch (error) {
          return this.selectedLog.log;
        }
      }
      return "";
    },
  },
};
</script>
