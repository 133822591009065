<template>
  <div
    :class="lockerClass"
    class="flex column text-center full-height justify-between no-wrap"
  >
    <div class="bg-green-vito-light text-green-vito text-bold q-py-none">
      {{ locker.doorName }}
    </div>
    <div style="min-height: 65px">
      <q-img
        v-if="locker.article && locker.article.product"
        :src="productImage"
        class="center"
        style="width: 55px"
      />
    </div>
    <locker-status :locker="locker" class="q-py-xs" />
  </div>
</template>

<script>
import LockerStatus from "./LockerStatus.vue";
export default {
  props: ["locker", "isSelected"],
  components: { LockerStatus },
  data: () => {
    return {};
  },

  async mounted() {
    //console.log("locker", this.locker);
  },

  computed: {
    productImage() {
      return (
        process.env.VUE_APP_BASE_URL +
        "/product/images/" +
        this.locker.article.product.image
      );
    },
    lockerClass() {
      //console.log(locker)
      if (!this.locker.isActive) {
        return "bg-grey-5";
      }
      if (this.locker.isFaulty) {
        return "bg-red-3";
      }
      if (this.isSelected) {
        return "bg-purple-2";
      }

      if (this.locker.article && this.locker.article.isReserved) {
        return "bg-green-5";
      }

      if (
        this.locker.article &&
        this.locker.article.product &&
        this.locker.article.isReturnable
      ) {
        return "bg-yellow-2 xlocker-returnable";
      }

      if (this.locker.article && this.locker.article.product) {
        return "bg-green-2";
      }
      return "bg-blue-1";
    },
  },
};
</script>
