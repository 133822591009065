<template>
  <q-card
    bordered
    class="q-pa-sm q-mb-sm no-padding-md"
    style="width: 380px; max-width: 100vw"
  >
    <q-card-section class="row q-pa-sm text-green-vito justify-between">
      <div class="row no-wrap">
        <q-avatar icon="store" color="green-vito" text-color="white" />
        <div class="text-h5 column q-ml-sm justify-center">
          {{ $t("title.networks") }}
        </div>
      </div>
      <div class="row q-gutter-x-sm no-wrap">
        <q-btn icon="close" flat round dense v-close-popup />
      </div>
    </q-card-section>

    <q-card-section class="row q-gutter-y-md q-mt-md full-width">
      <q-input
        class="col-grow"
        ref="newNetwork"
        square
        filled
        clearable
        v-model="newNetwork"
        :label="$t('label.name')"
        :hint="$t('hint.minchar', { chars: 2 })"
        lazy-rules
        :rules="[required, short]"
      >
        <template v-slot:append>
          <q-btn
            round
            dense
            color="primary"
            @click="addNetwork"
            icon="add_business"
          ></q-btn>
        </template>
      </q-input>
    </q-card-section>

    <q-card-section>
      <q-markup-table>
        <tbody>
          <tr v-for="network of networks" :key="network.id">
            <td>
              {{ network.name }}
              <q-popup-edit
                v-model="network.name"
                buttons
                :label-set="$t('button.save')"
                :label-cancel="$t('button.cancel')"
                v-slot="scope"
                @save="saveNetwork"
                :validate="validateNetwork"
              >
                <q-input
                  ref="updateNetwork"
                  v-model="scope.value"
                  dense
                  autofocus
                  @keyup.enter="scope.set"
                  :error="error"
                  :error-message="errorMessage"
                />
              </q-popup-edit>
            </td>
            <td align="right">
              <q-btn
                :disable="!network.isDeletable"
                icon="delete"
                class="text-red-vito"
                round
                dense
                @click="deleteNetwork(network)"
              >
              </q-btn>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card-section>
  </q-card>
</template>

<script>
import StoreApi from "../../apis/Store";

export default {
  emits: ["updateNetworks"],
  props: ["networkId"],
  data: () => {
    return {
      refreshCounter: 0,
      form: {
        id: null,
        name: null,
      },
      errorMessage: null,
      error: false,

      networks: [],
      newNetwork: null,
    };
  },
  async mounted() {
    await this.getNetworks();
  },
  methods: {
    async getNetworks() {
      try {
        const networksResponse = await StoreApi.findNetworks();
        this.networks = networksResponse.data;
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
      }
    },
    async addNetwork() {
      this.$refs.newNetwork.validate();
      if (this.$refs.newNetwork.hasError) {
        // form has error
      } else {
        try {
          const createNetwork = await StoreApi.createNetwork({
            name: this.newNetwork,
          });
          this.networks = createNetwork.data.networks;
          this.newNetwork = null;
          this.$emit("updateNetworks");
          this.$q.notify({
            type: "positive",
            message: this.$t(createNetwork.data.message),
          });
          //console.log(saveResponse.data);
        } catch (error) {
          const message = error.response
            ? error.response.data.message
            : error.message;
          this.errorMessage = message;
          this.$q.notify({
            type: "negative",
            message: message,
          });
          //console.error(message);
        }
        this.showAddNetwork = false;
      }
      //console.log(this.newNetwork);
    },

    validateNetwork(name) {
      if (name && name.length > 1) {
        this.errorMessage = "";
        this.error = false;
        return true;
      } else {
        this.errorMessage = this.$t("validation.minchar", { chars: 2 });
        this.error = true;
        return false;
      }
    },
    async saveNetwork(value, previousValue) {
      const model = this.networks.find((n) => n.name === previousValue);
      //console.log("saving", value, model);
      if (this.error) {
        //this.errorMessage = this.$t("validation.verify");
      } else {
        try {
          const updateNetworkResponse = await StoreApi.updateNetwork(model.id, {
            id: model.id,
            name: value,
          });
          this.networks = updateNetworkResponse.data.networks;
          this.$emit("updateNetworks");

          this.$q.notify({
            type: "positive",
            message: this.$t(updateNetworkResponse.data.message),
          });
        } catch (error) {
          const message = error.response
            ? error.response.data.message
            : error.message;
          this.errorMessage = message;
          this.$q.notify({
            type: "negative",
            message: message,
          });
        }
      }
    },

    async deleteNetwork(network) {
      try {
        const deleteResponse = await StoreApi.deleteNetwork(network.id);
        this.networks = deleteResponse.data.networks;
        this.$q.notify({
          type: "positive",
          message: this.$t(deleteResponse.data.message),
        });
        //console.log(saveResponse.data);
        this.$emit("updateNetworks");
      } catch (error) {
        let message = error.response
          ? error.response.data.message
          : error.message;
        this.$q.notify({
          type: "negative",
          message: message,
        });
        //console.error(message);
      }
    },
    required(val) {
      return (
        (val && val.toString().length > 0) || this.$t("validation.required")
      );
    },
    short(val) {
      return (
        (val && val.length > 1) || this.$t("validation.minchar", { chars: 2 })
      );
    },
  },
  computed: {},
};
</script>
