<template>
  <q-card
    bordered
    class="q-mt-md q-pa-md shadow-1"
    style="width: 550px; max-width: 90vw"
  >
    <q-card-section class="row text-green-vito no-wrap justify-between">
      <div class="text-h6">{{ $t("title.logDetails") }} #{{ log.id }}</div>
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>

    <q-card-section class="col-12 col-sm-auto">
      <q-markup-table flat class="highlight-values">
        <tr>
          <td>{{ $t("label.date") }}</td>
          <td>
            <span>{{ $moment(log.date).format("DD/MM/Y HH:mm:ss") }}</span>
          </td>
        </tr>
        <tr>
          <td>{{ $t("label.store") }}</td>
          <td>
            <span>{{ log.store }}</span>
          </td>
        </tr>
        <tr>
          <td>{{ $t("label.action") }}</td>
          <td>
            <span>{{ $t("action." + log.action) }}</span>
          </td>
        </tr>

        <tr>
          <td>{{ $t("label.status") }}</td>
          <td>
            <span>{{ $t("status." + log.status) }}</span>
          </td>
        </tr>
        <tr>
          <td>{{ $t("label.order") }}</td>
          <td>
            <router-link
              :to="'/order/' + log.orderId"
              class="vito-link text-bold"
            >
              <span>{{ log.orderId }}</span>
            </router-link>
          </td>
        </tr>
      </q-markup-table>
    </q-card-section>
    <q-card-section>
      <q-input v-model="prettyLog" filled type="textarea" autogrow readonly />
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  props: ["log"],
  data: () => {
    return {};
  },

  async mounted() {},

  methods: {
    hasError(log) {
      return !["new", "complete", "0"].includes(log.status);
    },
  },
  computed: {
    prettyLog() {
      //console.log(this.log);

      if (this.log && this.log.log) {
        try {
          const obj = JSON.parse(this.log.log);
          const text = JSON.stringify(obj, null, 4);
          return text;
        } catch (error) {
          return this.log.log;
        }
      }
      return "";
    },
  },
};
</script>
