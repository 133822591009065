<template>
  <q-card
    flat
    class="q-pa-sm full-width no-padding-md"
    style="max-width: 100vw"
  >
    <q-card-section class="row q-pb-none text-green-vito justify-between">
      <div class="text-h5">{{ title }}</div>
      <div class="q-gutter-x-md row">
        <q-btn
          v-if="
            isSuperAdmin && (order.status == 'new' || order.status == 'error')
          "
          ref="completeButton"
          color="primary"
          round
          dense
          icon="close"
          @click="showComplete = true"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.completeOrder") }}
          </q-tooltip>
        </q-btn>
        <q-btn
          icon="shopping_cart"
          square
          round
          dense
          to="/orders"
          @click="$emit('cancel')"
        >
          <q-tooltip class="bg-green-vito text-no-wrap">
            {{ $t("tooltip.listOrder") }}
          </q-tooltip>
        </q-btn>
      </div>
    </q-card-section>

    <q-card-section class="row justify-start no-padding-md">
      <div class="text-center col-12 col-sm-auto">
        <q-img :src="productImage" width="180px" />
        <div class="text-green-vito q-mb-sm">
          {{ deliveryProductText }}
        </div>
      </div>
      <q-card-section class="col-12 col-sm-auto no-padding-md">
        <q-markup-table flat class="highlight-values">
          <tr>
            <td>{{ $t("label.date") }}</td>
            <td>
              <span>{{ $moment(order.date).format("DD/MM/Y HH:mm") }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.store") }}</td>
            <td>
              <span>
                <router-link
                  :to="'/store/' + order.store.id"
                  class="vito-link text-bold"
                >
                  {{ order.store.name }}
                </router-link>
              </span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("type.order.label") }}</td>
            <td>
              <span>{{ $t("type.order." + order.type) }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.status") }}</td>
            <td>
              <span>{{ $t("status." + order.status) }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.source") }}</td>
            <td>
              <span>{{ order.source }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.amount") }}</td>
            <td>
              <span>
                {{
                  parseFloat(order.amount).toLocaleString(locale, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
                {{ currency }}
              </span>
            </td>
          </tr>
        </q-markup-table>
      </q-card-section>
      <q-separator vertical />
      <q-card-section class="col-12 col-sm-auto no-padding-md">
        <q-markup-table flat class="highlight-values">
          <tr>
            <td>{{ $t("label.deliveryDone") }}</td>
            <td>
              <span>{{ $t(order.delivered ? "true" : "false") }}</span>
              <span v-if="order.deliveryLockerId">
                <router-link
                  :to="'/locker/' + order.deliveryLockerId"
                  class="vito-link text-bold"
                >
                  {{ order.deliveryLockerDoorName }}
                </router-link>
              </span>
            </td>
          </tr>
          <tr v-if="order.returnProduct">
            <td>{{ $t("label.returnDone") }}</td>
            <td>
              <span>{{ $t(order.returned ? "true" : "false") }}</span>
              <span v-if="order.returnLockerId">
                <router-link
                  :to="'/locker/' + order.returnLockerId"
                  class="vito-link text-bold"
                >
                  {{ order.returnLockerDoorName }}
                </router-link>
              </span>
            </td>
          </tr>
          <tr v-if="order.code">
            <td>{{ $t("label.securityCode") }}</td>
            <td>
              <span>{{ order.code.code }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.contact") }}</td>
            <td>
              <span>{{ order.contactPhone || order.contactEmail }}</span>
            </td>
          </tr>
        </q-markup-table>
      </q-card-section>
    </q-card-section>

    <q-card-section>
      <order-transactions :order="order" :key="refreshCounter" />
    </q-card-section>
    <q-card-section>
      <order-logs :order="order" :key="refreshCounter" />
    </q-card-section>
  </q-card>

  <q-dialog v-model="showComplete" ref="completeOrderDialog">
    <order-complete :order="order" @refresh="refresh" />
  </q-dialog>
</template>

<script>
import OrderLogs from "./OrderLogs.vue";
import OrderTransactions from "./OrderTransactions.vue";
import OrderComplete from "./OrderComplete.vue";
import { useI18n } from "vue-i18n";

export default {
  props: ["order"],
  components: { OrderLogs, OrderTransactions, OrderComplete },
  emits: ["refresh", "cancel"],

  setup() {
    const { locale } = useI18n({ useScope: "global" });
    return {
      locale,
    };
  },
  data: () => {
    return {
      showComplete: false,
      errorMessage: null,
      refreshCounter: 0,
    };
  },
  async mounted() {
    //console.log("order", this.order);
  },
  methods: {
    refresh() {
      //console.log("refresh");
      this.showComplete = false;
      this.$emit("refresh");
    },
    required(val) {
      return (
        (val && val.toString().length > 0) || this.$t("validation.required")
      );
    },
    short(val) {
      return (
        (val && val.length > 1) || this.$t("validation.minchar", { chars: 2 })
      );
    },
    isNumber(val) {
      return !isNaN(val) || this.$t("validation.number");
    },
  },
  computed: {
    currency() {
      return this.$store.getters.currency;
    },

    deliveryProductText() {
      let product = this.order.deliveryProduct;
      if (!product) return this.$t("notFound");
      return `${product.name} ${product.weight} Kg`;
    },
    productImage() {
      let product = this.order.deliveryProduct;
      if (!product) return null;
      return process.env.VUE_APP_BASE_URL + "/product/images/" + product.image;
    },
    title() {
      let title = this.$t("order");
      return `${title.charAt(0).toUpperCase() + title.slice(1)} #${
        this.order.id
      }`;
    },

    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
  },
};
</script>
