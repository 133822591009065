<template>
  <q-card
    bordered
    class="q-mt-md q-pa-md shadow-1"
    style="width: 750px; max-width: 90vw"
  >
    <q-card-section class="row text-green-vito no-wrap justify-between">
      <div class="text-h6">{{ $t("title.completeOrder") }} #{{ order.id }}</div>
      <q-btn icon="close" flat round dense v-close-popup />
    </q-card-section>

    <q-card-actions class="q-pa-md justify-end">
      <q-btn
        unelevated
        size="md"
        class="text-green-vito"
        :label="$t('button.cancel')"
        v-close-popup
      />
      <q-btn
        unelevated
        size="md"
        class="bg-green-vito text-white"
        :label="$t('button.complete')"
        @click="handleSave"
      />
    </q-card-actions>
  </q-card>
</template>

<script>
//import StoreApi from "../../apis/Store";
import OrderApi from "../../apis/Order";

export default {
  props: ["order"],
  emits: ["refresh"],

  data: () => {
    return {
      errorMessage: null,
    };
  },

  async mounted() {
    //await this.getAllStores();
    //await this.getStore();
    //console.log(this.order);
  },
  methods: {
    async handleSave() {
      //console.log("saving complete");
      this.errorMessage = null;

      try {
        console.log(this.order);
/*
        const orderResponse = await OrderApi.complete({
          orderId: this.order.id,
        });
*/
        const orderResponse = await OrderApi.cancel(this.order.id, {
          reason: "Backoffice",
          transaction: {
            amount: this.order.amount,
            authorisationNumber: 0,
            reference: 0,
            source: "cb",
            log: "",
          },
        });
        this.$q.notify({
          type: "positive",
          message: this.$t(orderResponse.data.message),
        });
        this.$emit("refresh");
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message;
        this.errorMessage = message;
        this.$q.notify({
          type: "negative",
          message: this.$t(message),
        });
      }
    },
  },

  computed: {
    orderAmount() {
      //['same', 'other', 'none']
      if (this.selectedProduct && this.selectedProduct.prices) {
        switch (this.returnableType) {
          case "same":
            return this.getPrice(this.selectedProduct, false);
          case "other":
            return (
              this.getPrice(this.selectedProduct, false) -
              this.getPrice(this.selectedProduct, true) +
              this.getPrice(this.selectedReturnProduct, true)
            );
          case "none":
            return (
              this.getPrice(this.selectedProduct, false) +
              this.getPrice(this.selectedProduct, true)
            );
          default:
            return "error";
        }
      }

      return "";
    },
  },
};
</script>
