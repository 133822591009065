import Api from "./Api";
export default {
  findAll() {
    return Api().get("/log");
  },

  findOne(id) {
    return Api().get(`/log/${id}`);
  },

  save(id, form) {
    return Api().patch("/log/" + id, form);
  },

  create(form) {
    return Api().post("/log", form);
  },

  delete(id) {
    return Api().delete(`/log/${id}`);
  },
  paginate(paginate) {
    return Api().post("/order-log/page", paginate);
  },
};
