<template>
  <q-toolbar class="text-green-vito bg-grey-1 q-mb-sm q-pl-none shadow-1">
    <q-btn-dropdown
      stretch
      flat
      :label="$t('button.comments')"
      dropdown-icon="more_vert"
      menu-anchor="bottom left"
      menu-self="top left"
      icon-left
    >
    </q-btn-dropdown>
  </q-toolbar>
  <q-markup-table flat dense bordered separator="cell">
    <thead>
      <tr class="bg-green-vito-light">
        <th>ID</th>
        <th>{{ $t("label.date") }}</th>
        <th>{{ $t("label.from") }}</th>
        <th>{{ $t("label.comment") }}</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="comment in comments" :key="comment.id" class="text-center">
        <td>{{ comment.id }}</td>
        <td>{{ $moment(comment.date).format("DD/MM/YYYY HH:mm:ss") }}</td>
        <td>
          {{ comment.from }}
        </td>
        <td style="max-width: 500px">
          <div class="ellipsis">
            {{ comment.comment }}
          </div>
        </td>

        <td width="100" class="text-right">
          <q-btn
            icon="visibility"
            round
            dense
            @click="
              showCard = true;
              selectedComment = comment;
            "
          >
          </q-btn>
        </td>
      </tr>
    </tbody>
  </q-markup-table>

  <q-dialog v-model="showCard" ref="ShowVisitCommentDialog">
    <q-card
      bordered
      class="q-mt-md q-pa-md shadow-1"
      style="width: 550px; max-width: 90vw"
    >
      <q-card-section class="row text-green-vito no-wrap justify-between">
        <div class="text-h6">
          {{ $t("title.comment") }} #{{ selectedComment.id }}
        </div>
        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section class="col-12 col-sm-auto">
        <q-markup-table flat>
          <tr>
            <td>{{ $t("label.date") }}</td>
            <td class="highlight-values">
              <span>{{
                $moment(selectedComment.date).format("DD/MM/Y HH:mm:ss")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("label.from") }}</td>
            <td class="highlight-values">
              <span>{{ selectedComment.from }}</span>
            </td>
          </tr>
        </q-markup-table>
      </q-card-section>
      <q-card-section>
        <q-input
          v-model="selectedComment.comment"
          filled
          type="textarea"
          autogrow
          readonly
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["visit"],
  data: () => {
    return {
      comments: [],
      selectedComment: { comment: "" },
      showCard: false,
    };
  },

  async mounted() {
    this.comments = JSON.parse(JSON.stringify(this.visit.comments)).sort(
      (a, b) => (a.date < b.date ? 1 : -1)
    );
  },

  methods: {},
  computed: {},
};
</script>
