import Api from "./Api";
export default {
  findAll() {
    return Api().get("/product");
  },
  findActive() {
    return Api().get("/product/active");
  },
  findOne(id) {
    return Api().get(`/product/${id}`);
  },
  save(form) {
    return Api().patch("/product/" + form.id, form);
  },
  create(form) {
    return Api().post("/product", form);
  },
  delete(id) {
    return Api().delete(`/product/${id}`);
  },
  deleteImage(filename) {
    return Api().delete("/product/upload/" + filename);
  },
  getImage(filename) {
    return Api().get("/product/image/" + filename);
  },
};
