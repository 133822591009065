import Api from "./Api";
export default {
  findByStoreId(storeId) {
    return Api().get("/visit/store/" + storeId);
  },
  paginate(paginate) {
    return Api().post("/visit/page", paginate);
  },
  findAll() {
    return Api().get("/visit");
  },
  findOne(id) {
    return Api().get(`/visit/${id}`);
  },
  save(id, form) {
    return Api().patch("/visit/" + id, form);
  },
  create(form) {
    return Api().post("/visit", form);
  },
  complete(form) {
    return Api().post("/visit/complete", form);
  },
  delete(id) {
    return Api().delete(`/visit/${id}`);
  },
  getMessages(id) {
    return Api().get(`/visit/${id}/message`);
  },
  resendMessage(id, payload) {
    return Api().post(`/visit/${id}/message`, payload);
  },
};
